import { Button, Menu, MenuItem } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import LaunchIcon from "@material-ui/icons/Launch";
import React from "react";

const useStyles = makeStyles(({ palette, spacing }) => ({
    button: {
        justifyContent: "flex-start",
    },
    icon: {
        color: "white",
        marginRight: 10,
    },
}));

export default function MenuActions({ openMenu, setOpenMenu, setmodalAction }) {
    const classes = useStyles();

    const actions = [
        { label: "Déplacer vers", type: "move", color: "primary", icon: <LaunchIcon className={classes.icon} /> },
        { label: "Copier vers", type: "copy", color: "primary", icon: <FlipToFrontIcon className={classes.icon} /> },
        { label: "Supprimer", type: "delete", color: "secondary", icon: <DeleteForeverIcon className={classes.icon} /> },
    ];

    return (
        <Menu
            elevation={2}
            anchorEl={openMenu}
            open={Boolean(openMenu)}
            getContentAnchorEl={null}
            onClose={() => {
                setOpenMenu(null);
                setmodalAction(null);
            }}
            anchorOrigin={{
                vertical: "center",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}>
            {actions.map((action, key) => (
                <MenuItem
                    key={key}
                    onClick={() =>
                        setmodalAction({
                            type: action.type,
                            id: openMenu?.getAttribute("data-id"),
                        })
                    }>
                    <Button fullWidth className={classes.button} color={action.color} variant="contained" size="small">
                        {action.icon} {action.label}
                    </Button>
                </MenuItem>
            ))}
        </Menu>
    );
}
