import { UilLayerGroup, UilFileEditAlt } from "@iconscout/react-unicons"
import Documents from "~/pages/documents"
import TemplateManager from "~/pages/template-manager"

const routes = [
  {
    name: "Modèles de projets",
    path: "/tree",
    exact: true,
    component: TemplateManager,
    icon: UilLayerGroup,
  },
  {
    name: "Modèles de documents",
    path: "/documents",
    exact: false,
    component: Documents,
    icon: UilFileEditAlt,
  },
]

export default routes
