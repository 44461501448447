import ApiService from '../services/api-service';
import User from '../models/user';
import TokenData from '../models/token-data';
import axios from 'axios';
export default class AccountLib {
  static LoginPath = '/api/accounts/selectlogin';

  static RegistrationPath = '/api/accounts/registration/';

  static VerifyEmailPath = '/api/accounts/registration/verify-email/';

  static CheckEmailVerificationPath = '/api/accounts/email-verified/';

  static UserPath = '/api/users/';

  static ProfilePath = '/api/users/profile/';

  static PasswordChangePath = '/api/accounts/password/change/';

  static ResendValidationPath = '/api/accounts/resend-validation/';

  static PasswordResetPath = '/api/accounts/password/reset/';

  static PasswordResetConfirmPath = '/api/accounts/password/reset/confirm/';

  static GetUserTreePath = '/api/users/tree/';

  static InviteConfirmPath = '/api/invite_confirm';

  static UpdateEmailPath = '/api/users/update_email/';

  static async resendValidation(email) {
    const data = `email=${email}`;
    return await ApiService.post(AccountLib.ResendValidationPath, data);
  }

  static async login(username, password, client_id) {
    var bodyFormData = new FormData();
    bodyFormData.append('username', username);
    bodyFormData.append('password', password);
    client_id = 'jeeFLKH42gtxUQD3tIpCXDhu9aJIUHgbSTfZ1EjO';
    const response = await axios({
      method: 'post',
      url: process.env.COMMON_LAW_OAUTH_API_URL + AccountLib.LoginPath,
      data: bodyFormData,
      headers: { 'LOGIN-APP-ID': client_id },
    });

    const tokenData = new TokenData(response.data);
    tokenData.persist();

    const user = TokenData.getJwtTokenData();

    return new User(user);
  }
  // direct login with username and password (used by the Oauth app)
  // static async login(username, password) {
  //   const data = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(
  //     password,
  //   )}`;

  //   const response = await ApiService.post(AccountLib.LoginPath, data);

  //   const tokenData = new TokenData(response.data);
  //   tokenData.persist();

  //   const user = TokenData.getJwtTokenData();

  //   return new User(user);
  // }

  // get the user info (must be authenticated)
  static async getUserProfile() {
    const response = await ApiService.get(AccountLib.ProfilePath);

    return response.data;
  }

  static async registration(userRegistrationInfo) {
    await ApiService.post(AccountLib.RegistrationPath, userRegistrationInfo);
  }

  static async verifyEmail(token) {
    const data = `key=${token}`;
    await ApiService.post(AccountLib.VerifyEmailPath, data);
  }

  static async checkEmailVerification() {
    const response = await ApiService.get(AccountLib.CheckEmailVerificationPath);
    return response.data.email_validated;
  }

  static async updateProfile(profile) {
    await ApiService.put(AccountLib.ProfilePath, profile);
  }

  static async updateAccountsPassword(new_password1, new_password2, old_password) {
    const data = `new_password1=${encodeURIComponent(
      new_password1,
    )}&new_password2=${encodeURIComponent(new_password2)}&old_password=${encodeURIComponent(
      old_password,
    )}`;

    await ApiService.post(AccountLib.PasswordChangePath, data);
  }

  static async resetPassword(data) {
    const response = await ApiService.post(AccountLib.PasswordResetPath, data);
    return response.data;
  }

  static async confirmResetPassword(data) {
    const response = await ApiService.post(AccountLib.PasswordResetConfirmPath, data);
    return response.data?.detail;
  }

  static async updateUser(formData) {
    const user = User.fromStorage();

    const url = `${AccountLib.UserPath}${user.id}/`;
    const data = `first_name=${encodeURIComponent(
      formData.first_name,
    )}&last_name=${encodeURIComponent(formData.last_name)}`;

    const response = await ApiService.put(url, data);

    new User({ ...user, ...response.data });

    return response.data;
  }

  static async getUserTreePath() {
    const { id } = User.fromStorage();
    const path = AccountLib.GetUserTreePath;

    const response = await ApiService.get(path, { params: { id } });
    return response.data;
  }

  static async inviteConfirmCreate(key) {
    const path = AccountLib.InviteConfirmPath;

    const response = await ApiService.post(path, { key });
    return response.data;
  }

  static async updateEmail(email) {
    const path = AccountLib.UpdateEmailPath;

    const response = await ApiService.post(path, { email });
    return response.data;
  }
}
