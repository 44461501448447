import { Button, CircularProgress } from "@material-ui/core";

export default function Add({ _function, loading }) {
  return (
    <Button
      variant="contained"
      color="secondary"
      disabled={loading}
      onClick={_function}
    >
      {loading ? <CircularProgress size={18} /> : "Supprimer"}
    </Button>
  );
}
