import DocumentContext from "../../../providers/DocumentContext";
import { Button } from "@material-ui/core/";
import React, { useContext } from "react";
import DialogEditor from "../EditorView/DialogEditor";
import Code from "@material-ui/icons/Code";
import AccountTree from "@material-ui/icons/AccountTree";
import BorderAll from "@material-ui/icons/BorderAll";

export default function Insertion() {
  const [open, setOpen] = React.useState(false);
  const { document, updateDocument } = useContext(DocumentContext);

  const openVariableModal = () => {
    document.edit.type = "variable";
    updateDocument(document);
    setOpen(true);
  };

  const openConditionModal = () => {
    document.edit.type = "condition";
    updateDocument(document);
    setOpen(true);
  };

  const openTableauAndRepetitionModal = () => {
    document.edit.type = "repetition";
    updateDocument(document);
    setOpen(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        minHeight: 450,
      }}
    >
      <Button
        fullWidth
        variant="contained"
        component="label"
        color="primary"
        style={{ marginBottom: 10 }}
        onClick={openVariableModal}
      >
        <Code style={{ marginRight: 10 }}></Code>
        Insérer une variable
      </Button>
      <Button
        fullWidth
        variant="contained"
        component="label"
        color="primary"
        style={{ marginBottom: 10 }}
        onClick={openConditionModal}
      >
        <AccountTree style={{ marginRight: 10 }}></AccountTree>
        Insérer une condition
      </Button>
      <Button
        fullWidth
        variant="contained"
        component="label"
        color="primary"
        style={{ marginBottom: 10 }}
        onClick={openTableauAndRepetitionModal}
      >
        <BorderAll style={{ marginRight: 10 }}></BorderAll>
        Insérer un tableau ou répétition
      </Button>
      <DialogEditor
        open={open}
        setOpen={setOpen}
        isFromWordIntegration={true}
      />
    </div>
  );
}
