import ApiService from '../services/api-service';

export function addressParser(data) {
  const result = { ...data };
  if (result.address) {
    const { complement, country, locality_name, postal_code, street } = result.address;
    result._address = `${street}, ${postal_code} ${country}`;
  }

  return result;
}

export default class PaginationHelper {
  static getPagination(path, params, parser = (e) => e) {
    return function (query) {
      return new Promise((resolve) => {
        ApiService.get(path, {
          params: {
            ...params,
            offset: query.page * query.pageSize,
            limit: query.pageSize,
          },
        }).then(({ data }) => {
          resolve({
            data: data.results.map(parser).map(addressParser),
            page: query.page,
            totalCount: data.count,
          });
        });
      });
    };
  }
}
