import TemplateLib from "~/common/libs/interview-template-lib";
export default async function checkOrder({ toOrder, isGroup }) {
    return await new Promise(resolve => {
        let isOrdered = true;
        if (isGroup) {
            const toOrderByOrder = toOrder.sort((a, b) => {
                return a.order - b.order;
            });
            toOrderByOrder.forEach((group, index) => {
                if (group.order !== index + 1) isOrdered = false;
            });
            if (!isOrdered)
                Promise.all(toOrderByOrder.map((group, index) => TemplateLib.updateGroupVariable(group.id, { ...group, order: index + 1 })))
                    .then(res => {
                        resolve(res);
                    })
                    .catch(() => resolve(toOrderByOrder));
            else resolve(toOrderByOrder);
        } else {
            const toOrderByOrder = toOrder.sort((a, b) => {
                return a.order_in_group - b.order_in_group;
            });
            toOrderByOrder.forEach((variable, index) => {
                if (variable.order_in_group !== index + 1) isOrdered = false;
            });
            if (!isOrdered)
                Promise.all(toOrderByOrder.map((variable, index) => TemplateLib.updateVariable(variable.id, { ...variable, order_in_group: index + 1 })))
                    .then(res => {
                        resolve(res);
                    })
                    .catch(() => resolve(toOrderByOrder));
            else resolve(toOrderByOrder);
        }
    });
}
