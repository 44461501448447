export default class User {
  constructor(jsonData) {
    this.id = jsonData.id;
    this.username = jsonData.username;
    this.email = jsonData.email;
    this.first_name = jsonData.first_name;
    this.last_name = jsonData.last_name;

    if (jsonData) this.persist();
  }

  static fromStorage() {
    const jsonData = localStorage.getItem('app_user');
    if (!jsonData) return null;

    return new User(JSON.parse(jsonData));
  }

  toJson() {
    return JSON.stringify({
      id: this.id,
      username: this.username,
      email: this.email,
      first_name: this.first_name,
      last_name: this.last_name,
    });
  }

  persist() {
    localStorage.setItem('app_user', this.toJson());
  }

  static revoke() {
    localStorage.removeItem('app_user');
  }
}
