import React from "react";

export default function BadgeLabel({ type }) {
    const getTypeColor = () => {
        if (type === "text" || type === "number" || type === "object" || type === "date" || type === "unisign" || type === "multisign"|| type === "textformat") return "#00db5c";
        else if (type === "bool" || type === "choice" || type === "multi" || type === "list") return "#f93b3b";
        else if (type === "array" || type === "multiarray") return "#00b8d9";
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
            <div style={{ width: 10, height: 10, borderRadius: "2px", backgroundColor: getTypeColor() }}></div>
        </div>
    );
}
