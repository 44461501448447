import { IconButton } from "@material-ui/core/";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React from "react";
import { useHistory } from "react-router-dom";
import config from "~/config";

const useStyles = makeStyles(({ palette, spacing }) => ({
  table: {
    padding: 0,
    margin: "15px 0px",
    backgroundColor: "white",
  },
  tableCell: {
    fontSize: 12,
    padding: "5px 15px",
  },
  row: {
    cursor: "pointer",
    transition: "0.1s",
    "&:hover": {
      backgroundColor: "#ddd!important",
    },
  },
  tagValid: {
    backgroundColor: "rgba(24, 204, 30, 0.35)",
    color: "#18cc1e",
    padding: "5px 8px",
    fontWeight: "bold",
    borderRadius: 5,
  },
  tagWarning: {
    backgroundColor: "rgba(252, 186, 3, 0.35)",
    color: "#ff9800",
    padding: "5px 8px",
    fontWeight: "bold",
    borderRadius: 5,
  },
  project: {
    padding: "6px 9px",
    borderRadius: 5,
    minWidth: 350,
    fontWeight: "bold",
  },
  task: {
    minWidth: 350,
    padding: "6px 9px",
    borderRadius: 5,
    fontWeight: "bold",
  },
}));

export default function LineDocument({
  data,
  setdocumentIniitList,
  documentIniitList,
  setOpenMenu,
}) {
  const classes = useStyles();
  const history = useHistory();

  const color_project = localStorage.getItem("color-project")
    ? localStorage.getItem("color-project")
    : config.defaultColors.project;
  const color_task = localStorage.getItem("color-task")
    ? localStorage.getItem("color-task")
    : config.defaultColors.task;

  const clickRow = (e) => {
    if (e.target.closest(".deletebutton") === null)
      history.push(`/documents/${data.id}`);
  };
  return (
    <TableRow className={classes.row} onClick={clickRow}>
      <TableCell className={classes.tableCell} align="center">
        {data.name} (id: {data.id})
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        {data.broken_content ? (
          <div className={classes.tagWarning}>Problème</div>
        ) : (
          <div className={classes.tagValid}>Valide</div>
        )}
      </TableCell>
      {/* <TableCell className={classes.tableCell} align="center">
                {data.variables.length}
            </TableCell> */}
      <TableCell className={classes.tableCell} align="center">
        <div
          className={classes.project}
          style={{
            color: `rgb(${color_project})`,
            backgroundColor: `rgba(${color_project} , 0.3)`,
          }}
        >
          {data.project_name} (id: {data.project})
        </div>
      </TableCell>
      <TableCell className={classes.tableCell} align="center">
        <div
          className={classes.project}
          style={{
            color: `rgb(${color_task})`,
            backgroundColor: `rgba(${color_task} , 0.3)`,
          }}
        >
          {data.task_name ?? "/"} (id: {data.task})
        </div>
      </TableCell>
      <TableCell
        className={"deletebutton"}
        style={{ fontSize: 12, padding: "5px 15px" }}
        align="center"
      >
        <IconButton
          className={"deletebutton"}
          data-id={data.id}
          onClick={(e) => setOpenMenu(e.currentTarget)}
        >
          <MoreHorizIcon className={"deletebutton"} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
