import ActiveEditorContext from "../../../../providers/ActiveEditorContext";
import TreeContext from "../../../../providers/TreeContext";
import Add from "./Buttons/Add.js";
import DescriptionField from "./Fields/Description";
import MandatoryField from "./Fields/Mandatory";
import Dependencies from "./Fields/Dependencies";
import NameField from "./Fields/Name";
import messageError from "./Functions/messageError";
import TemplateLib from "@common/libs/template-lib";
import { Grid } from "@material-ui/core";
import { useState, useContext } from "react";
import { toast } from "react-toastify";

export default function FormAddSection() {
  const { activeEditor, updateActiveEditor } = useContext(ActiveEditorContext);
  const { tree, updateTree } = useContext(TreeContext);
  const [_loading, set_loading] = useState(false);
  const [_formData, set_formData] = useState({
    category_id: activeEditor.root[1]?.id,
    name: "",
    description: "",
    project_id: activeEditor.root[0].id,
    mandatory: false,
    tasks: [],
  });

  const addSection = () => {
    set_loading(true);
    let projectIndex = 0;
    let parentSectionIndex = 0;
    if (activeEditor.root_key.length === 1) {
      projectIndex = activeEditor.root_key[0];
      _formData["order"] = activeEditor.root[0].sections?.length + 1;
    }
    if (activeEditor.root_key.length === 2) {
      projectIndex = activeEditor.root_key[0];
      parentSectionIndex = activeEditor.root_key[1];
      if (
        activeEditor.root[1].sections === undefined ||
        activeEditor.root[1].sections === "loading"
      ) {
        activeEditor.root[1].sections = [];
      }
      _formData["order"] = activeEditor.root[1].sections?.length + 1;
    }

    TemplateLib.newCategorie(_formData)
      .then((res) => {
        res["tasks"] = [];
        let result = { ...tree };
        if (activeEditor.root[1]?.id) {
          result.projects[projectIndex].sections[
            parentSectionIndex
          ].sections.push(res);
          updateTree({
            ...result,
            selected: [
              projectIndex,
              result.projects[projectIndex].sections[parentSectionIndex]
                .sections.length - 1,
            ].join("-"),
          });
        } else {
          result.projects[projectIndex].sections.push(res);
          updateTree({
            ...result,
            selected: [
              projectIndex,
              result.projects[projectIndex].sections.length - 1,
            ].join("-"),
          });
        }
        updateActiveEditor({
          ...activeEditor,
          type: "section",
          data: res,
          root: [...activeEditor.root, res],
          root_key: [
            ...activeEditor.root_key,
            result.projects[projectIndex].sections.length,
          ],
        });
        toast.success('Ajout de la section : "' + res.name + '" enregistré.');
        set_loading(false);
      })
      .catch((err) => {
        console.error("err", err);
        messageError(err, "durant l'ajout de la section");
        set_loading(false);
      });
  };

  return (
    <Grid container spacing={2}>
      <NameField
        _formData={_formData}
        set_formData={set_formData}
        label="Nom de la section"
      />
      <MandatoryField
        _formData={_formData}
        set_formData={set_formData}
        label={"Section facultative "}
      />
      {_formData.mandatory && (
        <Dependencies
          _formData={_formData}
          set_formData={set_formData}
          activeEditor={activeEditor}
        ></Dependencies>
      )}
      <DescriptionField
        _formData={_formData}
        set_formData={set_formData}
        label="Description de la section"
      />
      <Add _function={addSection} loading={_loading} />
    </Grid>
  );
}
