import StripeLib from "@common/libs/stripe-lib";
import { Grid, TextField, InputAdornment, CircularProgress } from "@material-ui/core";
import React from "react";

export default function Price({ _formData, set_formData }) {
    React.useEffect(() => {
        if (_formData.price_uuid !== null && _formData.price_uuid !== undefined) {
            StripeLib.getPrice(_formData.price_uuid).then(res => {
                set_formData({ ..._formData, price: +res.amount });
            });
        } else if (_formData.price) {
        } else {
            set_formData({ ..._formData, price: 0 });
        }
    }, []);

    const getClearNumber = number => {
        let table = number.split("");
        if (number === "") return 0;
        else if (number === "0" || number === 0) return number;
        else if (table[0] === "0" || table[0] === 0) {
            table.shift();

            return getClearNumber(table.join(""));
        } else return table.join("");
    };

    return (
        <>
            {_formData.price !== undefined && _formData.price !== null ? (
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    {/* <div>
                        <Checkbox value={freeProject} onChange={e => setFreeProject(e.target.checked)} />
                    </div> */}

                    <TextField
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                        align="center"
                        style={{ backgroundColor: "white" }}
                        label="Prix du projet"
                        size="small"
                        variant="outlined"
                        type="number"
                        value={_formData.price}
                        onChange={e => {
                            if (e.nativeEvent.inputType === "insertText" || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "insertFromPaste") {
                                set_formData({ ..._formData, price: e.target.value === "" ? "" : +e.target.value });
                            }
                        }}
                        onBlur={e => {
                            set_formData({ ..._formData, price: getClearNumber(e.target.value) });
                        }}
                    />
                </Grid>
            ) : (
                <CircularProgress size={20} style={{ fontSize: 20, marginLeft: 15 }} />
            )}
        </>
    );
}
