import { Grid, TextField } from "@material-ui/core";
import React from "react";

export default function Description({ _formData, set_formData, label }) {
  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <TextField
        style={{ backgroundColor: "white" }}
        fullWidth
        label={label}
        size="small"
        variant="outlined"
        multiline
        rows={6}
        value={_formData.description}
        onChange={(e) =>
          set_formData({ ..._formData, description: e.target.value })
        }
      />
    </Grid>
  );
}
