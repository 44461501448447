import Courrier from "../../TasksList/Courrier";
import Formulaire from "../../TasksList/Formulaire";
import Paiement from "../../TasksList/Paiement";
import Questionnaire from "../../TasksList/Questionnaire";
import Rdv from "../../TasksList/Rdv";
import Signature from "../../TasksList/Signature";
import Texte from "../../TasksList/Texte";
import Upload from "../../TasksList/Upload";

const getTaskType = (type, _formData, set_formData, _paymentForm, set_paymentForm, save_function) => {
    switch (type) {
        case "text":
            return <Texte _formData={_formData} set_formData={set_formData} _paymentForm={_paymentForm} set_paymentForm={set_paymentForm} />;
        case "payment":
            return <Paiement _formData={_formData} set_formData={set_formData} _paymentForm={_paymentForm} set_paymentForm={set_paymentForm} />;
        case "interview":
            return <Questionnaire save_function={save_function} _formData={_formData} set_formData={set_formData} />;
        case "electronic":
            return <Signature _formData={_formData} set_formData={set_formData} />;
        case "courrier":
            return <Courrier _formData={_formData} set_formData={set_formData} />;
        case "form":
            return <Formulaire _formData={_formData} set_formData={set_formData} />;
        case "upload":
            return <Upload _formData={_formData} set_formData={set_formData} />;
        case "rdv":
            return <Rdv _formData={_formData} set_formData={set_formData} />;

        default:
            return <Texte _formData={_formData} set_formData={set_formData} />;
    }
};

export default getTaskType;
