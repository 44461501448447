import {
  FormControl,
  MenuItem,
  Select,
  Grid,
  InputLabel,
  TextField,
  Divider,
} from "@material-ui/core";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import React, { useState } from "react";
import MappingComplexInput from "../MappingComplexInput";
import SelectObjectModelField from "../SelectObjectModelField";
import SelectObjectModel from "../SelectObjectModel";

export default ({
  variableDialog,
  setvariableDialog,
  setoriginalvariableType,
  originalvariableType,
}) => {
  const [databaseObjectModelName, setDatabaseObjectModelName] = useState("");
  React.useEffect(() => {
    if (originalvariableType !== "unisign") {
      setvariableDialog({
        ...variableDialog,
        options: null,
        placeholder: [
          { id: "email", value: "" },
          { id: "first_name", value: "" },
          { id: "last_name", value: "" },
        ],
      });
      setoriginalvariableType("unisign");
    }
  }, []);

  const optionsForm = [
    { id: "email", type: "email", value: "" },
    { id: "first_name", type: "text", value: "" },
    { id: "last_name", type: "text", value: "" },
  ];

  return (
    originalvariableType === "unisign" && (
      <>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            backgroundColor: "#eee",
            border: "1px solid silver",
          }}
        >
          <SelectObjectModel
            variableDialog={variableDialog}
            setvariableDialog={setvariableDialog}
            index={0}
          ></SelectObjectModel>
          <FormControl
            size="small"
            style={{ width: 300, backgroundColor: "white", marginLeft: 15 }}
            variant="outlined"
          >
            <InputLabel>Type de mapping</InputLabel>
            <Select
              label="Type de mapping"
              value={
                variableDialog.database_object_model_fields_mapping[0]
                  .mappingType
              }
              onChange={(e) => {
                let result = { ...variableDialog };
                result.database_object_model_fields_mapping[0].mappingType =
                  e.target.value;
                setvariableDialog(result);
              }}
            >
              <MenuItem value={"simple"}>Mono-sélection</MenuItem>
              {/* <MenuItem value={"address"}>Adresse</MenuItem> */}
              <MenuItem value={"list_with_single_choice"}>
                Mono-sélection dans une liste
              </MenuItem>
              <MenuItem value={"list_with_multiple_choices"}>
                Multi-sélection dans une liste
              </MenuItem>
            </Select>
          </FormControl>
          <Divider
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          />
          {optionsForm &&
            optionsForm?.map((el, key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <SubdirectoryArrowRightIcon style={{ marginLeft: 10 }} />
                <TextField
                  disabled
                  style={{
                    backgroundColor: "white",
                    marginLeft: 10,
                    marginRight: 10,
                    width: 400,
                  }}
                  label={"Element " + (key + 1)}
                  size="small"
                  fullWidth
                  variant="outlined"
                  type="text"
                  defaultValue={
                    key === 0 ? "Email" : key === 1 ? "Nom" : "Prénom"
                  }
                />

                <SelectObjectModelField
                  variableDialog={variableDialog}
                  setvariableDialog={setvariableDialog}
                  elementKey={key}
                  tabValue={1}
                ></SelectObjectModelField>

                <FormControl
                  size="small"
                  style={{
                    width: 200,
                    backgroundColor: "white",
                    marginLeft: 10,
                  }}
                  variant="outlined"
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    disabled
                    label="Type"
                    defaultValue={optionsForm[key].type}
                  >
                    <MenuItem value={"text"}>Texte</MenuItem>
                    <MenuItem value={"email"}>Email</MenuItem>
                  </Select>
                </FormControl>
                {
                  <TextField
                    label={"Valeur pré-remplie"}
                    value={variableDialog.placeholder[key].value}
                    onChange={(e) => {
                      let result = { ...variableDialog };
                      result.placeholder[key].value = e.target.value;
                      setvariableDialog(result);
                    }}
                    type={el.type}
                    variant="outlined"
                    size="small"
                    style={{
                      marginLeft: 10,
                      backgroundColor: "white",
                      width: 300,
                    }}
                  />
                }
              </div>
            ))}
        </Grid>
      </>
    )
  );
};
