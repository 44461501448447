export default function getColorEdit(type) {
    switch (type) {
        case "variable":
            return "rgba(0, 219, 92, 0.8)";
        case "condition":
            return "rgba(249, 59, 59,0.8)";
        case "repetition":
            return "rgba(0, 184, 217, 0.8)";
        default:
            return "yellow";
    }
}
