let sas = {
  name: "SAS",
  parent: "Entité",
  fields: [
    "Dénomination sociale",
    "Forme sociale",
    "Adresse du siège social",
    "Ville d'immatriculation",
    "Numéro d'immatriculation",
    "Numéro de SIREN",
    "Capital social",
    // "Statuts",
    // "Commissaires aux comptes",
    // "Baux",
    // "Sociétés filles",
    // "Actionnaires",
    // "Membres du CSE",
    // "Délégués du personnel",
    // "Membres du conseil stratégique",
  ],
};

export { sas };
