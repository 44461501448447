import ActiveEditorContext from "../../../providers/ActiveEditorContext";
import FormAddProject from "./EditorForms/FormAddProject";
import FormAddSection from "./EditorForms/FormAddSection";
import FormAddTask from "./EditorForms/FormAddTask";
import FormProject from "./EditorForms/FormProject";
import FormSection from "./EditorForms/FormSection";
import FormTask from "./EditorForms/FormTask";
import EditorRoot from "./EditorRoot.js";
import { Paper, Divider, CircularProgress } from "@material-ui/core/";
import React, { useState, useContext, useEffect } from "react";
import config from "~/config";

const Editor = () => {
    const { activeEditor } = useContext(ActiveEditorContext);

    const [_loading, set_loading] = useState(true);
    useEffect(() => {
        set_loading(true);
        setTimeout(() => {
            set_loading(false);
        }, 700);
    }, [activeEditor]);

    const getEditorRender = () => {
        switch (activeEditor.type) {
            case null:
                return <>Veuillez sélectionner un élément</>;
            case "project":
                return <FormProject />;
            case "section":
                return <FormSection />;
            case "task":
                return <FormTask />;
            case "add_project":
                return <FormAddProject />;
            case "add_section":
                return <FormAddSection />;
            case "add_task":
                return <FormAddTask />;
            default:
                return <></>;
        }
    };

    const color_project = localStorage.getItem("color-project") ? localStorage.getItem("color-project") : config.defaultColors.project;
    const color_section = localStorage.getItem("color-section") ? localStorage.getItem("color-section") : config.defaultColors.section;
    const color_task = localStorage.getItem("color-task") ? localStorage.getItem("color-task") : config.defaultColors.task;

    const getColor = (data, opacity) => {
        if (data?.type?.indexOf("add") > -1) {
            if (!data.root) return `rgba(${color_project},${opacity})`;
            else if (data.root?.length === 1) return `rgba(${color_section},${opacity})`;
            else if (data.root?.length === 2) return `rgba(${color_task},${opacity})`;
        } else {
            if (data.root?.length === 1) return `rgba(${color_project},${opacity})`;
            else if (data.root?.length === 2) return `rgba(${color_section},${opacity})`;
            else if (data.root?.length === 3) return `rgba(${color_task},${opacity})`;
        }
    };

    return (
        <Paper style={{ padding: "25px", minHeight: "70vh", backgroundColor: getColor(activeEditor, 0.05) }} elevation={6}>
            {_loading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                    <CircularProgress size={20} />
                </div>
            ) : (
                <>
                    <EditorRoot />
                    <Divider style={{ margin: "10px 10% 20px 10%", width: "80%", height: "1px", borderRadius: "5px", backgroundColor: getColor(activeEditor, 1) }} />
                    {getEditorRender()}
                </>
            )}
        </Paper>
    );
};

export default Editor;
