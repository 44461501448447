import TemplateLib from "@common/libs/interview-template-lib";
import { Button } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { toast } from "react-toastify";
import config from "~/config";

const useStyles = makeStyles(({ palette, spacing }) => ({
    line: {
        display: "flex",
        justifyContent: "center",
        margin: "10px 0",
    },
    project: {
        padding: "6px 9px",
        borderRadius: 5,
        fontWeight: "bold",
        textAlign: "center",
    },
    buttonLine: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 50,
    },
    document: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 20,
        marginBottom: 10,
    },
}));

export default function MoveModal({ projectList, myTemplate, setmodalAction, setOpenMenu, set_reload }) {
    const classes = useStyles();
    const color_project = localStorage.getItem("color-project") ? localStorage.getItem("color-project") : config.defaultColors.project;

    const deleteDocument = () => {
        if (window.confirm("Voulez vous vraiment supprimer ce document ?")) {
            TemplateLib.deleteDocument(myTemplate.id)
                .then(res => {
                    toast.success("Le document a bien été supprimé");
                    setmodalAction(null);
                    setOpenMenu(null);
                    set_reload(reload => !reload);
                })
                .catch(() => {
                    toast.success("Erreur durant la suppression du document");
                });
        }
    };

    return (
        <div style={{ width: "100%" }}>
            <div className={classes.line} style={{ display: "block" }}>
                <div className={classes.document}>{myTemplate.name}</div>
                <div className={classes.line}>Workspace : {projectList.find(el => el.id === myTemplate.project).workspace} </div>
                <div className={classes.project} style={{ color: `rgb(${color_project})`, backgroundColor: `rgba(${color_project} , 0.3)` }}>
                    Projet : {projectList.find(el => el.id === myTemplate.project).name}
                </div>
            </div>

            <div className={classes.buttonLine}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setmodalAction(null);
                        setOpenMenu(null);
                    }}>
                    Annuler
                </Button>
                <Button onClick={deleteDocument} style={{ marginLeft: 15 }} color="secondary" variant="contained">
                    Supprimer ce document
                </Button>
            </div>
        </div>
    );
}
