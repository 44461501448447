import { Grid, Chip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

export default ({ variableDialog, setvariableDialog, setoriginalvariableType, originalvariableType }) => {
    React.useEffect(() => {
        if (originalvariableType !== "list") {
            setvariableDialog({ ...variableDialog, options: null, placeholder: [] });
            setoriginalvariableType("list");
        }
    }, []);

    return (
        originalvariableType === "list" && (
            <>
                <Grid style={{ backgroundColor: "#eee", margin: "0 20px", border: "1px solid silver", padding: "10px 20px" }} item lg={12} md={12} sm={12} xs={12}>
                    <div>Liste pré-remplie :</div>
                    <Autocomplete
                        style={{ backgroundColor: "white", width: 500, marginTop: 10 }}
                        multiple
                        size="small"
                        freeSolo
                        value={variableDialog.placeholder ? variableDialog.placeholder : []}
                        onChange={(e, v) => {
                            setvariableDialog({ ...variableDialog, placeholder: v });
                        }}
                        options={[]}
                        renderTags={(value, getTagProps) => value.map((option, index) => <Chip key={index} variant="outlined" style={{ borderRadius: 5 }} color="primary" label={option} {...getTagProps({ index })} />)}
                        renderInput={params => <TextField {...params} variant="outlined" />}
                    />
                </Grid>
            </>
        )
    );
};
