import StoreDataContext from "../../../../../providers/StoreDataContext";
import { Grid, TextField, Checkbox, Chip, Collapse } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function Subdomains({ _formData, set_formData }) {
    const { storeData } = React.useContext(StoreDataContext);
    const [_disabledEntities, set_disabledEntities] = useState(_formData.entities.length === 0);
    const list_entities = [].concat(storeData.groups).concat(storeData.enterprises).concat(storeData.establishments);

    const getType = type => {
        switch (type) {
            case "Groupes":
                return "group";
            case "Sociétés":
                return "enterprise";
            case "Etablissements":
                return "establishment";
            default:
                return "";
        }
    };

    const getColor = type => {
        switch (type) {
            case "Groupes":
                return "230, 81, 0";
            case "Sociétés":
                return "255, 160, 0";
            case "Etablissements":
                return "251, 192, 45";
            default:
                return "50,50,50";
        }
    };

    return (
        <>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Checkbox
                    checked={_disabledEntities}
                    onChange={() => {
                        set_disabledEntities(!_disabledEntities);
                        set_formData({ ..._formData, entities: [], set_entities: [] });
                    }}
                />
                <span
                    style={{ fontSize: "16px", cursor: "pointer" }}
                    onClick={() => {
                        set_disabledEntities(!_disabledEntities);
                        set_formData({ ..._formData, entities: [], set_entities: [] });
                    }}>
                    Modèle partagé à toutes les entités
                </span>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Collapse in={!_disabledEntities}>
                    <Autocomplete
                        multiple
                        size="small"
                        disableCloseOnSelect
                        options={list_entities}
                        groupBy={option => option.render_type}
                        getOptionLabel={option => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                <div
                                    style={{
                                        color: `rgba(${getColor(option.render_type)},1)`,
                                        fontSize: "14px",
                                        backgroundColor: `rgba(${getColor(option.render_type)},0.2)`,
                                        width: "100%",
                                        border: `2px solid rgba(${getColor(option.render_type)},1)`,
                                        borderRadius: "5px",
                                        padding: "5px 5px 5px 15px",
                                    }}>
                                    {option.name}
                                </div>
                            </React.Fragment>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    style={{
                                        color: `rgba(${getColor(option.render_type)},1)`,
                                        fontSize: "14px",
                                        backgroundColor: `rgba(${getColor(option.render_type)},0.2)`,
                                        border: `2px solid rgba(${getColor(option.render_type)},1)`,
                                        borderRadius: "5px",
                                        marginRight: "5px",
                                        marginTop: "5px",
                                        padding: "0px",
                                        fontSize: "12px",
                                    }}
                                    label={option.render_type.slice(0, -1) + " - " + option.name}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        value={_formData.entities}
                        onChange={(e, v) => set_formData({ ..._formData, entities: v, set_entities: v.map(elem => ({ entity_id: elem.id, entity_type: getType(elem.render_type) })) })}
                        renderInput={params => <TextField fullWidth {...params} variant="outlined" label="Choisir entités" placeholder="Rechercher" style={{ backgroundColor: "white" }} />}
                    />
                </Collapse>
            </Grid>
        </>
    );
}
