////// React Core :
import FilterSite from "./components/_common/FilterSite";
import Layout from "./components/_common/layout";
import ActiveEditorContext from "./providers/ActiveEditorContext";
import FiltersContext from "./providers/FiltersContext";
import StoreDataContext from "./providers/StoreDataContext";
import TreeContext from "./providers/TreeContext";
import routes from "./routes";
import EnterpriseLib from "@common/libs/enterprises-lib";
import EstablishmentLib from "@common/libs/establishments-lib";
import GroupLib from "@common/libs/groups-lib";
import SitesLib from "@common/libs/sites-lib";
import StripeLib from "@common/libs/stripe-lib";
import TokenData from "@common/models/token-data";
import { CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App({ isLogged, setIsLogged }) {
  const history = useHistory();
  const location = useLocation();
  const [tree, setTree] = useState({
    projects: "loading",
    selected: "",
    expanded: [],
  });
  const [activeEditor, setActiveEditor] = useState({ type: null, data: null });
  const [storeData, setStoreData] = useState({
    sites: null,
    specialities: [],
    subdomains: [],
    groups: [],
    enterprises: [],
    establishments: [],
    taxes: [],
  });
  const [filtersData, setFiltersData] = useState(
    sessionStorage.getItem("filters")
      ? JSON.parse(sessionStorage.getItem("filters"))
      : {
          site: null,
          entities: [],
          speciality: 1,
          project: "",
          categories: null,
          reload: null,
        }
  );

  const contextFilters = {
    filtersData,
    updateFiltersData: setFiltersData,
  };

  const contextStore = {
    storeData,
    updateStoreData: setStoreData,
  };

  const contextTree = {
    tree,
    updateTree: setTree,
  };
  const contextEditor = {
    activeEditor,
    updateActiveEditor: setActiveEditor,
  };

  useEffect(() => {
    const token = TokenData.fromStorage();
    if ((token && token.isExpired()) || !token) {
      setIsLogged(false);
    } else {
      if (location.pathname === "/") history.push("/tree");

      Promise.all([SitesLib.getListSites(), StripeLib.getTaxeList()]).then(
        (responses) => {
          const sites = responses[0];
          const taxes = responses[1];
          setStoreData((storeData) => ({ ...storeData, sites, taxes }));
        }
      );

      // Promise.all([SitesLib.getListSites(), GroupLib.getAll(), EnterpriseLib.getAll(), EstablishmentLib.getAll(), StripeLib.getTaxeList()]).then(responses => {
      //     const sites = responses[0];
      //     const groups = responses[1].map(el => ({ ...el, render_type: "Groupes" }));
      //     const enterprises = responses[2].results.map(el => ({ ...el, render_type: "Sociétés" }));
      //     const establishments = responses[3].map(el => ({ ...el, render_type: "Etablissements" }));
      //     const taxes = responses[4];
      //     setStoreData(storeData => ({ ...storeData, groups, sites, enterprises, establishments, taxes }));
      // });
    }
  }, []);

  return (
    isLogged && (
      <Layout>
        <ToastContainer />
        <TreeContext.Provider value={contextTree}>
          <ActiveEditorContext.Provider value={contextEditor}>
            <StoreDataContext.Provider value={contextStore}>
              <FiltersContext.Provider value={contextFilters}>
                {storeData.sites === null ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 100,
                    }}
                  >
                    <CircularProgress size={40} />
                  </div>
                ) : (
                  <>
                    {location.pathname.split("/").length < 3 &&
                      location.pathname.indexOf("settings") === -1 && (
                        <FilterSite />
                      )}
                    {routes.map((route, index) => (
                      <Route
                        key={index}
                        name={route.name}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                      />
                    ))}
                  </>
                )}
              </FiltersContext.Provider>
            </StoreDataContext.Provider>
          </ActiveEditorContext.Provider>
        </TreeContext.Provider>
      </Layout>
    )
  );
}
