import { ApiServiceRaw } from '../services/api-service';
import baseAxios from 'axios';
import User from '../models/user';
import TokenData from '../models/token-data';
import path from 'path';
import EnterprisesLib from './enterprises-lib';
import GroupsLib from './groups-lib';

const axios = baseAxios.create({
  baseURL: process.env.COMMON_LAW_OAUTH_API_URL,
});

const ApiService = new ApiServiceRaw(process.env.COMMON_LAW_OAUTH_API_URL);

export default class TemplateLib {
  // Site

  static SitesPath = 'api/sites/{id}';

  static async getListSites() {
    const response = await ApiService.get(this.SitesPath.replace('{id}', ''));
    return response.data.results;
  }

  static async getSite(id) {
    const response = await ApiService.get(this.SitesPath.replace('{id}', id));
    return response.data;
  }

  static async newSite(data) {
    const response = await ApiService.post(this.SitesPath.replace('{id}', ''), data);
    return response.data;
  }

  static async editSite(id, data) {
    const response = await ApiService.put(this.SitesPath.replace('{id}', id) + '/', data);
    return response.data;
  }

  static async deleteSite(id) {
    const response = await ApiService.delete(this.SitesPath.replace('{id}', id));
    return response.data;
  }
}
