////// React Core :
import ActiveEditorContext from "../../../../providers/ActiveEditorContext";
import TreeContext from "../../../../providers/TreeContext";
import Button from "@material-ui/core/Button";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import ShareIcon from "@material-ui/icons/Share";
import React, { useContext } from "react";
import config from "~/config";

export default function ProjectLine(props) {
  const { updateActiveEditor } = useContext(ActiveEditorContext);
  const { tree, updateTree } = useContext(TreeContext);

  const { nodeId, data, root, root_key, icon } = props;
  const color_project = localStorage.getItem("color-project")
    ? localStorage.getItem("color-project")
    : config.defaultColors.project;
  const color_project_shared = localStorage.getItem("color-project-shared")
    ? localStorage.getItem("color-project-shared")
    : config.defaultColors.project_shared;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "45px",
        transition: "0.3s",
        backgroundColor:
          tree.selected === nodeId.trim()
            ? `rgba(${
                localStorage.getItem("color-selected")
                  ? localStorage.getItem("color-selected")
                  : config.defaultColors.selected
              },0.6)`
            : "initial",
      }}
      className="clickable_line"
    >
      <b
        className="clickable_line"
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "22px",
          width: "75%",
          color: `rgb(${
            data.entities.length === 0 ? color_project_shared : color_project
          })`,
        }}
        onClick={() => {
          updateActiveEditor({
            type: "project",
            data,
            root,
            root_key,
          });
        }}
      >
        {icon ? (
          <div className="clickable_line" />
        ) : (
          <CollectionsBookmarkIcon
            className="clickable_line"
            style={{ marginRight: "5px" }}
          />
        )}
        {data.name} (id: {data.id})
        {data.entities.length === 0 && (
          <ShareIcon
            onClick={() => {
              updateActiveEditor({
                type: "project",
                data,
                root,
                root_key,
              });
            }}
            className="clickable_line"
            style={{ marginLeft: "10px" }}
          />
        )}
      </b>
      {tree.selected === nodeId && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            updateTree({
              ...tree,
              expanded: [...tree.expanded, root_key + ""],
            });
            updateActiveEditor({
              type: "add_section",
              data: null,
              root,
              root_key,
            });
          }}
          style={{
            padding: "3px 7px",
            marginRight: "5px",
          }}
        >
          + Ajouter section
        </Button>
      )}
    </div>
  );
}
