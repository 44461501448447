import DocumentContext from "../../../providers/DocumentContext"
import TemplateTaskLib from "@common/libs/template-lib"
import TemplateLib from "@common/libs/interview-template-lib"
import VariableDialog from "../ListVariables/VariableDialog";
import { Button, FormControl, TextField } from "@material-ui/core/";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useContext } from "react";

export default function PdfMapping() {
  const [variableDialog, setvariableDialog] = useState(false);
  const { document, updateDocument } = useContext(DocumentContext);
  const variables = document.variables
    .filter(
      (el) =>
        el.type === "number" ||
        el.type === "text" ||
        el.type === "textformat" ||
        el.type === "date" ||
        el.type === "object" ||
        el.type === "bool" ||
        el.type === "choice" ||
        el.type === "multi" ||
        el.type === "list" ||
        el.type === "unisign" ||
        el.type === "array"
      // el.type === "multisign" ||
      // el.type === "composable" ||
    )
    .map((el) => ({
      ...el,
      groupName:
        document.groupsVariables.find((elem) => elem.id === el.group) !==
        undefined
          ? document.groupsVariables.find((elem) => elem.id === el.group).name
          : "Aucune page",
    }));
  const getObjects = (list) => {
    let result = [];
    list.forEach((elem) => {
      if (elem.type !== "object") result.push(elem);
      else {
        elem.options.forEach((el) => {
          result.push({
            ...elem,
            name: elem.name + " -> " + el.label,
            id: elem.id + "_" + el.id,
          });
        });
      }
    });
    return result;
  };

  const options = getObjects(variables);

  console.log("options", options);

  if (!document.pdf_mapping) {
    document.pdf_mapping = [];
  } else {
    // setFields(document.pdf_mapping)
  }

  console.log("document.pdf_mapping", document.pdf_mapping);

  const [fields, setFields] = useState(document.pdf_mapping);

  React.useEffect(() => {
    setFields(document.pdf_mapping);
  }, [document]);

  const [projectName, setprojectName] = useState(null);
  const [taskName, settaskName] = useState(null);

  function updatePdfMapping(updatedField, variable) {
    console.log("updatePdfMapping", updatedField, variable);
    let value;
    // if (variable.type in ["object"]) {
    //   value = String(variable.id);
    // }
    value = String(variable.id);

    const nextFields = fields.map((field) => {
      if (updatedField == field) {
        return { ...field, value: value };
      }
      return field;
    });

    setFields(nextFields);
    const updatedDocument = { ...document, pdf_mapping: nextFields };
    updateDocument(updatedDocument);
    TemplateLib.updateDocument(document.id, updatedDocument).then((res) => {});
    console.log("updatePdfMapping2", updatedField, value);
  }

  React.useEffect(() => {
    if (document.task) {
      TemplateTaskLib.getSpecificTask(document.task)
        .then((res) => {
          settaskName(res.name);
        })
        .catch(() => settaskName("/"));
    } else settaskName("/");

    TemplateTaskLib.getProject(document.project)
      .then((res) => {
        setprojectName(res.name);
      })
      .catch(() => settaskName("/"));
  }, [document]);

  console.log("need to load fields?", document.pdf_mapping);
  if (document.pdf_mapping.length == 0) {
    // console.log("loading fields")
    // TemplateLibInterview.getPdfFields(document.id)
    //   .then((res) => {
    //     console.log("pdf fields", res)
    //     document.pdf_mapping = res
    //     setFields(res)
    //     updateDocument({ ...document, pdf_mapping: res })
    //     TemplateLib.updateDocument(document.id, document).then((res) => {
    //       // updateDocument({ ...document, launchSave: res.id })
    //     })
    //   })
    //   .catch((e) => console.log("e", e))
  } else {
    console.log("already have fields");
  }

  // React.useEffect(() => {
  // TemplateLibInterview.getPdfFields(document.id)
  //   .then((res) => {
  //     console.log("pdf fields", res)
  //     document.pdf_mapping = res
  //     listItems = document.pdf_mapping.map((field) => (
  //       <li>{field.field_name}</li>
  //     ))
  //     setFields(res)
  //   })
  //   .catch((e) => console.log("e", e))
  // }, [document.pdf_mapping]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: 450,
        marginBottom: 40,
        marginTop: -20,
      }}
    >
      {document.pdf && (
        <div style={{ width: "80%" }}>
          <h3>Mapping formulaire PDF</h3>
          {fields.map((el, key) => (
            <div key={key}>
              <FormControl
                fullWidth
                label="Titre du document"
                size="small"
                style={{ backgroundColor: "white" }}
              >
                <h6>{el.field_name}</h6>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Autocomplete
                    options={options}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option) =>
                      String(option.id) === el.value
                    }
                    value={
                      options.find((e) => String(e.id) === el.value) || null
                    }
                    onChange={(e, v) => updatePdfMapping(el, v)}
                    disableClearable
                    renderInput={(params) => (
                      <TextField {...params} label="" variant="outlined" />
                    )}
                    style={{ flex: 20 }}
                    />
                    {/* To create a new variable */}
                  <Button
                    onClick={
                      () =>
                        setvariableDialog({
                          name: el.field_name,
                          type: el.type === "boolean" ? "bool" : "text",
                          is_global: true,
                          question: "--",
                          description: "",
                          order_in_group: null,
                          options: null,
                          placeholder: null,
                          project: 0,
                          field: null,
                          group: null,
                          id: null,
                          is_mail_merging: false,
                          role: "",
                          database_object_model_fields_mapping: [
                            {
                              database_object_model_name: "",
                              mappingType: "simple",
                              data: [
                                {
                                  mapping_complex: "",
                                  database_object_model_field_name: "",
                                  variable_field_name: "",
                                },
                              ],
                            },
                          ],
                        })//.then((newVar) => console.log('OO',newVar)) //updatePdfMapping(el, v))
                    }
                    style={{ flex: 1 }}
                  >
                    +
                  </Button>
                </div>
              </FormControl>
            </div>
          ))}

          <VariableDialog
            variableDialog={variableDialog}
            setvariableDialog={setvariableDialog}
          />
        </div>
      )}
    </div>
  );
}
