import {
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Divider,
} from "@material-ui/core";
import RolesInput from "../RolesInput";
import MappingComplexInput2 from "../MappingComplexInput2";
import SelectObjectModelField from "../SelectObjectModelField";
import SelectObjectModel from "../SelectObjectModel";
import React from "react";
import HelpIcon from "@material-ui/icons/Help";
import { withStyles } from "@material-ui/core/styles";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgb(235,235,235)",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #222",
    padding: "10px",
    minWidth: "400px",
  },
}))(Tooltip);

export default ({
  variableDialog,
  setvariableDialog,
  type,
  setoriginalvariableType,
  originalvariableType,
}) => {
  const getPlaceholder = () => {
    switch (type) {
      case "text":
        return "Texte auto-rempli";
      case "number":
        return "Nombre auto-rempli";
      case "date":
        return "Date auto-remplie";
      case "textformat":
        return "Texte auto-remplie";

      default:
        return "/";
    }
  };

  React.useEffect(() => {
    switch (type) {
      case "text":
        if (originalvariableType !== "text") {
          setvariableDialog({
            ...variableDialog,
            placeholder: { value: "" },
            options: null,
          });
          setoriginalvariableType("text");
        }
        break;
      case "number":
        if (originalvariableType !== "number") {
          setvariableDialog({
            ...variableDialog,
            placeholder: { value: 0 },
            options: null,
          });
          setoriginalvariableType("number");
        }
        break;
      case "date":
        if (originalvariableType !== "date") {
          setvariableDialog({
            ...variableDialog,
            placeholder: { value: "" },
            options: null,
          });
          setoriginalvariableType("date");
        }
        break;
      case "textformat":
        if (originalvariableType !== "textformat") {
          setvariableDialog({
            ...variableDialog,
            placeholder: { value: "" },
            options: null,
          });
          setoriginalvariableType("textformat");
        }
        break;

      default:
        return "d";
    }
  }, [type]);

  return (
    (originalvariableType === "text" ||
      originalvariableType === "number" ||
      originalvariableType === "date" ||
      originalvariableType === "textformat") && (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "start",
            backgroundColor: "#eee",
            margin: "0 20px",
            flexDirection: "column",
            padding: "10px",
            width: "100%",
            border: "1px solid silver",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "start",
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  marginRight: "15px",
                }}
              >
                <SelectObjectModel
                  variableDialog={variableDialog}
                  setvariableDialog={setvariableDialog}
                  index={0}
                ></SelectObjectModel>
              </div>
              <FormControl
                size="small"
                style={{
                  width: 300,
                  backgroundColor: "white",
                  marginRight: 15,
                }}
                variant="outlined"
              >
                <InputLabel>Type de mapping</InputLabel>
                <Select
                  label="Type de mapping"
                  value={
                    variableDialog.database_object_model_fields_mapping[0]
                      .mappingType
                    // "Simple"
                  }
                  onChange={(e) => {
                    let result = { ...variableDialog };
                    result.database_object_model_fields_mapping[0].mappingType =
                      e.target.value;
                    setvariableDialog(result);
                  }}
                >
                  <MenuItem value={"simple"}>Mono-sélection</MenuItem>
                  {/* <MenuItem value={"address"}>Adresse</MenuItem> */}
                  <MenuItem value={"list_with_single_choice"}>
                    Mono-sélection dans une liste
                  </MenuItem>
                  <MenuItem value={"list_with_multiple_choices"}>
                    Multi-sélection dans une liste
                  </MenuItem>
                </Select>
              </FormControl>

              {/* <SelectObjectModelField
                variableDialog={variableDialog}
                setvariableDialog={setvariableDialog}
                elementKey={0}
                tabValue={1}
              ></SelectObjectModelField> */}
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "start",
                marginBottom: 5,
              }}
            >
              <MappingComplexInput2
                style={{
                  backgroundColor: "white",
                  width: "100%",
                }}
                variableDialog={variableDialog}
                setvariableDialog={setvariableDialog}
              ></MappingComplexInput2>

              <div
                style={{
                  width: "100%",
                  maxWidth: 300,
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              >
                <RolesInput
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                  }}
                  variableDialog={variableDialog}
                  setvariableDialog={setvariableDialog}
                ></RolesInput>
              </div>
            </div>
            <Divider
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              margin: "0 20px",
            }}
          >
            {getPlaceholder()} :{" "}
            <TextField
              variant="outlined"
              type={type}
              style={{
                backgroundColor: "white",
                width: "100%",
                maxWidth: 400,
                marginLeft: 15,
              }}
              size="small"
              value={variableDialog?.placeholder?.value}
              onChange={(e) => {
                setvariableDialog({
                  ...variableDialog,
                  placeholder: {
                    value: type === "number" ? +e.target.value : e.target.value,
                  },
                });
              }}
            />
            {variableDialog?.type === "number" && (
              <IconButton>
                <HtmlTooltip
                  arrow
                  placement="top-center"
                  title={
                    <React.Fragment>
                      <div>
                        Pour insérer un nombre à virgules, une seule virgule est
                        possible et vous avez la possiblité de soit :
                      </div>
                      <ul>
                        <li>
                          Utiliser la touche du clavier <kbd>,</kbd> pour
                          ajouter une virgule
                        </li>
                        <li>
                          Utiliser la touche du clavier <kbd>.</kbd> pour
                          ajouter une virgule seulement quand le curseur se
                          trouve entre deux chiffres
                        </li>
                      </ul>
                    </React.Fragment>
                  }
                >
                  <HelpIcon />
                </HtmlTooltip>
              </IconButton>
            )}
          </div>
        </div>
      </>
    )
  );
};
