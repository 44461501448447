import routes from "../../routes";
import Title from "../login/title";
import { UilAngleDoubleRight, UilAngleDoubleLeft } from "@iconscout/react-unicons";
import { Drawer, Menu, MenuItem } from "@material-ui/core/";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import AccountLib from "~/common/libs/account-lib";

const drawerWidth = 275;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
    },
    title: {
        color: "black",
        fontFamily: "Ubuntu, Helvetica, sans-serif",
        fontSize: "28px",
        fontWeight: "bold",
        "& span": {
            color: theme.palette.primary.main,
        },
        "& small": {
            color: theme.palette.grey.light,
            fontWeight: 400,
            marginLeft: theme.spacing(1),
        },
    },
    appBar: {
        color: "black",
        backgroundColor: "white",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: "none",
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        cursor: "pointer",
        marginRight: 36,
        transition: "0.2s",
        "&:hover": {
            color: "#5469D4",
        },
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(14) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        minHeight: "100px!important",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    item: {
        display: "flex",
        alignItems: "center",
        padding: "0px 15px",
        margin: "0px 25px",
        height: 40,
        fontSize: 14,
        color: "rgb(73, 72, 82)",
        transition: "0.2s",
        cursor: "pointer",
        "&:hover": {
            "& svg": {
                color: "#5469D4",
            },
            "& span": {
                color: "#5469D4",
            },
        },
    },
    menu: {
        marginTop: 55,
        color: "#5469D4",
    },
}));

export default function MiniDrawer(props) {
    const { children } = props;
    const [user, setuser] = React.useState({
        first_name: "",
        last_name: "",
        role: "",
        workspace: "",
    });

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [badgeVisible, setbadgeVisible] = React.useState(true);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        window.addEventListener("resize", event => {
            if (window.innerWidth < 950) setbadgeVisible(false);
            else setbadgeVisible(true);
        });
        if (window.innerWidth < 950) {
            setOpen(false);
        }

        AccountLib.getUserProfile().then(res => {
            setuser(res);
        });
    }, []);

    const getRole = () => {
        switch (user.role) {
            case "CUSTOMER":
                return "Client";
            case "MEMBER":
                return "Membre";
            case "COUNCIL":
                return "Conseiller";
            case "ADMIN":
                return "Administarteur";
            default:
                break;
        }
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}>
                <Toolbar>
                    <UilAngleDoubleRight
                        size="40"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                        style={{ marginRight: 70, marginLeft: 10 }}
                    />

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", padding: "20px 5px" }}>
                        <h2 style={{ fontSize: 26, color: "#494852" }}>Éditeur de projets</h2>

                        <Menu className={classes.menu} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                            <MenuItem
                                onClick={() => {
                                    if (window.confirm("Voulez vous vraiment vous déconnecter ?")) {
                                        localStorage.removeItem("app_user");
                                        localStorage.removeItem("app_auth");
                                        localStorage.removeItem("user");
                                        localStorage.removeItem("store");
                                        window.location.href = window.location.origin;
                                    }
                                }}>
                                Déconnexion
                            </MenuItem>
                        </Menu>

                        <Button style={{ boxShadow: "none", border: "1px solid #ddd" }} onClick={handleClick}>
                            <div style={{ display: "flex", padding: "2px 5px" }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "#5469D4",
                                            borderRadius: 100,
                                            width: 40,
                                            height: 40,
                                            color: "white",
                                            fontWeight: "bold",
                                            margin: "0 5px 0 5px",
                                        }}>
                                        {user.first_name.slice(0, 1).toUpperCase()}
                                        {user.last_name.slice(0, 1).toUpperCase()}
                                    </div>
                                </div>
                                {badgeVisible && (
                                    <>
                                        <div style={{ marginLeft: 10 }}>
                                            <div style={{ fontWeight: "bold", textAlign: "left" }}>{user.first_name + " " + user.last_name}</div>
                                            <div>{getRole()}</div>
                                        </div>
                                        <hr style={{ margin: "0 15px", border: "1px solid #ddd", backgroundColor: "#ddd", borderRadius: 100 }} />
                                        <div>
                                            <div style={{ fontWeight: "bold" }}>{user.workspace}</div>
                                            <div>{user.is_admin ? "Administrateur" : "Standard"}</div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}>
                <div className={classes.toolbar}>
                    <Title />

                    <UilAngleDoubleLeft size="40" className={classes.menuButton} style={{ marginRight: 0 }} onClick={handleDrawerClose} />
                </div>

                <div style={{ marginTop: 20 }}>
                    {routes.map((route, index) => (
                        <Link
                            className={classes.item}
                            key={index}
                            to={route.path}
                            style={{
                                borderLeft: location.pathname.indexOf(route.path) > -1 ? "3px solid #5469D4" : "3px solid #ffffff",
                                color: location.pathname.indexOf(route.path) > -1 ? "#5469D4" : "inherit",
                                fontWeight: location.pathname.indexOf(route.path) > -1 ? "bold" : 500,
                            }}>
                            <route.icon size="24" />
                            {open && <span style={{ marginLeft: 10, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: 14 }}>{route.name} </span>}
                        </Link>
                    ))}
                </div>
            </Drawer>
            <main className={classes.content}>
                <div style={{ height: "80px", width: "100%" }}></div>
                <Container maxWidth="xl">{children}</Container>
            </main>
        </div>
    );
}
