import React, { useEffect } from "react";

export default function Texte(props) {
    const { _formData, set_formData } = props;

    useEffect(() => {
        let state = {
            text: _formData.actions[0].data.text,
        };
        set_formData({ ..._formData, actions: [{ ..._formData.actions[0], data: state }] });
    }, []);

    return (
        <h4 className="description" style={{ marginTop: "0px" }}>
            Le texte lu par l'utilisateur sera la description de la tâche.
        </h4>
    );
}
