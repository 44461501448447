import { Grid, TextField, Fade, Slide, Button, Checkbox as MaterialCheckbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    title: {
        color: "grey",
        fontSize: "12px",
    },
    inputLabel: {
        fontSize: "12px",
    },
    inputPlaceholder: {
        color: "#aaa",
        fontSize: "12px",
    },
}));

export default function Checkbox(props) {
    const classes = useStyles();
    const { _formData, set_formData, index } = props;

    const deleteLine = () => {
        let result = { ..._formData };
        result.actions[0].data.schema = result.actions[0].data.schema.filter(elem => elem.key !== _formData.actions[0].data.schema[index].key);
        set_formData(result);
    };

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
            <div>
                <Fade in={true} {...{ timeout: 1800 }}>
                    <div>
                        <small style={{ display: "flex", justifyContent: "center", marginBottom: "10px", fontWeight: "bold" }}>Case à cocher</small>

                        <Grid container style={{ marginBottom: "10px" }}>
                            <Grid item lg={7} md={7} sm={7} xs={12}>
                                <TextField
                                    multiline
                                    InputLabelProps={{
                                        className: classes.title,
                                    }}
                                    InputProps={{
                                        className: classes.inputLabel,
                                    }}
                                    fullWidth
                                    value={_formData.actions[0].data.schema[index].label}
                                    onChange={e => {
                                        let result = { ..._formData };
                                        result.actions[0].data.schema[index].label = e.target.value;
                                        set_formData(result);
                                    }}
                                    variant="outlined"
                                    size="small"
                                    label="Titre du champ"
                                    style={{ backgroundColor: "white" }}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <small className="description">par défaut : </small>
                                <MaterialCheckbox
                                    checked={_formData.actions[0].data.schema[index].checked}
                                    onChange={() => {
                                        let result = { ..._formData };
                                        result.actions[0].data.schema[index].checked = !result.actions[0].data.schema[index].checked;
                                        set_formData(result);
                                    }}
                                />
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button onClick={deleteLine} variant="contained" color="secondary" style={{ height: "27px", width: "60%", margin: "15% 0 0 10%" }}>
                                    <b>-</b>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </div>
        </Slide>
    );
}
