import { toast } from "react-toastify";

const error = (err = undefined, text) =>
    toast.error(
        <div>
            <div>Erreur survenue {text} :</div>
            {err && <div dangerouslySetInnerHTML={{ __html: JSON.stringify(err.response.data).replaceAll(/[}{]/gi, " ").replaceAll("[", " ").replaceAll("]", " ").replaceAll(",", "<br/>") }} />}
        </div>
    );

export default error;
