import Copy from "./action-modals/Copy";
import Delete from "./action-modals/Delete";
import Move from "./action-modals/Move";
import TemplateLib from "@common/libs/interview-template-lib";
import TemplateTaskLib from "@common/libs/template-lib";
import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@material-ui/core/";
import React, { useState, useContext } from "react";
import StoreDataContext from "~/providers/StoreDataContext";

export default function ModalAction({ modalAction, setmodalAction, setOpenMenu, set_reload }) {
    const { storeData } = useContext(StoreDataContext);
    const [projectList, setprojectList] = useState();
    const [myTemplate, setmyTemplate] = useState();

    React.useEffect(() => {
        Promise.all(storeData.sites.map(e => TemplateTaskLib.getListTemplateProjects(e.domain))).then(projects => {
            setprojectList(projects.map((el, key) => el.map(e => ({ ...e, workspace: storeData.sites[key].name, site: storeData.sites[key].domain }))).flat());
        });
    }, []);

    React.useEffect(() => {
        if (modalAction?.id) {
            TemplateLib.getDocument(modalAction.id).then(res => setmyTemplate(res));
        }
    }, [modalAction?.id]);

    return (
        Boolean(modalAction) && (
            <Dialog
                open={Boolean(modalAction)}
                onClose={() => {
                    setOpenMenu(null);
                    setmodalAction(null);
                }}
                fullWidth
                style={{ maxWidth: 600, margin: "0 auto" }}>
                <DialogTitle>
                    {modalAction.type === "move" && "Déplacer le modèle de document"}
                    {modalAction.type === "copy" && "Copier le modèle de document"}
                    {modalAction.type === "delete" && "Supprimer le modèle de document"}
                </DialogTitle>

                {projectList && myTemplate ? (
                    <DialogContent>
                        {modalAction.type === "move" && <Move projectList={projectList} myTemplate={myTemplate} setOpenMenu={setOpenMenu} setmodalAction={setmodalAction} set_reload={set_reload} />}
                        {modalAction.type === "copy" && <Copy projectList={projectList} myTemplate={myTemplate} setOpenMenu={setOpenMenu} setmodalAction={setmodalAction} set_reload={set_reload} />}
                        {modalAction.type === "delete" && <Delete projectList={projectList} myTemplate={myTemplate} setOpenMenu={setOpenMenu} setmodalAction={setmodalAction} set_reload={set_reload} />}
                    </DialogContent>
                ) : (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 150 }}>
                        <CircularProgress />
                    </div>
                )}
            </Dialog>
        )
    );
}
