import { TextField } from "@material-ui/core";
import React from "react";

export default ({ variableDialog, setvariableDialog, type }) => {
  return (
    <TextField
      variant="outlined"
      label="Rôle associé"
      type={type}
      style={{
        backgroundColor: "white",
        width: "100%",
        maxWidth: 300,
      }}
      size="small"
      value={variableDialog.role}
      onChange={(e) => {
        let result = { ...variableDialog };
        result.role = e.target.value;
        setvariableDialog(result);
      }}
    />
  );
};
