import OauthPermission from './oauth-permission';

export default class TokenData {
  constructor(jsonData) {
    this.accessToken = jsonData.access_token;
    this.accessTokenJwt = jsonData.access_token_jwt;
    this.refreshToken = jsonData.refresh_token;
    this.tokenType = jsonData.token_type;
    this.expiresIn = jsonData.expires_in;
    this.scope = jsonData.scope;
    this.createdAt = jsonData.created_at ? jsonData.created_at : Date.now();
  }

  static fromSearchParams(urlSearchParams) {
    const jsonData = {
      access_token: urlSearchParams.get('access_token'),
      access_token_jwt: urlSearchParams.get('access_token_jwt'),
      refresh_token: urlSearchParams.get('refresh_token'),
      token_type: urlSearchParams.get('token_type'),
      expires_in: urlSearchParams.get('expires_in'),
      scope: urlSearchParams.get('scope'),
      created_at: Date.now(),
    };

    return new TokenData(jsonData);
  }

  static fromStorage() {
    const jsonData = localStorage.getItem('app_auth');
    if (!jsonData) return null;

    return new TokenData(JSON.parse(jsonData));
  }

  toJson() {
    return JSON.stringify({
      access_token: this.accessToken,
      access_token_jwt: this.accessTokenJwt,
      refresh_token: this.refreshToken,
      token_type: this.tokenType,
      expires_in: this.expiresIn,
      scope: this.scope,
      created_at: this.createdAt,
    });
  }

  static getJwtTokenData(jwtToken = null) {
    const token = jwtToken || TokenData.fromStorage().accessTokenJwt;

    if (token) {
      try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''),
        );
        return JSON.parse(jsonPayload);
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  isExpired() {
    const expireAt = new Date(this.createdAt);
    expireAt.setSeconds(expireAt.getSeconds() + this.expiresIn);

    return new Date(Date.now()) > expireAt;
  }

  // check if the token will expire in 3 min
  willExpire(seconds = 180) {
    const expireAt = new Date(this.createdAt);

    expireAt.setSeconds(expireAt.getSeconds() + (this.expiresIn - seconds));
    return new Date(Date.now()) > expireAt;
  }

  getExpireAt() {
    const expireAt = new Date(this.createdAt);

    return expireAt.setSeconds(expireAt.getSeconds() + this.expiresIn);
  }

  persist() {
    localStorage.setItem('app_auth', this.toJson());
  }

  durationInMinutes() {
    return this.expiresIn / 60;
  }

  willExpireInMinutes(seconds = 180) {
    const expirationDate = new Date(this.createdAt);
    expirationDate.setSeconds(expirationDate.getSeconds() + (this.expiresIn - seconds));

    return Math.floor((expirationDate.getTime() - Date.now()) / 1000 / 60);
  }

  permissions(platformUrl = '') {
    const scopes = this.scope.split(' ');
    const permissions = [];
    const cleanedPlatformUrl = platformUrl.replace(/\/+$/, '');

    scopes.forEach((scope) => {
      const scopePermission = scope.split('@')[0];
      const scopePlatform = scope.split('@')[1].replace(/\/+$/, '');

      if (
        scopePlatform !== cleanedPlatformUrl &&
        scopePermission === OauthPermission.StandardUser
      ) {
        permissions.push(OauthPermission.Guest);
      } else if (
        scopePlatform === cleanedPlatformUrl &&
        scopePermission === OauthPermission.StandardUser
      ) {
        permissions.push(OauthPermission.StandardUser);
      } else if (
        scopePlatform === cleanedPlatformUrl &&
        scopePermission === OauthPermission.AdminUser
      ) {
        permissions.push(OauthPermission.AdminUser);
      }
    });

    return permissions.length === 0 ? [OauthPermission.UnAuthorized] : permissions;
  }

  static revoke() {
    localStorage.removeItem('app_auth');
  }
}
