import { Grid, TextField, Fade, Slide, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RadioButtonCheckedOutlinedIcon from "@material-ui/icons/RadioButtonCheckedOutlined";
import { useEffect } from "react";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
    title: {
        color: "grey",
        fontSize: "12px",
    },
    inputLabel: {
        fontSize: "12px",
    },
    inputPlaceholder: {
        color: "#aaa",
        fontSize: "12px",
    },
}));

export default function Radio(props) {
    const classes = useStyles();
    const { _formData, set_formData, index } = props;

    const deleteLine = () => {
        let result = { ..._formData };
        result.actions[0].data.schema = result.actions[0].data.schema.filter(elem => elem.key !== _formData.actions[0].data.schema[index].key);
        set_formData(result);
    };

    useEffect(() => {
        if (_formData.actions[0].data.schema[index].radio === undefined) {
            let result = { ..._formData };
            result.actions[0].data.schema[index].radio = ["", ""];
            set_formData(result);
        }
    }, [_formData]);

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
            <div>
                <Fade in={true} {...{ timeout: 1800 }}>
                    <div>
                        <small style={{ display: "flex", justifyContent: "center", marginBottom: "10px", fontWeight: "bold" }}>Choix</small>

                        <Grid container style={{ marginBottom: "10px" }}>
                            <Grid item lg={11} md={11} sm={11} xs={12}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
                                    <TextField
                                        multiline
                                        rows={2}
                                        InputLabelProps={{
                                            className: classes.title,
                                        }}
                                        InputProps={{
                                            className: classes.inputLabel,
                                        }}
                                        fullWidth
                                        value={_formData.actions[0].data.schema[index].label}
                                        onChange={e => {
                                            let result = { ..._formData };
                                            result.actions[0].data.schema[index].label = e.target.value;
                                            set_formData(result);
                                        }}
                                        variant="outlined"
                                        size="small"
                                        label="Question"
                                        style={{ backgroundColor: "white" }}
                                    />
                                </div>

                                {_formData.actions[0].data.schema[index].radio !== undefined &&
                                    _formData.actions[0].data.schema[index].radio.map((elem, key) => {
                                        return (
                                            <div key={key} style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
                                                <TextField
                                                    multiline
                                                    InputLabelProps={{
                                                        className: classes.title,
                                                    }}
                                                    InputProps={{
                                                        className: classes.inputLabel,
                                                    }}
                                                    fullWidth
                                                    value={_formData.actions[0].data.schema[index].radio[key]}
                                                    onChange={e => {
                                                        let result = { ..._formData };
                                                        result.actions[0].data.schema[index].radio[key] = e.target.value;
                                                        set_formData(result);
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                    label={"Choix " + (key + 1)}
                                                    style={{ backgroundColor: "white" }}
                                                />
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100px" }}>
                                                    <RadioButtonCheckedOutlinedIcon />
                                                </div>
                                                <Button
                                                    size="small"
                                                    color="secondary"
                                                    variant="contained"
                                                    style={{ visibility: key === 0 || key === 1 ? "hidden" : "visible" }}
                                                    onClick={() => {
                                                        if (_formData.actions[0].data.schema[index].radio.length > 2) {
                                                            let result = { ..._formData };
                                                            result.actions[0].data.schema[index].radio = result.actions[0].data.schema[index].radio.filter((el, index) => index !== key);
                                                            set_formData(result);
                                                        } else {
                                                            toast.error("Un champ choix doit avoir au moins deux choix possibles");
                                                        }
                                                    }}>
                                                    -
                                                </Button>
                                            </div>
                                        );
                                    })}

                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
                                    <Button
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            let result = { ..._formData };
                                            result.actions[0].data.schema[index].radio.push("");
                                            set_formData(result);
                                        }}>
                                        <AddIcon />
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <Button onClick={deleteLine} variant="contained" color="secondary" style={{ height: "27px", width: "60%", margin: "15% 0 0 10%" }}>
                                    <b>-</b>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </div>
        </Slide>
    );
}
