export default class RouteHelper {
  static replaceParams(path, paramsValue) {
    let completedPath = path;

    Object.entries(paramsValue).forEach(([key, value]) => {
      completedPath = completedPath.replace(`{${key}}`, value);
    });

    return completedPath;
  }

  static buildQuery(data, path = null) {
    const ret = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const d in data) {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
    }

    if (path) {
      return `${path}?${ret.join('&')}`;
    }

    return ret.join('&');
  }

  static addTrailingSlash(path) {
    if (path[path.length - 1] !== '/') {
      return `${path}`;
    }

    return path;
  }

  static buildRoute(path, params = null, queries = null) {
    let completedPath = path;

    if (params) {
      completedPath = RouteHelper.replaceParams(path, params);
    }

    if (queries) {
      return RouteHelper.addTrailingSlash(RouteHelper.buildQuery(queries, completedPath));
    }

    return RouteHelper.addTrailingSlash(completedPath);
  }
}
