import DocumentContext from "../../../providers/DocumentContext";
import GroupDialog from "./GroupDialog";
import List from "./List";
import VariableDialog from "./VariableDialog";
import TemplateLib from "@common/libs/interview-template-lib";
import { Button, FormControlLabel, Checkbox } from "@material-ui/core/";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import TemplateLibInterview from "@common/libs/interview-template-lib";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  grouplist: {
    display: "block",
    borderTop: "1px solid silver",
    backgroundColor: "#f2f2f2",
  },
  titleGroup: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  editButton: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
    fontWeight: "bold",
  },
  numberTitle: {
    color: "grey",
    fontWeight: "bold",
    marginRight: 10,
    fontSize: 16,
  },
  description: {
    // fontStyle: "italic",
    padding: "8px 15px ",
    backgroundColor: "white",
    border: "1px solid grey",
    borderRadius: 5,
    margin: "15px 10px",
    fontSize: 12,
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();
  const { document, updateDocument } = useContext(DocumentContext);
  const [variableDialog, setvariableDialog] = useState(false);
  const [groupDialog, setgroupDialog] = useState(false);
  const [disableOrderButtons, setdisableOrderButtons] = useState(false);
  const [checked, setChecked] = useState(true);
  const [unusedGlobalVariables, setUnusedGlobalVariables] = useState();
  const [unusedLocalVariables, setUnusedLocalVariables] = useState();
  const [documentFiltered, setDocumentFiltered] = useState(
    document.groupsVariables
  );

  const initVariable = {
    name: "",
    type: "text",
    is_global: true,
    question: "",
    description: "",
    order_in_group: null,
    options: null,
    placeholder: null,
    project: 0,
    field: null,
    group: null,
    id: null,
    is_mail_merging: false,
    role: "",
    database_object_model_fields_mapping: [
      {
        database_object_model_name: "",
        mappingType: "simple",
        data: [
          {
            mapping_complex: "",
            database_object_model_field_name: "",
            variable_field_name: "",
          },
        ],
      },
    ],
  };

  React.useEffect(() => {
    TemplateLib.getUnusedGlobalVariable(document.project).then((result) => {
      setUnusedGlobalVariables(result);
    });
    TemplateLib.getUnusedLocalVariable(document.project).then((result) => {
      setUnusedLocalVariables(result);
    });
  }, []);

  const changeOrder = ({ oldOrder, orderUse }) => {
    setdisableOrderButtons(true);
    const indexVar = document.groupsVariables.findIndex(
      (el) => el.order === oldOrder
    );
    const indexVar_2 = document.groupsVariables.findIndex(
      (el) => el.order === oldOrder + orderUse
    );
    let result = { ...document };
    TemplateLib.updateGroupVariable(result.groupsVariables[indexVar].id, {
      ...result.groupsVariables[indexVar],
      order: oldOrder + orderUse,
    })
      .then(() => {
        TemplateLib.updateGroupVariable(result.groupsVariables[indexVar_2].id, {
          ...result.groupsVariables[indexVar_2],
          order: oldOrder,
        })
          .then(() => {
            result.groupsVariables[indexVar].order = oldOrder + orderUse;
            result.groupsVariables[indexVar_2].order = oldOrder;
            updateDocument(result);
            toast.success("Ordre changé");
          })
          .catch(() => toast.error("Erreur dans le changement d'ordre"));
      })
      .catch(() => toast.error("Erreur dans le changement d'ordre"));
    setdisableOrderButtons(false);
  };

  const removeGroup = (id) => {
    if (window.confirm("Voulez vous vraiment supprimer cette page ?")) {
      TemplateLib.deleteGroupVariable(id).then(() => {
        let result = { ...document };
        document.variables
          .filter((el) => el.group === id)
          .forEach((variable) => {
            const index = result.variables.findIndex(
              (el) => el.id === variable.id
            );
            result.variables[index].group = null;
          });
        updateDocument({
          ...result,
          groupsVariables: document.groupsVariables.filter(
            (el) => el.id !== id
          ),
        });
        toast.success("La page a été supprimé");
      });
    }
  };

  const testSurvey = (newScreen) => {
    if (!newScreen) {
      const jwt_data = JSON.parse(localStorage.getItem("app_auth"));
      const token_data = {
        accessToken: jwt_data.access_token,
        accessTokenJwt: jwt_data.access_token_jwt,
        refreshToken: jwt_data.refresh_token,
        tokenType: jwt_data.token_type,
        expiresIn: jwt_data.expires_in,
        scope: jwt_data.scope,
        createdAt: jwt_data.created_at ? jwt_data.created_at : Date.now(),
      };
      const token = encodeURIComponent(
        btoa(
          JSON.stringify({
            project: document.project,
            task: document.task,
            jwt: token_data,
            newScreen: newScreen,
          })
        )
      );
      const link =
        process.env.COMMON_LAW_QUESTIONNAIRE_URL +
        `documents?newScreen=${newScreen}&token=${token}`;
      window.open(link, "_blank").focus();
    } else {
      const link =
        process.env.COMMON_LAW_APP_URL +
        "clients/" +
        process.env.COMMON_LAW_NOCODE_CLIENT +
        `/projects/${document.project}?taskId=${document.task}`;
      window.open(link, "_blank").focus();
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (checked) {
      setdisableOrderButtons(true);
      const filter = document.groupsVariables.filter((el, key) => {
        return (
          document.variables.filter((elem) => elem.group === el.id).length > 0
        );
      });
      setDocumentFiltered(filter);
    } else {
      setdisableOrderButtons(false);
      setDocumentFiltered(document.groupsVariables);
    }
  };

  return (
    <div className={classes.root}>
      <VariableDialog
        variableDialog={variableDialog}
        setvariableDialog={setvariableDialog}
      />
      <GroupDialog groupDialog={groupDialog} setgroupDialog={setgroupDialog} />
      <div style={{ marginBottom: 15 }}>
        Les pages où aucunes variables ne sont liées n'apparaitront pas dans le
        questionnaire final du document.
      </div>
      <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
        <Button
          style={{ marginRight: 15 }}
          variant="contained"
          color="primary"
          onClick={() =>
            setgroupDialog({
              id: null,
              name: "",
              description: "",
              order: null,
            })
          }
        >
          <AddIcon style={{ marginRight: 10 }} /> Ajouter une page de
          questionnaire
        </Button>
        {/* <Button
          style={{ marginRight: 15 }}
          variant="contained"
          color="primary"
          onClick={() => testSurvey(false)}
        >
          {" "}
          Tester le questionnaire (avec les anciens écrans)
        </Button> */}
        <Button
          style={{ marginRight: 15 }}
          variant="contained"
          color="primary"
          onClick={() => testSurvey(true)}
        >
          {" "}
          Tester le questionnaire
        </Button>
        <FormControlLabel
          control={<Checkbox onChange={handleChange} checked={checked} />}
          label="Afficher les pages vides"
        />
      </div>
      {documentFiltered
        .sort(function (a, b) {
          return a.order - b.order;
        })
        .filter((el) => el.project === document.project)
        .map((el, key) => (
          <div key={key} style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 10,
                marginBottom: 10,
              }}
            >
              <Button
                className="buttonOrder"
                style={{
                  height: 20,
                  boxShadow: "none",
                  border: "1px solid silver",
                  borderRadius: 0,
                }}
                onClick={() =>
                  changeOrder({ oldOrder: el.order, orderUse: -1 })
                }
                disabled={
                  el.order === 1 || el.order === 0 || disableOrderButtons
                }
              >
                <ArrowDropUpIcon />
              </Button>
              <Button
                className="buttonOrder"
                style={{
                  height: 20,
                  boxShadow: "none",
                  border: "1px solid silver",
                  borderRadius: 0,
                }}
                onClick={() => changeOrder({ oldOrder: el.order, orderUse: 1 })}
                disabled={
                  el.order === document.groupsVariables.length ||
                  el.order === 0 ||
                  disableOrderButtons
                }
              >
                <ArrowDropDownIcon />
              </Button>
            </div>

            <Accordion
              style={{
                border: "1px solid grey",
                marginBottom: 10,
                flexGrow: 1,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.titleGroup}
              >
                <div className={classes.title} style={{ zIndex: 999 }}>
                  <span className={classes.numberTitle}>
                    {" "}
                    {el.order > 10 ? el.order : "0" + el.order}.
                  </span>{" "}
                  {el.name}
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.grouplist}>
                {el.description && (
                  <div className={classes.description}>{el.description}</div>
                )}
                <List
                  list={document.variables.filter(
                    (elem) => elem.group === el.id
                  )}
                  variableDialog={variableDialog}
                  setvariableDialog={setvariableDialog}
                  setUnusedGlobalVariables={setUnusedGlobalVariables}
                  setUnusedLocalVariables={setUnusedLocalVariables}
                  isOrderButton={true}
                  isUnsuedGlobalVariable={false}
                  isUnsuedLocalVariable={false}
                  group={el.id}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 10,
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: 15, marginBottom: 10 }}
                    onClick={() => setgroupDialog(el)}
                  >
                    <EditIcon style={{ fontSize: 18, marginRight: 10 }} />{" "}
                    Modifier la page
                  </Button>
                  <Button
                    style={{ marginRight: 15, marginBottom: 10 }}
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      setvariableDialog({
                        ...initVariable,
                        group: el.id,
                      })
                    }
                  >
                    <AddIcon style={{ fontSize: 18, marginRight: 10 }} />
                    Ajouter une variable
                  </Button>

                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ marginRight: 15, marginBottom: 10 }}
                    onClick={() => removeGroup(el.id)}
                  >
                    <RemoveIcon style={{ fontSize: 18, marginRight: 10 }} />
                    Supprimer la page
                  </Button>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}

      <Accordion
        style={{ border: "1px solid grey", marginBottom: 10, flexGrow: 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.titleGroup}
        >
          <div className={classes.title} style={{ zIndex: 999 }}>
            <span className={classes.numberTitle}>
              {document.groupsVariables.length + 1 > 10
                ? document.groupsVariables.length + 1
                : "0" + (document.groupsVariables.length + 1)}
              .
            </span>
            Informations complémentaires
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.grouplist}>
          <div className={classes.description}>
            Les variables ci dessous n'ont été associées à aucune page.
          </div>
          <List
            list={document.variables.filter((elem) => elem.group === null)}
            variableDialog={variableDialog}
            setvariableDialog={setvariableDialog}
            setUnusedGlobalVariables={setUnusedGlobalVariables}
            setUnusedLocalVariables={setUnusedLocalVariables}
            isOrderButton={true}
            isUnsuedGlobalVariable={false}
            isUnsuedLocalVariable={false}
            group={null}
          />
          <div style={{ display: "flex", alignItems: "center", padding: 10 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setvariableDialog(initVariable)}
            >
              <AddIcon style={{ fontSize: 18, marginRight: 10 }} />
              Ajouter une variable
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{ border: "1px solid grey", marginBottom: 10, flexGrow: 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.titleGroup}
        >
          <div className={classes.title} style={{ zIndex: 999 }}>
            Variables globales non utilisées
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.grouplist}>
          <div className={classes.description}>
            Les variables globales ci-dessous ne sont pas utilisées.
          </div>
          {unusedGlobalVariables && (
            <List
              list={unusedGlobalVariables}
              variableDialog={variableDialog}
              setvariableDialog={setvariableDialog}
              setUnusedGlobalVariables={setUnusedGlobalVariables}
              setUnusedLocalVariables={setUnusedLocalVariables}
              isOrderButton={false}
              isUnsuedGlobalVariable={true}
              isUnsuedLocalVariable={false}
              group={null}
            />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{ border: "1px solid grey", marginBottom: 10, flexGrow: 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.titleGroup}
        >
          <div className={classes.title} style={{ zIndex: 999 }}>
            Variables locales non utilisées
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.grouplist}>
          <div className={classes.description}>
            Les variables locales ci-dessous ne sont pas utilisées.
          </div>
          {unusedLocalVariables && (
            <List
              list={unusedLocalVariables}
              variableDialog={variableDialog}
              setvariableDialog={setvariableDialog}
              setUnusedGlobalVariables={setUnusedGlobalVariables}
              setUnusedLocalVariables={setUnusedLocalVariables}
              isOrderButton={false}
              isUnsuedGlobalVariable={false}
              isUnsuedLocalVariable={true}
              group={null}
            />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{ border: "1px solid grey", marginBottom: 10, flexGrow: 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.titleGroup}
        >
          <div className={classes.title} style={{ zIndex: 999 }}>
            Variables avec problèmes de dépendances
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.grouplist}>
          <div className={classes.description}>
            Les variables ci-dessous sont dépendantes de variables qui arrivent
            plus tard dans le questionnaire.
          </div>
          {unusedLocalVariables && (
            <List
              list={unusedLocalVariables}
              variableDialog={variableDialog}
              setvariableDialog={setvariableDialog}
              setUnusedGlobalVariables={setUnusedGlobalVariables}
              setUnusedLocalVariables={setUnusedLocalVariables}
              isOrderButton={false}
              isUnsuedGlobalVariable={false}
              isUnsuedLocalVariable={true}
              group={null}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
