import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import React, { useState } from "react";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        marginTop: 15,
    },
    tab: {
        fontSize: 12,
        padding: "0 10px",
        // height: "35px!important",
        maxHeight: "155px!important",
        minHeight: "35px!important",
        // maxWidth: "100px!important",
        minWidth: "100px!important",
        textTransform: "none!important",
    },
    tabContainer: {
        borderBottom: "1px solid silver",
        // height: "35px!important",
        maxHeight: "155px!important",
        minHeight: "35px!important",
    },
}));

export default function SimpleTabs({ variableInstance, setvariableInstance, setSelect }) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [newColumnTitle, setnewColumnTitle] = useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addColumn = () => {
        if (newColumnTitle) {
            const newCol = {
                title: newColumnTitle,
                body: "",
            };

            let result = { ...variableInstance };
            result.columns.push(newCol);
            setvariableInstance(result);
            setValue(variableInstance.columns.length);
            setnewColumnTitle("");
        }
    };

    return (
        <Paper className={classes.root} elevation={2}>
            <Tabs value={value} onChange={handleChange} className={classes.tabContainer}>
                <Tab
                    key={0}
                    className={classes.tab}
                    label={
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <AddCircleIcon color="primary" style={{ cursor: "pointer" }} />
                        </div>
                    }
                />
                {variableInstance.columns.map((el, key) => (
                    <Tab key={key} className={classes.tab} label={<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{el.title}</div>} />
                ))}
            </Tabs>
            <TabPanel value={value} index={0}>
                <TextField
                    variant="outlined"
                    label="Titre de la colonne"
                    fullWidth
                    size="small"
                    style={{ backgroundColor: "white", margin: "5px 0  98px 0" }}
                    value={newColumnTitle}
                    onChange={e => setnewColumnTitle(e.target.value)}
                    onFocus={e => {
                        setSelect({
                            bloc: "newtitle",
                        });
                    }}
                />
                <Button disabled={newColumnTitle === ""} color="primary" variant="contained" size="small" style={{ marginTop: 10 }} onClick={addColumn}>
                    + Ajouter colonne
                </Button>
            </TabPanel>

            {variableInstance.columns.map((el, key) => (
                <TabPanel value={value} index={key + 1} key={key}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label="Titre de la colonne"
                        style={{ backgroundColor: "white", margin: "0px 0 10px" }}
                        value={variableInstance.columns[key].title}
                        onChange={e => {
                            let result = { ...variableInstance };
                            result.columns[key].title = e.target.value;
                            setvariableInstance(result);
                        }}
                        onFocus={e => {
                            setSelect({
                                bloc: "title",
                            });
                        }}
                        size="small"
                    />
                    <TextField
                        variant="outlined"
                        multiline
                        rows={3}
                        label="Texte affiché"
                        fullWidth
                        style={{ backgroundColor: "white", caretColor: "red" }}
                        value={variableInstance.columns[key].body}
                        onMouseUp={e => {
                            setSelect({
                                cursor: e.target.selectionStart,
                                bloc: "body",
                                index: key,
                            });
                        }}
                        onChange={e => {
                            let result = { ...variableInstance };
                            result.columns[key].body = e.target.value;
                            setvariableInstance(result);
                        }}
                    />
                    <Button color="secondary" variant="contained" size="small" style={{ marginTop: 10 }}>
                        - Supprimer colonne
                    </Button>
                </TabPanel>
            ))}
        </Paper>
    );
}
