import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
} from "@material-ui/core"
import FiltersContext from "~/providers/FiltersContext"
import TemplateLib from "@common/libs/interview-template-lib"
import TemplateLibInterviews from "~/common/libs/interview-template-lib"
import React, { useContext } from "react"

export default function Dependencies({
  _formData,
  set_formData,
  activeEditor,
}) {
  const { filtersData } = useContext(FiltersContext)
  const [variables, setVariables] = React.useState([])
  const [groupsVariables, setGroupsVariables] = React.useState([])

  React.useEffect(() => {
    TemplateLib.getVariablesFromProject(
      activeEditor.root[0].id,
      filtersData?.site ? filtersData.site.domain : ""
    ).then((varsProject) => {
      setVariables(
        varsProject.filter(
          (elem) =>
            elem.type === "bool" ||
            elem.type === "choice" ||
            elem.type === "multi"
        )
      )
    })
    TemplateLib.getGroupVariablesFromProject(
      activeEditor.root[0].id,
      filtersData?.site ? filtersData.site.domain : ""
    ).then((groupsVariables) => {
      setGroupsVariables(groupsVariables)
    })
  }, [])

  const isBool = (questionId) => {
    const dependant_question = variables.filter(
      (elem) => elem.id == questionId
    )[0]
    if (dependant_question?.type === "bool") {
      return true
    } else {
      return false
    }
  }

  const isSingleChoice = (questionId) => {
    const dependant_question = variables.filter(
      (elem) => elem.id == questionId
    )[0]
    if (dependant_question?.type === "choice") {
      return true
    } else {
      return false
    }
  }

  const isMultiChoice = (questionId) => {
    const dependant_question = variables.filter(
      (elem) => elem.id == questionId
    )[0]
    if (dependant_question?.type === "multi") {
      return true
    } else {
      return false
    }
  }

  const boolDependantValueSelectItems = [
    {
      key: 1,
      value: "true",
      text: "Oui",
    },
    {
      key: 2,
      value: "false",
      text: "Non",
    },
  ]

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          style={{ marginRight: "5px" }}
        >
          <InputLabel>Question dépendante</InputLabel>
          <Select
            label="Question dépendante"
            value={_formData.dependant_variable}
            onChange={(e) => {
              set_formData({
                ..._formData,
                dependant_variable: e.target.value,
              })
            }}
          >
            <MenuItem key={0} value={null}>
              Aucune
            </MenuItem>
            {groupsVariables.map((el, key) => [
              variables.filter((elem) => elem.group === el.id).length != 0 && (
                <div>
                  <ListSubheader>
                    {el.order > 10 ? el.order : "0" + el.order}. {el.name}
                  </ListSubheader>
                </div>
              ),
              variables
                .filter((elem) => elem.group === el.id)
                .sort((a, b) => {
                  return a.id - b.id
                })
                .map((el, key) => (
                  <MenuItem key={el.id} value={el.id}>
                    #{el.id} - {el.name}
                  </MenuItem>
                )),
            ])}
            <ListSubheader>Informations complémentaires</ListSubheader>
            {variables
              .filter((elem) => elem.group === null)
              .sort((a, b) => {
                return a.id - b.id
              })
              .map((el, key) => (
                <MenuItem key={el.id} value={el.id}>
                  #{el.id} - {el.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          style={{ marginRight: "5px" }}
        >
          <InputLabel>Valeur de la question dépendante</InputLabel>
          <Select
            label="Valeur de la question dépendante"
            value={_formData.dependant_value}
            onChange={(e) => {
              set_formData({
                ..._formData,
                dependant_value: e.target.value,
              })
            }}
          >
            {_formData && _formData.dependant_variable == 0 && (
              <MenuItem key={0} value={null}>
                -
              </MenuItem>
            )}
            {_formData &&
              _formData.dependant_variable != 0 &&
              isBool(_formData.dependant_variable) &&
              boolDependantValueSelectItems.map((el, key) => (
                <MenuItem key={el.key} value={el.value}>
                  {el.text}
                </MenuItem>
              ))}
            {_formData &&
              _formData.dependant_variable != 0 &&
              isSingleChoice(_formData.dependant_variable) &&
              variables
                .filter((elem) => elem.id == _formData.dependant_variable)[0]
                .options.map((el, key) => (
                  <MenuItem key={key + 1} value={el}>
                    {el}
                  </MenuItem>
                ))}
            {_formData &&
              _formData.dependant_variable != 0 &&
              isMultiChoice(_formData.dependant_variable) &&
              variables
                .filter((elem) => elem.id == _formData.dependant_variable)[0]
                .options.map((el, key) => (
                  <MenuItem key={key + 1} value={el}>
                    {el}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          style={{ marginRight: "5px" }}
        >
          <InputLabel>Question dépendante 2 </InputLabel>
          <Select
            label="Question dépendante"
            value={_formData.dependant_variable_2}
            onChange={(e) => {
              set_formData({
                ..._formData,
                dependant_variable_2: e.target.value,
              })
            }}
          >
            <MenuItem key={0} value={null}>
              Aucune
            </MenuItem>
            {groupsVariables.map((el, key) => [
              variables.filter((elem) => elem.group === el.id).length != 0 && (
                <div>
                  <ListSubheader>
                    {el.order > 10 ? el.order : "0" + el.order}. {el.name}
                  </ListSubheader>
                </div>
              ),
              variables
                .filter((elem) => elem.group === el.id)
                .sort((a, b) => {
                  return a.id - b.id
                })
                .map((el, key) => (
                  <MenuItem key={el.id} value={el.id}>
                    #{el.id} - {el.name}
                  </MenuItem>
                )),
            ])}
            <ListSubheader>Informations complémentaires</ListSubheader>
            {variables
              .filter((elem) => elem.group === null)
              .sort((a, b) => {
                return a.id - b.id
              })
              .map((el, key) => (
                <MenuItem key={el.id} value={el.id}>
                  #{el.id} - {el.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          style={{ marginRight: "5px" }}
        >
          <InputLabel>Valeur de la question dépendante 2</InputLabel>
          <Select
            label="Valeur de la question dépendante 2"
            value={_formData.dependant_value_2}
            onChange={(e) => {
              set_formData({
                ..._formData,
                dependant_value_2: e.target.value,
              })
            }}
          >
            {_formData && _formData.dependant_variable_2 == 0 && (
              <MenuItem key={0} value={null}>
                -
              </MenuItem>
            )}
            {_formData &&
              _formData.dependant_variable_2 != 0 &&
              isBool(_formData.dependant_variable_2) &&
              boolDependantValueSelectItems.map((el, key) => (
                <MenuItem key={el.key} value={el.value}>
                  {el.text}
                </MenuItem>
              ))}
            {_formData &&
              _formData.dependant_variable_2 != 0 &&
              isSingleChoice(_formData.dependant_variable_2) &&
              variables
                .filter((elem) => elem.id == _formData.dependant_variable_2)[0]
                .options.map((el, key) => (
                  <MenuItem key={key + 1} value={el}>
                    {el}
                  </MenuItem>
                ))}
            {_formData &&
              _formData.dependant_variable_2 != 0 &&
              isMultiChoice(_formData.dependant_variable_2) &&
              variables
                .filter((elem) => elem.id == _formData.dependant_variable_2)[0]
                .options.map((el, key) => (
                  <MenuItem key={key + 1} value={el}>
                    {el}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          style={{ marginRight: "5px" }}
        >
          <InputLabel>Question dépendante 3</InputLabel>
          <Select
            label="Question dépendante"
            value={_formData.dependant_variable_3}
            onChange={(e) => {
              set_formData({
                ..._formData,
                dependant_variable_3: e.target.value,
              })
            }}
          >
            <MenuItem key={0} value={null}>
              Aucune
            </MenuItem>
            {groupsVariables.map((el, key) => [
              variables.filter((elem) => elem.group === el.id).length != 0 && (
                <div>
                  <ListSubheader>
                    {el.order > 10 ? el.order : "0" + el.order}. {el.name}
                  </ListSubheader>
                </div>
              ),
              variables
                .filter((elem) => elem.group === el.id)
                .sort((a, b) => {
                  return a.id - b.id
                })
                .map((el, key) => (
                  <MenuItem key={el.id} value={el.id}>
                    #{el.id} - {el.name}
                  </MenuItem>
                )),
            ])}
            <ListSubheader>Informations complémentaires</ListSubheader>
            {variables
              .filter((elem) => elem.group === null)
              .sort((a, b) => {
                return a.id - b.id
              })
              .map((el, key) => (
                <MenuItem key={el.id} value={el.id}>
                  #{el.id} - {el.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          style={{ marginRight: "5px" }}
        >
          <InputLabel>Valeur de la question dépendante 3</InputLabel>
          <Select
            label="Valeur de la question dépendante 3"
            value={_formData.dependant_value_3}
            onChange={(e) => {
              set_formData({
                ..._formData,
                dependant_value_3: e.target.value,
              })
            }}
          >
            {_formData && _formData.dependant_variable_3 == 0 && (
              <MenuItem key={0} value={null}>
                -
              </MenuItem>
            )}
            {_formData &&
              _formData.dependant_variable_3 != 0 &&
              isBool(_formData.dependant_variable_3) &&
              boolDependantValueSelectItems.map((el, key) => (
                <MenuItem key={el.key} value={el.value}>
                  {el.text}
                </MenuItem>
              ))}
            {_formData &&
              _formData.dependant_variable_3 != 0 &&
              isSingleChoice(_formData.dependant_variable_3) &&
              variables
                .filter((elem) => elem.id == _formData.dependant_variable_3)[0]
                .options.map((el, key) => (
                  <MenuItem key={key + 1} value={el}>
                    {el}
                  </MenuItem>
                ))}
            {_formData &&
              _formData.dependant_variable_3 != 0 &&
              isMultiChoice(_formData.dependant_variable_3) &&
              variables
                .filter((elem) => elem.id == _formData.dependant_variable_3)[0]
                .options.map((el, key) => (
                  <MenuItem key={key + 1} value={el}>
                    {el}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}
