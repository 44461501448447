import {
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
} from "@material-ui/core";
import React from "react";
import moment from "moment";
import TemplateLib from "@common/libs/template-lib";
import { useState, useContext } from "react";
import messageError from "../Functions/messageError";
import ActiveEditorContext from "../../../../../providers/ActiveEditorContext";
import FiltersContext from "~/providers/FiltersContext";
import { toast } from "react-toastify";

export default function Preload({ _formData, set_formData }) {
  const { activeEditor, updateActiveEditor } = useContext(ActiveEditorContext);
  React.useEffect(() => {}, []);

  const [_loading, set_loading] = useState(false);
  const { filtersData, updateFiltersData } = useContext(FiltersContext);

  const pausePreloading = () => {
    console.log("pause preload");
    console.log(_formData);
    console.log(_formData.preloading_status);

    set_loading(true);
    TemplateLib.editProject(
      activeEditor.data.id,
      { ..._formData, preloading_status: "PS" },
      filtersData.site.domain
    )
      .then((res) => {
        console.log("updated project");
        set_loading(false);
        set_formData({ ..._formData, preloading_status: "PS" });
      })
      .catch((err) => {
        messageError(err, "durant la modifcation du projet");
        set_loading(false);
      });
  };

  const activatePreloading = () => {
    console.log("pause preload");
    console.log(_formData);
    console.log(_formData.preloading_status);

    set_loading(true);
    TemplateLib.editProject(
      activeEditor.data.id,
      { ..._formData, preloading_status: "AC" },
      filtersData.site.domain
    )
      .then((res) => {
        console.log("updated project");
        set_loading(false);
        set_formData({ ..._formData, preloading_status: "AC" });
      })
      .catch((err) => {
        set_loading(false);
        messageError(err, "durant la modifcation du projet");
      });
  };

  const refreshPreloadedProjects = () => {
    console.log("refreshPreloadedProjects");

    set_loading(true);
    TemplateLib.refreshPreloadedProjects(activeEditor.data.id)
      .then((res) => {
        set_loading(false);
        toast.success("Mise à jour des projets pré-chargés en cours.");
      })
      .catch((err) => {
        console.log(err);
        set_loading(false);
        messageError(
          undefined,
          "durant la mise à jour des projets pré-chargés"
        );
      });
  };

  const formatDateTime = (dateTime) => {
    const date = Date.parse(dateTime);
    let md = moment(date);
    let dateStr = md.format("DD/MM/YYYY HH:mm:ss");
    return dateStr;
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <span
          style={{
            fontWeight: "700",
          }}
        >
          Etat du pré-chargement :{" "}
        </span>
        <span>
          &nbsp; {_formData.preloading_status == "PS" && <span>En pause</span>}
          {_formData.preloading_status == "IP" && (
            <span>En cours de création</span>
          )}
          {_formData.preloading_status == "AC" && <span>Actif</span>}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <span
          style={{
            fontWeight: "700",
          }}
        >
          Date de dernière mise à jour du projet :{" "}
        </span>
        <span>&nbsp;{formatDateTime(_formData.last_modification)}</span>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        <span
          style={{
            fontWeight: "700",
          }}
        >
          Date de dernière mise à jour des projets pré-chargés :{" "}
        </span>
        <span>
          &nbsp;{formatDateTime(_formData.last_preloading_from_projects)}
        </span>
      </div>

      <div
        style={{
          marginBottom: "20px",
        }}
      >
        {" "}
        &nbsp;
      </div>
      {_formData.preloading_status == "AC" && (
        <Button
          variant="contained"
          color="primary"
          onClick={pausePreloading}
          style={{
            marginRight: "30px",
            marginBottom: "30px",
          }}
        >
          Mettre en pause le pré-chargement
        </Button>
      )}

      {_formData.preloading_status == "PS" && (
        <Button
          variant="contained"
          color="primary"
          onClick={activatePreloading}
          style={{
            marginRight: "30px",
            marginBottom: "30px",
          }}
        >
          Activer le pré-chargement
        </Button>
      )}

      {_formData.preloading_status == "AC" && (
        <Button
          variant="contained"
          color="primary"
          onClick={refreshPreloadedProjects}
          style={{
            marginRight: "30px",
            marginBottom: "30px",
          }}
        >
          Rafraîchir les projets pré-chargés
        </Button>
      )}
    </>
  );
}
