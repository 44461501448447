import { FormControl, MenuItem, Select, InputLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { physicalPerson } from "./DatabaseObjectFields/PhysicalPerson";
import { sas } from "./DatabaseObjectFields/SAS";
import { moralPerson } from "./DatabaseObjectFields/MoralPerson";

export default ({
  variableDialog,
  setvariableDialog,
  elementKey,
  tabValue,
}) => {
  const [mappingModels, setMappingModels] = useState({});
  const [loading, setLoading] = useState({});

  useEffect(() => {
    setLoading(true);
    const obj = mappingModels;
    obj[physicalPerson.name] = physicalPerson.fields;
    obj[sas.name] = sas.fields;
    obj[moralPerson.name] = moralPerson.fields;
    setMappingModels(obj);
    setLoading(false);
  }, []);

  if (variableDialog.type === "multiarray") {
    if (
      variableDialog.database_object_model_fields_mapping[+tabValue - 1].data[
        elementKey
      ] === undefined
    ) {
      variableDialog.database_object_model_fields_mapping[
        +tabValue - 1
      ].data.push({
        database_object_model_field_name: "",
        variable_field_name: "",
      });
    }
  }
  if (
    variableDialog.type === "unisign" ||
    variableDialog.type === "multisign"
  ) {
    if (
      variableDialog.database_object_model_fields_mapping &&
      variableDialog.database_object_model_fields_mapping[0] &&
      variableDialog.database_object_model_fields_mapping[0].data &&
      variableDialog.database_object_model_fields_mapping[0]?.data[1] ===
        undefined
    ) {
      variableDialog.database_object_model_fields_mapping[0].data.push({
        database_object_model_field_name: "",
        variable_field_name: "",
      });
    }
    if (
      variableDialog.database_object_model_fields_mapping &&
      variableDialog.database_object_model_fields_mapping[0] &&
      variableDialog.database_object_model_fields_mapping[0].data &&
      variableDialog.database_object_model_fields_mapping[0]?.data[2] ===
        undefined
    ) {
      variableDialog.database_object_model_fields_mapping[0].data.push({
        database_object_model_field_name: "",
        variable_field_name: "",
      });
    }
  }

  if (
    variableDialog.database_object_model_fields_mapping &&
    variableDialog.database_object_model_fields_mapping[0].data[elementKey] ===
      undefined
  ) {
    variableDialog.database_object_model_fields_mapping[0].data.push({
      database_object_model_field_name: "",
      variable_field_name: "",
    });
  }

  function handleChange(e) {
    let result = { ...variableDialog };
    if (!Array.isArray(result.database_object_model_fields_mapping)) {
      result.database_object_model_fields_mapping = [];
    }
    if (result.type === "unisign" || result.type === "multisign") {
      if (elementKey === 0) {
        result.database_object_model_fields_mapping[0].data[0] = {
          database_object_model_field_name: e.target.value,
          variable_field_name: "email",
        };
      }
      if (elementKey === 1) {
        result.database_object_model_fields_mapping[0].data[1] = {
          database_object_model_field_name: e.target.value,
          variable_field_name: "email",
        };
      }
      if (elementKey === 2) {
        result.database_object_model_fields_mapping[0].data[2] = {
          database_object_model_field_name: e.target.value,
          variable_field_name: "email",
        };
      }
    } else if (result.type === "multiarray") {
      result.database_object_model_fields_mapping[tabValue - 1].data[
        elementKey
      ] = {
        database_object_model_field_name: e.target.value,
        variable_field_name:
          variableDialog.options[tabValue - 1][elementKey].label,
      };
    } else if (result.type === "object") {
      result.database_object_model_fields_mapping[tabValue - 1].data[
        elementKey
      ] = {
        database_object_model_field_name: e.target.value,
        variable_field_name: variableDialog.options[elementKey].label,
      };
    } else {
      result.database_object_model_fields_mapping[tabValue - 1].data[
        elementKey
      ] = {
        database_object_model_field_name: e.target.value,
        variable_field_name: "",
      };
    }
    setvariableDialog(result);
  }

  const getFields = () => {
    const model =
      variableDialog.database_object_model_fields_mapping[tabValue - 1]
        .database_object_model_name;
    return mappingModels[model]?.map((el) => {
      return <MenuItem value={el}>{el}</MenuItem>;
    });
  };
  return (
    <FormControl
      size="small"
      style={{ width: 200, backgroundColor: "white" }}
      variant="outlined"
    >
      <InputLabel>Modèle d'objet</InputLabel>
      <Select
        label="Modèle d'objet"
        value={
          variableDialog.database_object_model_fields_mapping[+tabValue - 1]
            .data[elementKey].database_object_model_field_name
        }
        onChange={(e) => {
          handleChange(e);
        }}
      >
        <MenuItem value="">
          <em>Aucun</em>
        </MenuItem>
        {!loading && getFields()}
      </Select>
    </FormControl>
  );
};
