import LineDocument from "./LineDocument";
import MenuActions from "./MenuActions";
import ModalAction from "./ModalAction";
import TemplateLib from "@common/libs/interview-template-lib";
import { CircularProgress, TextField } from "@material-ui/core/";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useContext } from "react";
import FiltersContext from "~/providers/FiltersContext";
import TemplateTaskLib from "@common/libs/template-lib";
const useStyles = makeStyles(({ palette, spacing }) => ({
  table: {
    padding: 0,
    margin: "15px 0px",
    backgroundColor: "white",
  },
  tableHead: {
    fontSize: 14,
    padding: "10px 15px",
    color: "black",
    borderBottom: " 0.2px solid silver",
  },
}));

export default function DocumentList() {
  const classes = useStyles();
  const { filtersData } = useContext(FiltersContext);
  const [searchInput, setsearchInput] = useState("");
  const [documentsList, setdocumentsList] = useState();
  const [documentIniitList, setdocumentIniitList] = useState();
  const [modalAction, setmodalAction] = useState();
  const [openMenu, setOpenMenu] = React.useState(null);

  const [_reload, set_reload] = useState(false);

  React.useEffect(() => {
    if (filtersData.site?.domain) {
      setdocumentsList();
      TemplateLib.getAllDocumentsOptimized(filtersData.site?.domain)
        .then((docs) => {
          setdocumentIniitList(docs);

          // Promise.all(docs.map(el => (el.project === null ? null : TemplateTaskLib.getProject(el.project)))).then(project_name => {
          //     Promise.all(docs.map(el => (el.task === null ? null : TemplateTaskLib.getSpecificTask(el.task)))).then(task_name => {
          //         setdocumentIniitList(docs.map((el, key) => ({ ...el, task_name: task_name[key]?.name, project_name: project_name[key]?.name })));
          //     });
          // });
        })
        .catch(() => {
          setdocumentIniitList([]);
        });
    } else {
      setdocumentsList(null);
    }
  }, [filtersData.site, _reload]);

  React.useEffect(() => {
    if (documentIniitList)
      setdocumentsList(
        documentIniitList
          .filter(
            (el) =>
              el.name
                .toLowerCase()
                .trim()
                .indexOf(searchInput.toLowerCase().trim()) > -1 ||
              el?.task_name
                ?.toLowerCase()
                .trim()
                .indexOf(searchInput.toLowerCase().trim()) > -1 ||
              el?.project_name
                ?.toLowerCase()
                .trim()
                .indexOf(searchInput.toLowerCase().trim()) > -1 ||
              el?.project
                ?.toString()
                .toLowerCase()
                .trim()
                .indexOf(searchInput.toLowerCase().trim()) > -1 ||
              el?.task
                ?.toString()
                .toLowerCase()
                .trim()
                .indexOf(searchInput.toLowerCase().trim()) > -1 ||
              el?.id
                .toString()
                .toLowerCase()
                .trim()
                .indexOf(searchInput.toLowerCase().trim()) > -1
          )
          .sort((a, b) => {
            return a.id - b.id;
          })
          .sort((a, b) => {
            return a.project - b.project;
          })
      );
  }, [searchInput, documentIniitList]);

  return (
    <div>
      <ModalAction
        modalAction={modalAction}
        setmodalAction={setmodalAction}
        setOpenMenu={setOpenMenu}
        set_reload={set_reload}
      />
      <MenuActions
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        setmodalAction={setmodalAction}
      />

      <div
        style={{ display: "flex", margin: "50px 0 20px 0", flexWrap: "wrap" }}
      >
        <TextField
          variant="outlined"
          label="Rechercher par : Titre / Projet / Tâche"
          value={searchInput}
          onChange={(e) => setsearchInput(e.target.value)}
          fullWidth
          style={{
            maxWidth: 400,
            marginRight: 15,
            backgroundColor: "white",
            marginBottom: 10,
          }}
          size="small"
        />
        {/* <Button color="primary" variant="contained" onClick={() => history.push("/documents/new")} style={{ paddingLeft: 25, paddingRight: 25, height: "100%" }}>
                    <AddIcon style={{ marginRight: 10 }} /> Ajouter document
                </Button> */}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#888",
          }}
        >
          Veuillez passer par les modèles de projets pour ajouter un nouveau
          document
        </div>
      </div>
      <TableContainer className={classes.table}>
        <Table stickyHeader style={{ padding: 0, border: "1px solid silver" }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} align="center">
                Nom du document
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Statut
              </TableCell>
              {/* <TableCell className={classes.tableHead} align="center">
                                Nombre variables
                            </TableCell> */}
              <TableCell className={classes.tableHead} align="center">
                Projet
              </TableCell>
              <TableCell className={classes.tableHead} align="center">
                Tâche
              </TableCell>
              <TableCell
                className={classes.tableHead}
                align="center"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ maxHeight: 300 }}>
            {Boolean(documentsList === undefined) && (
              <TableRow>
                <TableCell
                  className={classes.tableHead}
                  align="center"
                  colSpan="5"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 80,
                      fontWeight: "bold",
                    }}
                  >
                    <CircularProgress size={28} />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {Boolean(documentsList === null) && (
              <TableRow>
                <TableCell
                  className={classes.tableHead}
                  align="center"
                  colSpan="5"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 80,
                      fontWeight: "bold",
                    }}
                  >
                    Veuillez sélectionner un Périmètre
                  </div>
                </TableCell>
              </TableRow>
            )}

            {Boolean(documentsList?.length === 0) && (
              <TableRow>
                <TableCell
                  className={classes.tableHead}
                  align="center"
                  colSpan="5"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 80,
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Aucun document
                  </div>
                </TableCell>
              </TableRow>
            )}
            {Boolean(documentsList?.length) &&
              documentsList.map((el, key) => (
                <LineDocument
                  setOpenMenu={setOpenMenu}
                  setmodalAction={setmodalAction}
                  data={el}
                  key={key}
                  documentIniitList={documentIniitList}
                  setdocumentIniitList={setdocumentIniitList}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
