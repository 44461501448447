import { Grid, Button, CircularProgress } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";

const GreenButton = withStyles(theme => ({
    root: {
        fontWeight: "500",
        minWidth: 200,
        fontFamily: "var(--fontFamily_1)",
        color: theme.palette.getContrastText(green[800]),
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[600],
        },
    },
    outlined: {
        fontFamily: "var(--fontFamily_1)",
        color: green[500],
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: green[600],
            color: "white",
        },
    },
}))(Button);

export default function Add({ _function, loading }) {
    return (
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <GreenButton variant="contained" disabled={loading} onClick={_function}>
                {loading ? <CircularProgress size={18} /> : "+ Ajouter"}
            </GreenButton>
        </Grid>
    );
}
