import StoreDataContext from "./providers/StoreDataContext";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import _ from "lodash";
import React from "react";

export const base_palette = {
    background: {
        side: "white",
        default: "#f7fafc",
        brand: "#f7fafc",
    },
    border: "#e0dede",
    grey: { light: "#a09e9e" },
    body: { main: "#494852" },
    primary: { light: "#e1e9ff", main: "#5469d4", dark: "#4759b6" },
    secondary: { light: "#f496d1", main: "#f93b3b" },
    // info: { main: "#a0a0a0" },
    success: { main: "#47ba72" },
    deadline: ["#f93b3b", "#ff910a", "#f9d71c", "#a0a0a0"],
};

export const colors = {
    group: "#ff5630",
    enterprise: "#0052cc",
    establishment: "#36b37e",
};

const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
}

export const getMuiTheme = (theme, storeDataTheme) => {
    const palette = _.merge(
        _.cloneDeep({
            ...base_palette,
            fontFamily: storeDataTheme ? capitalize(storeDataTheme.font) + ", sans-serif" : "Lato, sans-serif",
            background: {
                ...base_palette.background,
                brand: storeDataTheme ? storeDataTheme.brandColor2 : "#f7fafc",
            },
            primary: {
                ...base_palette.primary,
                light: storeDataTheme ? shadeColor(storeDataTheme.brandColor1, 10) : "#3E85C3",
                main: storeDataTheme ? storeDataTheme.brandColor1 : "#3E85C3",
                dark: storeDataTheme ? shadeColor(storeDataTheme.brandColor1, -10) : "#3E85C3",
            },
            cta: {
                dark: "#43AF6C",
                main: "#72C391",
                light: "#A1D7B5",
                hyperlight: "#D0EBDA",
            },
            blue: {
                light: "#4099FF",
                main: "#076BDF",
                dark: "#164B77",
            },
            grey: {
                light: "#e0e0e0",
                main: "#bdbdbd",
                dark: "#9e9e9e",
            },

            // error: {
            //     dark: "#FF918D",
            //     main: "#FFACA9",
            //     light: "#FFC8C6",
            //     hyperlight: "#FFE3E3",
            // },
            success: {
                dark: "#AAE69C",
                main: "#BFECB5",
                light: "#D4F3CD",
                hyperlight: "#EAF9E6",
            },
            warning: {
                main: "#FFC01A",
            },
            current: {
                dark: "#A6D2FF",
                main: "#BCDDFF",
                light: "#D3E8FF",
                hyperlight: "#EAFAF7",
            },
        }),
        theme?.palette
    );
    const _theme = createTheme({ palette });

    const { spacing } = _theme;
    return createTheme({
        ..._theme,
        typography: {
            fontFamily: ["Lato", "sans-serif"],
        },
        overrides: {
            MuiCssBaseline: {
                "@global": {
                    body: {
                        color: palette.body.main,
                        fontSize: 15,
                        lineHeight: "1.6em",
                        backgroundColor: palette.background.default,
                        fontFamily: palette.fontFamily,
                    },
                    a: {
                        color: palette.primary.main,
                        textDecoration: "none",
                        outline: 0,
                        "&:hover": {},
                        fontFamily: palette.fontFamily,
                    },
                    h1: {
                        fontSize: 28,
                        margin: 0,
                        fontFamily: palette.fontFamily,
                    },
                    h2: {
                        fontSize: 22,
                        fontWeight: 700,
                        margin: "0 0 0px 0",
                        lineHeight: "1",
                    },
                    h6: {
                        fontSize: 15,
                        margin: "10px 0 5px 0",
                    },
                    ".description": {
                        color: palette.grey.dark,
                        fontFamily: palette.fontFamily,
                    },
                    span: {
                        fontFamily: palette.fontFamily,
                    },
                    b: {
                        fontFamily: palette.fontFamily,
                    },
                    button: {
                        fontFamily: palette.fontFamily,
                    },
                    input: {
                        height: 38,
                        padding: "8px 12px",
                        // border: "1px solid #cccccc",
                        flex: "0 auto",
                        borderRadius: "6px",
                        fontSize: 16,
                        fontFamily: palette.fontFamily,
                        "&:focus": {
                            // border: `1px solid ${palette.primary.main}`,
                            outline: "none",
                        },
                        "&::placeholder": {
                            color: "#ccc",
                        },
                        "&[type=radio]": {
                            height: "unset",
                        },
                    },
                    textarea: {
                        color: "#999",
                        borderRadius: 6,
                        padding: "8px 12px",
                        border: "1px solid #cccccc",
                        "&:focus": {
                            outline: "none",
                        },
                    },
                    ".field-string": {
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 2,
                        "& .control-label": {
                            justifyContent: "flex-start",
                        },
                    },
                    ".align-vertical": {
                        flexDirection: "column",
                        "& .control-label .form-control": {
                            justifyContent: "flex-start",
                        },
                        "& .control-label": {
                            marginBottom: 4,
                        },
                    },
                    ".has-error .form-control": {
                        border: `1px solid ${palette.secondary.main} !important`,
                    },
                    ".StripeElement": {
                        height: 38,
                        padding: "10px 12px",
                        border: "1px solid #cccccc",
                        flex: "0 auto",
                        borderRadius: "6px",
                        fontSize: 16,
                        fontFamily: palette.fontFamily,
                        width: "300px",
                        textAlign: "center",
                    },
                    ".StripeElement--invalid": {
                        color: `1px solid ${palette.secondary.main} !important`,
                        border: `1px solid ${palette.secondary.main} !important`,
                    },
                    ".StripeElement--focus": {
                        border: `1px solid ${palette.primary.main}`,
                        outline: "none",
                    },
                    ".css-1uccc91-singleValue": {
                        width: "calc(100% - 16px)",
                    },
                    ".icon_chevron": {
                        transition: "0.2s",
                        "&:hover": {
                            backgroundColor: "silver",
                        },
                    },
                    ".ql-editor": {
                        minHeight: "150px",
                    },
                    ".MuiInputLabel-outlined.MuiInputLabel-shrink": {
                        backgroundColor: "rgba(255,255,255,0.8)",
                        borderRadius: "2px",
                    },
                    ".e-de-tool-ctnr-properties-pane": {
                        // height: "200px!important",
                    },
                    ".e-spinner-pane.e-spin-overlay": {
                        // height: "500px!important",
                    },
                    ".e-documenteditorcontainer": {
                        height: "650px!important",
                    },
                },
            },
            MuiPaper: {
                root: {
                    color: "inherit",
                    backgroundColor: "white",
                    border: `1px solid ${palette.border}`,
                    boxShadow: "1px 1px 8px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1)",
                },
                rounded: {
                    borderRadius: "6px",
                },
            },
            MuiButton: {
                root: {
                    textTransform: "none",
                    color: "#222222",
                    fontSize: "15px",
                    lineHeight: "20px",
                    borderRadius: "6px",
                    boxShadow: "rgba(0, 0, 0, 0.1) 4px 4px 8px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px",
                    padding: "9px 15px",
                    minWidth: "unset",
                },
            },
            MuiDialog: {
                paper: {
                    maxWidth: "100vw!important",
                    maxHeight: "100vh!important",
                    // height: "100vh!important",
                    // width: "100vw!important",
                },
            },
            MuiDialogContent: {
                root: {
                    padding: `${spacing(4)}px !important`,
                    "&:first-child": {
                        padding: "unset",
                        paddingTop: "unset",
                    },
                },
            },
            MuiTableRow: {
                root: {
                    width: "600px",
                    // display: "none"
                },
                head: {
                    display: "table-row",
                    borderBottom: `1px solid ${palette.border}`,
                },
                footer: { display: "inherit" },
            },
            MuiTableCell: {
                root: {
                    padding: 15,
                    paddingLeft: 20,
                },
                head: {
                    border: "none",
                    fontSize: 15,
                    fontWeight: 700,
                    color: "#222222",
                    backgroundColor: "transparent !important",
                },
                paddingCheckbox: {
                    "&:last-child": {
                        paddingLeft: 0,
                        paddingRight: 100,
                    },
                },
            },
            MuiBreadcrumbs: {
                li: {
                    color: "#646464",
                    fontSize: "15px",
                    fontWeight: "700 !important",
                },
            },
        },
    });
};

export function ThemeProvider(props) {
    const { theme, children } = props;
    const { storeData } = React.useContext(StoreDataContext);
    return <MuiThemeProvider theme={getMuiTheme(theme, storeData?.theme ? storeData.theme : null)}>{children}</MuiThemeProvider>;
}
const Providers = ({ theme, children }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
    </ThemeProvider>
);

export default Providers;

export const getSelectStyles = valid => ({
    control: (_styles, state) => ({
        backgroundColor: state.isDisabled ? "rgba(239, 239, 239, 0.3)" : base_palette.background.side,
        display: "flex",
        minHeight: 38,
        "& input": {
            height: "unset",
        },
        border: `1px solid ${getBorderSelect(state, valid)}`,
        borderRadius: "6px",
    }),
    valueContainer: styles => ({ ...styles, fontSize: 16 }),
    placeholder: styles => ({ ...styles, color: "#ccc" }),
    multiValue: styles => ({ ...styles, height: 28 }),
    menuList: styles => ({ ...styles }),
});

const getBorderSelect = (state, valid) => {
    if (state.isFocused) return base_palette.primary.main;
    else if (valid === true) return "red";
    else return "#cccccc";
};
