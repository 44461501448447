import Title from "../components/login/title";
import AccountLib from "@common/libs/account-lib";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import TokenData from "~/common/models/token-data";
import ClientDataContext from "~/providers/ClientDataContext";

const useStyles = makeStyles(({ palette, spacing }) => ({
    root: {
        height: "100vh",
        overflow: "hidden",
    },
    image: {
        backgroundImage: "url(https://cloud.magic-lemp.com/apps/files_sharing/publicpreview/xcAczLzMzrXDpwA?x=1913&y=505&a=true&file=annie-spratt-McM5BW4jQHc-unsplash.jpg&scalingup=0)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    paper: {
        margin: spacing(0, 4),
        display: "flex",
        // flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
    },
    avatar: {
        margin: spacing(1),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: spacing(1),
        backgroundColor: palette.primary.main,
    },
    submit: {
        margin: spacing(3, 0),
    },
    gitlab: {
        height: "70px",
        fontSize: "16px",
        marginTop: spacing(4),
        backgroundColor: "#380d75",
        "&:hover": {
            backgroundColor: "#280d65",
        },
    },
    gitlabicon: {
        height: "40px",
        marginRight: "10px",
    },
    or: {
        margin: spacing(6, 0),
    },
    titlelogo: {
        // marginBottom: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Ubuntu, Helvetica, sans-serif",
        fontSize: "28px",
        fontWeight: "bold",
        color: palette.body.main,
        transition: "0.2s",
        margin: "0 0 50px 0",
        cursor: "pointer",
        "& span": {
            color: palette.primary.main,
        },
        "&:hover": {
            transform: "scale(1.04)",
        },
    },
    title: {
        marginBottom: 30,
        fontFamily: "Ubuntu, Helvetica, sans-serif",
        fontSize: "34px",
        fontWeight: "bold",
        "& span": {
            color: palette.primary.main,
        },
        "& small": {
            color: palette.grey.light,
            fontWeight: 400,
            marginLeft: spacing(1),
        },
    },
}));
////// Constants :

///////////////////////-- Component Function --///////////////////////////////////////////////////////////////////////////////////////
const Login = () => {
    ////// Init Values :
    const location = useLocation();
    const classes = useStyles();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { clientData } = useContext(ClientDataContext);

    ////// Init Function :

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    ////// Internal Functions :
    const TryLoginId = (username, password) => {
        setLoading(true);
        AccountLib.login(username, password, clientData?.site_client?.client_id)
            .then(resss => {
                const scope = TokenData.fromStorage().scope;

                if (scope.indexOf("backoffice_user") > -1 || scope.indexOf("staff_user") > -1 || scope.indexOf("admin_backoffice_user") > -1 || scope.indexOf("admin_staff_user") > -1) {
                    if (location.pathname.indexOf("documents") > -1) {
                        window.location.href = window.location.origin + "/documents";
                    } else {
                        window.location.href = window.location.origin + "/tree";
                    }
                } else {
                    localStorage.removeItem("app_user");
                    localStorage.removeItem("app_auth");
                    localStorage.removeItem("user");
                    localStorage.removeItem("store");
                    setLoading(false);
                    setError("Vous n'avez pas les droits pour accéder à cette application");
                }
            })
            .catch(err => {
                setLoading(false);
                const message = JSON.stringify(err.response.data);
                if (username === "") {
                    setError("Un identifiant est requis");
                } else if (password === "") {
                    setError("Un mot de passe est requis");
                } else if (message.indexOf("application correspondante") > -1) {
                    setError(
                        <div>
                            {"Impossible de trouver l'application correspondante."}
                            <br />
                            Veuillez contacter le service support.
                        </div>
                    );
                } else if (message.indexOf("credentials") > -1) {
                    setError("Identifiant ou mot de passe incorrect.");
                } else {
                    setError(
                        <div>
                            Application indisponible pour le moment, veuillez réessayer ultérieurement.
                            <br />
                            Si le problème persiste, contactez le service support.
                        </div>
                    );
                }
            });
    };

    ////// Tests

    ///////////////////////-- Return ( Display ) --///////////////////////////////////////////////////////////////////////////////////////

    return (
        <Grid container className={classes.root}>
            <CssBaseline />
            <Grid item xs={12} sm={12} md={12} lg={12} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <div>
                        <Title login={true} />
                        <div>
                            <h1 style={{ textAlign: "start", margin: "20px 0 80px 0" }}>{"Éditeur de projets"}</h1>
                        </div>

                        <form>
                            {error ? (
                                <Alert severity="error" style={{ marginBottom: "20px", marginTop: "-50px" }}>
                                    <AlertTitle>
                                        <strong>{"Erreur d'authentification"}</strong>
                                    </AlertTitle>
                                    {error}
                                </Alert>
                            ) : null}
                            <TextField
                                variant="outlined"
                                fullWidth
                                autoComplete="username"
                                id="identifiant"
                                label="Identifiant"
                                aria-label="username"
                                name="identifiant"
                                size="small"
                                value={id}
                                onInput={e => setId(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === "Enter") TryLoginId(id, password);
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                aria-label="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onInput={e => setPassword(e.target.value)}
                                onKeyDown={e => {
                                    if (e.key === "Enter") TryLoginId(id, password);
                                }}
                                size="small"
                            />

                            <Button disabled={loading} fullWidth variant="contained" color="primary" style={{ height: "50px", marginTop: "40px " }} onClick={() => TryLoginId(id, password)}>
                                {loading ? (
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        Chargement
                                        <CircularProgress size={12} style={{ marginLeft: "15px" }} />
                                    </div>
                                ) : (
                                    "Se connecter"
                                )}
                            </Button>
                            {/* <Grid container className={classes.submit}>
                <Grid item>
                  <a href="https://magic-lemp.com/">{"Mot de passe oublié ?"}</a>
                </Grid>
              </Grid> */}
                        </form>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default Login;
