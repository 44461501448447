import ActiveEditorContext from "../../../providers/ActiveEditorContext";
import TreeContext from "../../../providers/TreeContext";
import TreeLine from "./TreeLine";
import { CircularProgress, Button } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import React from "react";
import TemplateLib from "~/common/libs/template-lib";
import config from "~/config";

export default function ListTasks({
  data,
  index,
  root,
  root_key,
  parent_index,
}) {
  const { tree, updateTree } = React.useContext(TreeContext);
  const { activeEditor, updateActiveEditor } =
    React.useContext(ActiveEditorContext);
  const splitIndex = index.split("-");
  let taskList = [];
  if (splitIndex.length === 2) {
    taskList = tree.projects[splitIndex[0]].sections[splitIndex[1]].tasks;
  }
  if (splitIndex.length === 3) {
    taskList =
      tree.projects[splitIndex[0]].sections[splitIndex[1]].sections[
        splitIndex[2]
      ].tasks;
  }

  React.useEffect(() => {
    if (taskList === "loading" || taskList === undefined) {
      TemplateLib.getListTaskOfCategories(data.id)
        .then((res) => {
          let sorted = res;
          sorted.sort(function (a, b) {
            return a.order - b.order;
          });
          let result = { ...tree };
          if (index.split("-").length === 2) {
            result.projects[index.split("-")[0]].sections[
              index.split("-")[1]
            ].tasks = sorted;
          }
          if (index.split("-").length === 3) {
            result.projects[index.split("-")[0]].sections[
              index.split("-")[1]
            ].sections[index.split("-")[2]].tasks = sorted;
          }
          updateTree(result);
        })
        .catch((e) => {
          console.error("error get tasks of categorie", e);
        });
    }
  }, [taskList]);

  return taskList === "loading" || taskList === undefined ? (
    <CircularProgress size={20} />
  ) : taskList.length ? (
    taskList.map((elem, key) => {
      return (
        <div>
          <TreeLine
            key={key}
            nodeId={index + "-" + key}
            root={[...root, elem]}
            data={elem}
            index={index}
            parent_index={parent_index}
            index_to_display={key + 1}
            root_key={[...root_key, key]}
            style={{
              border: "1px solid grey",
              backgroundColor: `rgba(${
                localStorage.getItem("color-task")
                  ? localStorage.getItem("color-task")
                  : config.defaultColors.task
              },0.2)`,
            }}
          />
        </div>
      );
    })
  ) : (
    <TreeItem
      nodeId={index + "-" + 9999}
      expandIcon={<></>}
      label={
        <div>
          <div>Aucune tâche</div>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              updateTree({ ...tree, selected: root_key.join("-") });
              updateActiveEditor({
                type: "add_task",
                data: null,
                root,
                root_key,
              });
            }}
            style={{ padding: "5px 10px" }}
          >
            + Ajouter une tâche
          </Button>
        </div>
      }
      style={{
        height: "65px",
        border: "1px solid grey",
        backgroundColor: `rgba(${
          localStorage.getItem("color-task")
            ? localStorage.getItem("color-task")
            : config.defaultColors.task
        },0.2)`,
      }}
    />
  );
}
