import TemplateLib from "@common/libs/interview-template-lib";
import { Button, TextField } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import { toast } from "react-toastify";
import config from "~/config";

const useStyles = makeStyles(({ palette, spacing }) => ({
    line: {
        display: "flex",
        justifyContent: "center",
        margin: "10px 0",
    },
    project: {
        padding: "6px 9px",
        borderRadius: 5,
        fontWeight: "bold",
        textAlign: "center",
    },
    buttonLine: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 50,
    },
    document: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 20,
        marginBottom: 10,
    },
}));

export default function MoveModal({ projectList, myTemplate, setmodalAction, setOpenMenu, set_reload }) {
    const classes = useStyles();
    const color_project = localStorage.getItem("color-project") ? localStorage.getItem("color-project") : config.defaultColors.project;

    const [selectedProject, setselectedProject] = useState(null);

    const moveDocument = () => {
        TemplateLib.moveDocument({ id: myTemplate.id, project_id: selectedProject.id })
            .then(res => {
                toast.success("Déplacement du document enregistré avec succès");
                setmodalAction(null);
                setOpenMenu(null);
                set_reload(reload => !reload);
            })
            .catch(() => {
                toast.error("Erreur durant le déplacement du document");
            });
    };

    return (
        <div style={{ width: "100%" }}>
            <div className={classes.line} style={{ display: "block" }}>
                <div className={classes.document}>{myTemplate.name}</div>
                <div className={classes.line}>Workspace : {projectList.find(el => el.id === myTemplate.project).workspace} </div>
                <div className={classes.project} style={{ color: `rgb(${color_project})`, backgroundColor: `rgba(${color_project} , 0.3)` }}>
                    Projet : {projectList.find(el => el.id === myTemplate.project).name}
                </div>
            </div>
            <hr style={{ margin: "40px 0" }} />
            <div className={classes.line}>Vers le projet :</div>
            <Autocomplete
                style={{ backgroundColor: selectedProject !== null && `rgba(${color_project} , 0.3)`, fontWeight: "bold" }}
                value={selectedProject}
                onChange={(e, v) => setselectedProject(v)}
                size="small"
                fullWidth
                groupBy={option => option.workspace}
                options={projectList.filter(e => e.id !== myTemplate.project)}
                getOptionLabel={option => option.name}
                renderInput={params => <TextField {...params} label="Projet cible" variant="outlined" />}
            />

            <div className={classes.buttonLine}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setmodalAction(null);
                        setOpenMenu(null);
                    }}>
                    Annuler
                </Button>
                <Button disabled={selectedProject == null} onClick={moveDocument} style={{ marginLeft: 15 }} color="primary" variant="contained">
                    Déplacer le document
                </Button>
            </div>
        </div>
    );
}
