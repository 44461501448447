import { ApiServiceRaw } from '../services/api-service';
import baseAxios from 'axios';
import User from '../models/user';
import TokenData from '../models/token-data';
import path from 'path';
import EnterprisesLib from './enterprises-lib';
import GroupsLib from './groups-lib';

const axios = baseAxios.create({
  baseURL: process.env.COMMON_LAW_STRIPE_API_URL,
});

const ApiService = new ApiServiceRaw(process.env.COMMON_LAW_STRIPE_API_URL);

export default class StripeLib {
  static PublishableKeyPath = '/api/stripe/publishable-key';

  static CurrentUser = '/api/user/current';

  static PaymentPath = '/api/payment';

  static PaymentMethod = '/api/payment-method';

  static Customer = '/api/customer';

  static SpecificCustomer = '/api/customer/{uuid}';

  static CustomerPaymentsPath = '/api/customer/{uuid}/payment';

  static CustomerPaymentMethods = '/api/customer/{uuid}/payment-method';

  static CustomerSubscriptionsPath = '/api/customer/{uuid}/subscription';

  static SpecificPaymentMethod = '/api/payment-method/{uuid}';

  static DefaultPaymentMethod = '/api/customer/{uuid}/default-payment-method';

  static Accounts = '/api/account';

  static SpecificAccount = '/api/account/{uuid}';

  static PricePath = '/api/price';

  static ProductPath = '/api/product';

  static PriceProductComeOnLawPath = '/api/product/comeonlaw/price';

  static SubscriptionPath = '/api/subscription';

  static StripeInfoSubscriptionPath = '/api/subscription/{uuid}/stripe-info';

  static async getPublishableKey() {
    const response = await axios.get(StripeLib.PublishableKeyPath);
    return response.data.stripe_publishable_key;
  }

  static async getSpecificCustomer(uuid) {
    const _path = StripeLib.SpecificCustomer.replace('{uuid}', uuid);
    const response = await ApiService.get(_path);
    return response.data;
  }

  static async addPayment(data) {
    const response = await ApiService.post(StripeLib.PaymentPath, data);
    return response.data;
  }

  static async getPayment(uuid) {
    const _path = path.join(StripeLib.PaymentPath, uuid);
    const response = await ApiService.get(_path);

    return response.data;
  }

  static async getPayments(uuid) {
    const _path = StripeLib.CustomerPaymentsPath.replace('{uuid}', uuid);
    const response = await ApiService.get(_path);

    return response.data;
  }

  static async getListPaymentMethod(uuid) {
    const _path = StripeLib.CustomerPaymentMethods.replace('{uuid}', uuid);
    const response = await ApiService.get(_path);

    return response.data;
  }

  static async getSpecificPaymentMethod(uuid) {
    const _path = StripeLib.SpecificPaymentMethod.replace('{uuid}', uuid);
    const response = await ApiService.get(_path);

    return response.data;
  }

  static async getDefaultPaymentMethod(uuid) {
    const _path = StripeLib.DefaultPaymentMethod.replace('{uuid}', uuid);
    const response = await ApiService.get(_path);

    return response.data;
  }

  static async getSubscriptions(uuid) {
    const _path = StripeLib.CustomerSubscriptionsPath.replace('{uuid}', uuid);
    const response = await ApiService.get(_path);

    return response.data;
  }

  static async deletePaymentMethod(uuid) {
    const _path = StripeLib.SpecificPaymentMethod.replace('{uuid}', uuid);
    const response = await ApiService.delete(_path);

    return response.data;
  }

  static async putPaymentMethod(dataForm, uuid_payment, facturation_info) {
    const data = {
      nameOnCard: dataForm.ownerCard,
      billingDetail: facturation_info,
    };

    const _path = StripeLib.SpecificPaymentMethod.replace('{uuid}', uuid_payment);
    const response = await ApiService.put(_path, data);
    return response;
  }

  static async putIsDefault(card) {
    const data = {
      nameOnCard: card.name_on_card,
      isDefault: true,
      billingDetail: card.billing_detail,
    };

    const _path = StripeLib.SpecificPaymentMethod.replace('{uuid}', card.uuid);
    const response = await ApiService.put(_path, data);
    return response;
  }

  static async postPaymentMethod(dataForm, payment_method, uuid_stripe, facturation_info) {
    const data = {
      nameOnCard: dataForm.ownerCard,
      stripePaymentMethodId: payment_method.id,
      customer: uuid_stripe,
      billingDetail: facturation_info,
    };
    const response = await ApiService.post(StripeLib.PaymentMethod, data);
    return response.data;
  }

  static async getCurrentUser() {
    const response = await ApiService.get(StripeLib.CurrentUser);
    return response.data;
  }

  // price
  static async getPrice(uuid = '') {
    const url = path.join(StripeLib.PricePath, String(uuid));

    const response = await ApiService.axios.get(url);
    return response.data;
  }

  static async addPrice(data) {
    const url = StripeLib.PricePath;

    const response = await ApiService.axios.post(url, data);
    return response.data;
  }

  static async editPrice(uuid, data) {
    const url = path.join(StripeLib.PricePath, String(uuid));

    const response = await ApiService.axios.put(url, data);
    return response.data;
  }

  // product
  static async getProduct(uuid) {
    const url = path.join(StripeLib.ProductPath, String(uuid));

    const response = await ApiService.axios.get(url);
    return response.data;
  }

  static async getPriceProduct(uuid) {
    const url = path.join(StripeLib.ProductPath, String(uuid), 'price');

    const response = await ApiService.axios.get(url);
    return response.data;
  }

  static async getPriceProductComeOnLaw() {
    const url = path.join(StripeLib.PriceProductComeOnLawPath);

    const response = await ApiService.axios.get(url);
    return response.data;
  }

  static async addProduct(data) {
    const url = StripeLib.ProductPath;

    const response = await ApiService.axios.post(url, data);
    return response.data;
  }

  static async editProduct(uuid, data) {
    const url = path.join(StripeLib.ProductPath, String(uuid));

    const response = await ApiService.axios.put(url, data);
    return response.data;
  }

  // subscription
  static async getSubscription(uuid) {
    const url = path.join(StripeLib.SubscriptionPath, String(uuid));

    const response = await ApiService.axios.get(url);
    return response.data;
  }

  static async editSubscription(uuid, data) {
    const url = path.join(StripeLib.SubscriptionPath, String(uuid));

    const response = await ApiService.axios.put(url, data);
    return response.data;
  }

  static async createSubscription(data) {
    const url = StripeLib.SubscriptionPath;

    const response = await ApiService.axios.post(url, data);
    return response.data;
  }

  static async cancelSubscription(uuid) {
    const url = path.join(StripeLib.SubscriptionPath, String(uuid), 'cancel');

    const response = await ApiService.axios.post(url);
    return response.data;
  }

  static async getStripeInfoSubscription(uuid) {
    const url = path.join(StripeLib.StripeInfoSubscriptionPath.replace('{uuid}', uuid));

    const response = await ApiService.axios.get(url);
    return response.data;
  }

  // taxes

  static TaxePath = '/api/tax-rate/{uuid}';

  static async getTaxeList() {
    const response = await ApiService.get(this.TaxePath.replace('/{uuid}', ''));
    return response.data;
  }

  static async getTaxe(uuid) {
    const response = await ApiService.get(this.TaxePath.replace('{uuid}', uuid));
    return response.data;
  }

  static async createTaxe(data) {
    const response = await ApiService.post(this.TaxePath.replace('/{uuid}', ''), data);
    return response.data;
  }

  static async deleteTaxe(uuid) {
    const response = await ApiService.delete(this.TaxePath.replace('{uuid}', uuid));
    return response.data;
  }
}
