////// React Core :
////// React Core :
////// React Core :
import ActiveEditorContext from "../../../../providers/ActiveEditorContext";
import TreeContext from "../../../../providers/TreeContext";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import EmailIcon from "@material-ui/icons/Email";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import NoteIcon from "@material-ui/icons/Note";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import TextsmsIcon from "@material-ui/icons/Textsms";
import React, { useContext } from "react";
import config from "~/config";

export default function TaskLine(props) {
  const { updateActiveEditor } = useContext(ActiveEditorContext);
  const { tree } = useContext(TreeContext);

  const { nodeId, data, root, root_key, icon, index_to_display, parent_index } =
    props;
  const getIcon = (data) => {
    if (data.actions?.length > 0 && data.actions[0].type !== undefined) {
      switch (data.actions[0].type) {
        case "text":
          return (
            <TextsmsIcon
              className="clickable_line"
              style={{ marginRight: "5px" }}
            />
          );
        case "form":
          return (
            <AssignmentIcon
              className="clickable_line"
              style={{ marginRight: "5px" }}
            />
          );
        case "interview":
          return (
            <ContactSupportIcon
              className="clickable_line"
              style={{ marginRight: "5px" }}
            />
          );
        case "electronic":
          return (
            <BorderColorIcon
              className="clickable_line"
              style={{ marginRight: "5px" }}
            />
          );
        case "upload":
          return (
            <CloudUploadIcon
              className="clickable_line"
              style={{ marginRight: "5px" }}
            />
          );
        case "courrier":
          return (
            <EmailIcon
              className="clickable_line"
              style={{ marginRight: "5px" }}
            />
          );
        case "payment":
          return (
            <EuroSymbolIcon
              className="clickable_line"
              style={{ marginRight: "5px" }}
            />
          );
        case "rdv":
          return (
            <OndemandVideoIcon
              className="clickable_line"
              style={{ marginRight: "5px" }}
            />
          );

        default:
          return (
            <NoteIcon
              className="clickable_line"
              style={{ marginRight: "5px" }}
            />
          );
      }
    } else
      return (
        <NoteIcon className="clickable_line" style={{ marginRight: "5px" }} />
      );
  };

  return (
    <i
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        color: `rgb(${
          localStorage.getItem("color-task")
            ? localStorage.getItem("color-task")
            : config.defaultColors.task
        })`,
        minHeight: "30px",
        transition: "0.3s",
        backgroundColor:
          tree.selected === nodeId
            ? `rgba(${
                localStorage.getItem("color-selected")
                  ? localStorage.getItem("color-selected")
                  : config.defaultColors.selected
              },0.6)`
            : "initial",
      }}
      className="clickable_line"
      onClick={() =>
        updateActiveEditor({
          type: "task",
          data,
          root,
          root_key,
        })
      }
    >
      {icon ? <div className="clickable_line" /> : getIcon(data)}
      {parent_index + 1}.{index_to_display} - {data.name}
      {data.mandatory && (
        <span
          onClick={() =>
            updateActiveEditor({
              type: "task",
              data,
              root,
              root_key,
            })
          }
          className="clickable_line"
          style={{
            marginLeft: "10px",
            padding: "3px 5px",
            backgroundColor: `rgba(${
              localStorage.getItem("color-project")
                ? localStorage.getItem("color-project")
                : config.defaultColors.project
            },0.2)`,
            borderRadius: "5px",
            fontSize: "12px",
            border: `1px solid rgba(${
              localStorage.getItem("color-project")
                ? localStorage.getItem("color-project")
                : config.defaultColors.project
            },1)`,
            color: `rgba(${
              localStorage.getItem("color-project")
                ? localStorage.getItem("color-project")
                : config.defaultColors.project
            },1)`,
          }}
        >
          facultatif
        </span>
      )}
    </i>
  );
}
