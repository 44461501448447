import DocumentContext from "../../../providers/DocumentContext";
import VariableDialog from "../ListVariables/VariableDialog";
import getNameType from "./functions/getNameType";
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { useState, useContext } from "react";

const useStyles = makeStyles(() => ({
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    fontSize: 14,
  },
  button: {
    width: 25,
    height: 25,
    minHeight: 25,
    marginLeft: 10,
  },
  table: {
    padding: 1,
    margin: " 0px",
    flex: 1,
    borderRadius: 5,
    backgroundColor: "white",
    overflowY: "scroll",
  },
  tableHead: {
    fontSize: 12,
    padding: 5,
    color: "black",
  },
  tableCellId: {
    fontSize: 10,
    padding: 5,
    color: "grey",
  },
  tableCell: {
    fontSize: 10,
    padding: 5,
  },
  row: {
    transition: "0.1s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#eee!important",
    },
  },

  numberTitle: {
    color: "grey",
    fontWeight: "bold",
    marginRight: 5,
    fontSize: 12,
  },

  accordion: {
    margin: "0!important",
    borderBottom: "0px!important",
  },

  accordionSummary: {
    backgroundColor: "rgba(0, 0, 0, 0.03);",
  },

  accordionDetails: {
    padding: "8px 8px 8px",
    "&:hover": {
      cursor: "pointer",
    },
  },

  idDetails: {
    fontWeight: "bold",
    marginRight: "5px",
  },

  "Mui-expanded": {
    margin: 0,
  },

  variableList: {
    maxHeight: "calc(100vh - 330px)",
    overflowY: "scroll",
  },
}));

export default function DialogListVar() {
  const { document, updateDocument } = useContext(DocumentContext);
  const [variableDialog, setvariableDialog] = useState(false);
  const classes = useStyles();
  const [checked, setChecked] = useState(true);
  const [documentFiltered, setDocumentFiltered] = useState(
    document.groupsVariables
  );

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (checked) {
      const filter = document.groupsVariables.filter((el, key) => {
        return (
          document.variables.filter((elem) => elem.group === el.id).length > 0
        );
      });
      setDocumentFiltered(filter);
    } else {
      setDocumentFiltered(document.groupsVariables);
    }
  };

  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <VariableDialog
        variableDialog={variableDialog}
        setvariableDialog={setvariableDialog}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          style={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 40,
          }}
          onClick={() =>
            setvariableDialog({
              name: "",
              type: "text",
              is_global: true,
              question: "",
              description: "",
              order_in_group: null,
              options: null,
              placeholder: null,
              project: 0,
              group: null,
              id: null,
              field: null,
              is_mail_merging: false,
              role: "",
              database_object_model_fields_mapping: [
                {
                  database_object_model_name: "",
                  mappingType: "simple",
                  data: [
                    {
                      mapping_complex: "",
                      database_object_model_field_name: "",
                      variable_field_name: "",
                    },
                  ],
                },
              ],
            })
          }
        >
          <AddIcon style={{ marginRight: 10 }} /> Ajouter variable
        </Button>
        <FormControlLabel
          control={<Checkbox onChange={handleChange} checked={checked} />}
          label="Afficher les pages vides"
        />
      </div>
      <div className={classes.variableList}>
        {documentFiltered.map((el, key) => (
          <React.Fragment key={key}>
            <Accordion
              className={classes.accordion}
              elevation={0}
              defaultExpanded={
                document.variables.filter((elem) => elem.group === el.id)
                  .length > 0
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
              >
                <div>
                  <span className={classes.numberTitle}>
                    {" "}
                    {el.order >= 10 ? el.order : "0" + el.order}.
                  </span>{" "}
                  {el.name}
                </div>
              </AccordionSummary>
              {document.variables
                .filter((elem) => elem.group === el.id)
                .sort((a, b) => {
                  return a.order - b.order;
                })
                .map((elem, key) => (
                  <AccordionDetails
                    className={classes.accordionDetails}
                    key={key}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={() => setvariableDialog(elem)}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                        }}
                      >
                        <span className={classes.idDetails}>#{elem.id}</span>
                        <span>{elem.name}</span>
                      </div>
                      <span>{getNameType(elem.type)}</span>
                    </div>
                  </AccordionDetails>
                ))}
            </Accordion>
          </React.Fragment>
        ))}
        <Accordion className={classes.accordion} elevation={0} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.accordionSummary}
          >
            <div>
              <span className={classes.numberTitle}>
                {document.groupsVariables.length + 1 > 10
                  ? document.groupsVariables.length + 1
                  : "0" + (document.groupsVariables.length + 1)}
                .
              </span>
              Informations complémentaires
            </div>
          </AccordionSummary>

          {document.variables.filter((elem) => elem.group === null).length ===
            0 && <div>/</div>}
          {document.variables
            .filter((elem) => elem.group === null)
            .sort((a, b) => {
              return a.id - b.id;
            })
            .map((elem, key) => (
              <AccordionDetails className={classes.accordionDetails} key={key}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onClick={() => setvariableDialog(elem)}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "start",
                    }}
                  >
                    <span className={classes.idDetails}>#{elem.id}</span>
                    <span>{elem.name}</span>
                  </div>
                  <span>{getNameType(elem.type)}</span>
                </div>
              </AccordionDetails>
            ))}
        </Accordion>
      </div>
    </div>
  );
}
