import Authentification from "./Autentification";
import React from "react";
import ReactDOM from "react-dom";
import ThemeProvider from "~/theme";

ReactDOM.render(
    <ThemeProvider>
        <Authentification />
    </ThemeProvider>,
    document.getElementById("root")
);
