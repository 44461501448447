import DocumentContext from "../../../providers/DocumentContext";
import TemplateLib from "@common/libs/interview-template-lib";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab/";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";

export default function FormDialog({
  variableDialog,
  setvariableDialog,
  arrayMultiObjectsDialog,
  setArrayMultiObjectsDialog,
}) {
  const [name, setName] = useState("");
  const makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleClose = () => {
    setArrayMultiObjectsDialog(false);
  };

  const createObject = () => {
    const id = makeid(9);
    let variable = { ...variableDialog };
    variable.options.push([
      {
        id: "__object_name__",
        label: name,
        type: "text",
      },
      {
        id: id,
        label: "",
        type: "text",
      },
    ]);
    variable.placeholder.push([
      {
        id: "__object_name__",
        value: "",
      },
      {
        id: id,
        value: "",
      },
    ]);
    variable.database_object_model_fields_mapping.push({
      database_object_model_name: "",
      mappingType: "simple",
      data: [
        {
          mapping_complex: "",
          database_object_model_field_name: "",
          variable_field_name: "",
        },
      ],
    });
    setvariableDialog(variable);
    setName("");
    handleClose();
  };

  return (
    arrayMultiObjectsDialog && (
      <Dialog
        open={arrayMultiObjectsDialog ? true : false}
        onClose={handleClose}
      >
        <div style={{ maxWidth: 1000 }}>
          <DialogTitle>Ajouter un objet</DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Alert severity="info">
                  Veuillez renseigner le nom de l'objet.
                </Alert>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  label="Nom de l'objet"
                  size="small"
                  multiline
                  fullWidth
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained">
              Annuler
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={createObject}
              disabled={name === ""}
            >
              Ajouter
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  );
}
