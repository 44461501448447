import { TextField } from "@material-ui/core";
import React from "react";

export default ({ variableDialog, setvariableDialog }) => {
  return (
    <TextField
      variant="outlined"
      label="Mapping dossiers permanents"
      style={{
        backgroundColor: "white",
        width: "100%",
        maxWidth: 300,
      }}
      size="small"
      value={
        variableDialog.database_object_model_fields_mapping[0]
          .database_object_model_field_name
      }
      onChange={(e) => {
        let result = { ...variableDialog };
        // console.log("nouvelle valeur de mapping", result);
        result.database_object_model_fields_mapping[0].database_object_model_field_name =
          e.target.value;
        setvariableDialog(result);
      }}
    />
  );
};
