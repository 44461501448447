import getColorEdit from "./getColorEdit";

const styles = {
  badge: {
    color: "white",
    fontWeight: "bold",
    padding: "3px 10px",
    borderRadius: 5,
    fontSize: 10,
    textShadow: "grey 1.4px 0.4px",
  },
};
const getNameType = (type) => {
  switch (type) {
    case "text":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("variable"),
          }}
        >
          Texte
        </span>
      );
    case "textformat":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("variable"),
          }}
        >
          Wysiwyg
        </span>
      );
    case "number":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("variable"),
          }}
        >
          Nombre
        </span>
      );
    case "date":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("variable"),
          }}
        >
          Date
        </span>
      );
    case "object":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("variable"),
          }}
        >
          Objet
        </span>
      );
    case "list":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("condition"),
          }}
        >
          Liste
        </span>
      );
    case "bool":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("condition"),
          }}
        >
          Oui / Non
        </span>
      );
    case "choice":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("condition"),
          }}
        >
          Choix
        </span>
      );
    case "multi":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("condition"),
          }}
        >
          Choix multiples
        </span>
      );
    case "array":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("repetition"),
          }}
        >
          Tableau
        </span>
      );
    case "unisign":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("variable"),
          }}
        >
          Signature
        </span>
      );

    case "multisign":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("variable"),
          }}
        >
          Multi-Signature
        </span>
      );
    case "multiarray":
      return (
        <span
          style={{
            ...styles.badge,
            backgroundColor: getColorEdit("repetition"),
          }}
        >
          Tableau multi-objets
        </span>
      );

    default:
      break;
  }
};

export default getNameType;
