import BadgeLabel from "../../../BadgeLabel";
import {
  Grid,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

export default function LineCondition({
  data,
  indexLine,
  indexBloc,
  variableInstance,
  setvariableInstance,
  options,
}) {
  const deleteBloc = () => {
    let result = { ...variableInstance };
    result.data = result.data.filter((el, i) => i !== indexBloc);
    setvariableInstance(result);
  };

  const deleteLine = () => {
    let result = { ...variableInstance };
    result.data[indexBloc] = result.data[indexBloc].filter(
      (el, i) => i !== indexLine
    );
    setvariableInstance(result);
  };

  return (
    <>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 10px 0 10px",
        }}
      >
        {indexLine === 0 ? <div>SI</div> : <div>ET</div>}
        {indexBloc === 0 && indexLine === 0 ? (
          <div></div>
        ) : indexLine !== 0 ? (
          <IconButton size="small" onClick={deleteLine}>
            <CloseIcon style={{ fontSize: 20 }} />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={deleteBloc}>
            <DeleteForeverIcon style={{ fontSize: 20 }} />
          </IconButton>
        )}
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <FormControl
          style={{ backgroundColor: "white" }}
          fullWidth
          variant="outlined"
          size="small"
        >
          <InputLabel></InputLabel>
          <Autocomplete
            size="small"
            value={variableInstance.data[indexBloc][indexLine].variable}
            getOptionSelected={(option) =>
              option.name ===
              variableInstance.data[indexBloc][indexLine].variable.name
            }
            onChange={(e, v) => {
              let result = { ...variableInstance };
              result.data[indexBloc][indexLine].variable = v;
              result.data[indexBloc][indexLine].option = "";
              setvariableInstance(result);
            }}
            options={options
              .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
              .sort((a, b) => -b.groupName.localeCompare(a.groupName))}
            groupBy={(option) => option.groupName}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} size="small" variant="outlined" />
            )}
            renderOption={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <BadgeLabel type={option.type} />
                <span style={{ marginLeft: 15 }}>
                  <b>#{option.id}</b> {option.name}
                </span>
              </div>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          disabled={!data.variable}
          style={{ backgroundColor: "white" }}
        >
          <InputLabel></InputLabel>
          <Select
            value={variableInstance.data[indexBloc][indexLine].option}
            onChange={(e) => {
              let result = { ...variableInstance };
              result.data[indexBloc][indexLine].option = e.target.value;
              setvariableInstance(result);
            }}
          >
            <MenuItem value={""}></MenuItem>
            {data.variable?.type === "bool" && (
              <MenuItem value={true}>OUI</MenuItem>
            )}
            {data.variable?.type === "bool" && (
              <MenuItem value={false}>NON</MenuItem>
            )}
            {data.variable?.options?.length &&
              data.variable.options.map((el, indexBloc) => (
                <MenuItem key={indexBloc} value={el}>
                  {el}
                </MenuItem>
              ))}
            {data.variable?.type === "list" &&
              data.variable?.placeholder?.length &&
              data.variable.placeholder.map((el, indexBloc) => (
                <MenuItem key={indexBloc} value={el}>
                  {el}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}
