import { realToLight } from "./editor-functions/convertHighlightColors";
import insertBloc from "./editor-functions/insertBloc";
import onSelect from "./editor-functions/onSelect";
import onSelectClick from "./editor-functions/onSelectClick";
import onToolbarClick from "./editor-functions/onToolbarClick";
import updateDocx from "./editor-functions/updateDocx";
import language from "./utils/langFr.json";
import { L10n } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import {
  DocumentEditorContainerComponent,
  Toolbar,
  SfdtExport,
  Selection,
  Editor,
  TextExport,
  WordExport,
  OptionsPane,
  Search,
} from "@syncfusion/ej2-react-documenteditor";
import "@syncfusion/ej2-react-documenteditor/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import * as React from "react";
import uploadMachineDoc from "./functions/uploadMachineDoc";

L10n.load({
  "fr-FR": {
    documenteditor: language.fr.documenteditor,
    documenteditorcontainer: language.fr.documenteditorcontainer,
  },
});

DocumentEditorContainerComponent.Inject(
  Toolbar,
  SfdtExport,
  Selection,
  Editor,
  WordExport,
  TextExport,
  OptionsPane,
  Search
);
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  componentDidMount() {
    const documentEditor = this.container.documentEditor;
    let items = documentEditor.contextMenu.menuItems;
    items = items.filter((el) => el.id !== "container_editor_add_comment");
    documentEditor.contextMenu.menuItems = items;

    window.dispatchEvent(new Event("resize"));

    documentEditor.enableLocalPaste = false;
    window.document.querySelector(".toolGreen").classList.add("tooltip-green");
    window.document.querySelector(".toolRed").classList.add("tooltip-red");
    window.document.querySelector(".toolBlue").classList.add("tooltip-blue");
  }

  componentDidUpdate() {
    window.dispatchEvent(new Event("resize"));
    const documentEditor = this.container.documentEditor;
    const { document, updateDocument, tabIndex, uploadedFile } = this.props;

    if (tabIndex !== 1)
      window.document
        .querySelector("#editor_document")
        .classList.add("hideEditor");
    else
      window.document
        .querySelector("#editor_document")
        .classList.remove("hideEditor");

    if (document.fireChangeToBloc !== null) {
      insertBloc(this);
    }
    if (document.launchSave !== null && document.launchSave !== false) {
      updateDocx(this);
    }
    if (uploadedFile) {
      documentEditor.open(uploadedFile);
      updateDocument({ ...document, uploadedFile: false });
    } else if (uploadedFile === false) {
      realToLight(this);
    }
  }

  // downloadDocument() {
  //   this.container.documentEditor.save(this.props.document.name, "Docx");
  // }

  // uploadDocument() {
  // }

  render() {
    let items = [
      // "New",
      // "Open",
      "Undo",
      "Redo",
      "Separator",
      "Image",
      "Table",
      "Hyperlink",
      //   "Bookmark",
      //   "Comments",
      // "TableOfContents",
      "Separator",
      "Header",
      "Footer",
      // "PageSetup",
      "PageNumber",
      "Break",
      // "Separator",
      "Find",
      "Separator",
      // "Separator",
      // LookVariableItem
      // "userColor",
      //   "Separator",
      //   "LocalClipboard",
      //   "RestrictEditing"
      {
        cssClass: "e-de-prop-toolCustom toolGreen",
        prefixIcon: "e-icons e-de-ctnr-variable",
        tooltipText: "Insérer une variable",
        text: "Insérer une variable",
        id: "Variable",
      },
      {
        cssClass: "e-de-prop-toolCustom toolRed",
        prefixIcon: "e-icons e-de-ctnr-condition",
        tooltipText: "Insérer une condition",
        text: "Insérer une condition",
        id: "Condition",
      },
      {
        cssClass: "e-de-prop-toolCustom toolBlue",
        prefixIcon: "e-icons e-de-ctnr-repetition",
        tooltipText: "Insérer un tableau ou répétion",
        text: "Insérer un tableau ou répétion",
        id: "Repetition",
      },
      {
        cssClass: "e-de-prop-toolCustom",
        prefixIcon: "",
        tooltipText: "Télécharger le .docx",
        text: "Télécharger le .docx",
        id: "DownloadDocument",
      },
      {
        cssClass: "e-de-prop-toolCustom",
        prefixIcon: "",
        tooltipText: "Mettre à jour le document",
        text: "Mettre à jour le document",
        id: "UpdateDocument",
      },
    ];

    return (
      <div
        onMouseUp={(e) => onSelect(this, e)}
        onDoubleClick={() => onSelectClick(this)}
        id="editor_document"
      >
        <DocumentEditorContainerComponent
          ref={(scope) => {
            this.container = scope;
          }}
          id="container"
          style={{ height: "590px" }}
          toolbarItems={items}
          locale={"fr-FR"}
          toolbarClick={(e) => onToolbarClick(this, e)}
          serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
          enableToolbar={true}
          enableSelection={true}
          enableEditor={true}
          enableSfdtExport={true}
          enableTextExport={true}
          enableWordExport={true}
          enableComment={false}
          enableLocalPaste={true}
          enableSearch={true}
          isReadOnly={false}
          enableOptionsPane={true}
        />
        {/* <button onClick={this.downloadDocument.bind(this)}>
          Télécharger le document Word
        </button>
        <button onClick={this.uploadDocument.bind(this)}>
          Mettre à jour le document Word
        </button> */}
      </div>
    );
  }
}
