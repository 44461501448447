import { Grid, TextField, Fade, Slide, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    title: {
        color: "grey",
        fontSize: "12px",
    },
    inputLabel: {
        fontSize: "12px",
    },
    inputPlaceholder: {
        color: "#aaa",
        fontSize: "12px",
    },
}));

export default function Input(props) {
    const classes = useStyles();
    const { _formData, set_formData, index } = props;

    const deleteLine = () => {
        let result = { ..._formData };
        result.actions[0].data.schema = result.actions[0].data.schema.filter(elem => elem.key !== _formData.actions[0].data.schema[index].key);
        set_formData(result);
    };

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
            <div>
                <Fade in={true} {...{ timeout: 1800 }}>
                    <div>
                        <small style={{ display: "flex", justifyContent: "center", marginBottom: "10px", fontWeight: "bold" }}>Champ à remplir</small>
                        <Grid container style={{ marginBottom: "10px" }}>
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                <TextField
                                    multiline
                                    InputLabelProps={{
                                        className: classes.title,
                                    }}
                                    InputProps={{
                                        className: classes.inputLabel,
                                    }}
                                    fullWidth
                                    value={_formData.actions[0].data.schema[index].label}
                                    onChange={e => {
                                        let result = { ..._formData };
                                        result.actions[0].data.schema[index].label = e.target.value;
                                        set_formData(result);
                                    }}
                                    variant="outlined"
                                    size="small"
                                    label="Titre"
                                    style={{ backgroundColor: "white" }}
                                />
                            </Grid>
                            <Grid item lg={5} md={5} sm={5} xs={12}>
                                <TextField
                                    fullWidth
                                    InputLabelProps={{
                                        className: classes.title,
                                    }}
                                    InputProps={{
                                        className: classes.inputPlaceholder,
                                    }}
                                    value={_formData.actions[0].data.schema[index].placeholder}
                                    onChange={e => {
                                        let result = { ..._formData };
                                        result.actions[0].data.schema[index].placeholder = e.target.value;
                                        set_formData(result);
                                    }}
                                    variant="outlined"
                                    size="small"
                                    label="Pré-remplissage"
                                    style={{ backgroundColor: "white" }}
                                />
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button onClick={deleteLine} variant="contained" color="secondary" style={{ height: "27px", width: "60%", margin: "15% 0 0 10%" }}>
                                    <b>-</b>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </div>
        </Slide>
    );
}
