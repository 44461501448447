import StoreDataContext from "../../../../providers/StoreDataContext";
import { Grid, Select, InputLabel, MenuItem, FormControl, Button, ListItemText } from "@material-ui/core";
import React, { useContext, useEffect } from "react";

export default function Upload(props) {
    const { _formData, set_formData } = props;
    const { storeData } = useContext(StoreDataContext);

    useEffect(() => {
        let state = {
            text: _formData.actions[0].data.text,
            event_id: _formData.actions[0].data.event_id !== undefined && _formData.actions[0].data.event_id !== null ? _formData.actions[0].data.event_id : "",
            speciality_id: _formData.actions[0].data.speciality_id !== undefined && _formData.actions[0].data.speciality_id !== null ? _formData.actions[0].data.speciality_id : "",
        };
        set_formData({ ..._formData, actions: [{ ..._formData.actions[0], data: state }] });
    }, []);
    return (
        <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <FormControl variant="outlined" fullWidth style={{ backgroundColor: "white" }} size="small">
                    <InputLabel>Spécialité associée</InputLabel>
                    <Select
                        value={_formData.actions[0].data.speciality_id}
                        onChange={e => {
                            let result = { ..._formData };
                            result.actions[0].data.speciality_id = e.target.value;
                            set_formData(result);
                        }}
                        label="Spécialité associée">
                        {storeData.specialities
                            .sort(function (a, b) {
                                return a.id - b.id;
                            })
                            .map((speciality, key) => (
                                <MenuItem key={key} value={speciality.id}>
                                    <ListItemText primary={speciality.name} />
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button disabled variant="contained" style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", height: "40px" }}>
                    Planifier une visioconférence
                </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className="description">L'utilisateur se chargera de planifier ses rendez-vous.</div>
            </Grid>
        </Grid>
    );
}
