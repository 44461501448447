import { CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React, { useContext } from "react"
import ClientDataContext from "~/providers/ClientDataContext"

const useStyles = makeStyles(({ palette }) => ({
  root: {
    // marginBottom: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Ubuntu, Helvetica, sans-serif",
    fontSize: "28px",
    fontWeight: "bold",
    color: palette.body.main,
    transition: "0.2s",
    flexGrow: 1,

    // cursor: "pointer",
    "& span": {
      color: palette.primary.main,
    },
  },
}))

function Title(props) {
  const classes = useStyles()
  const { clientData } = useContext(ClientDataContext)

  return (
    <div
      className={classes.root}
      style={{ margin: props.login ? "0 0 50px 0" : "0" }}
    >
      {clientData?.theme ? (
        <img
          className={classes.title}
          alt="logo whitemark"
          src={clientData.theme.logoUrl}
          style={{
            marginLeft: props.login ? 0 : 40,
            height: "100%",
            maxHeight: props.login ? 100 : 40,
            maxWidth: props.login ? 200 : 150,
            objectFit: "contain",
          }}
        />
      ) : (
        <div>
          {props.login && (
            <h1 style={{ display: "flex", alignItems: "center" }}>
              ComeOn<span>Law</span>
            </h1>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0 ",
            }}
          >
            {/* <CircularProgress size={20} /> */}
          </div>
        </div>
      )}
    </div>
  )
}

export default Title
