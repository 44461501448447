import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import EmailIcon from "@material-ui/icons/Email";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import HelpIcon from "@material-ui/icons/Help";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import ShareIcon from "@material-ui/icons/Share";
import TextsmsIcon from "@material-ui/icons/Textsms";
import React from "react";
import config from "~/config";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        minHeight: "200px",
    },
    lineHelp: {
        minHeight: "26px",
        display: "flex",
        alignItems: "center",
    },
    helpIcon: {
        width: "25px",
        height: "25px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "10px",
    },
});

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: "rgb(235,235,235)",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #222",
        padding: "10px",
        minWidth: "400px",
    },
}))(Tooltip);

export default function TooltipHelp() {
    const classes = useStyles();
    return (
        <HtmlTooltip
            arrow
            placement="left-start"
            title={
                <React.Fragment>
                    <div className={classes.lineHelp}>
                        <span
                            style={{
                                marginRight: "10px",
                                padding: "3px 5px",
                                backgroundColor: `rgba(${localStorage.getItem("color-project") ? localStorage.getItem("color-project") : config.defaultColors.project},0.2)`,
                                borderRadius: "5px",
                                fontSize: "12px",
                                border: `1px solid rgba(${localStorage.getItem("color-project") ? localStorage.getItem("color-project") : config.defaultColors.project},1)`,
                                color: `rgba(${localStorage.getItem("color-project") ? localStorage.getItem("color-project") : config.defaultColors.project},1)`,
                            }}>
                            facultatif
                        </span>
                        Élement facultatif
                    </div>
                    <div className={classes.lineHelp}>
                        <span className={classes.helpIcon}>
                            <ShareIcon style={{ fontSize: 20 }} />
                        </span>
                        Template paratagé avec toutes les entités
                    </div>
                    <div className={classes.lineHelp}>
                        <span className={classes.helpIcon}>
                            <CollectionsBookmarkIcon style={{ fontSize: 20 }} />
                        </span>
                        Template de Projet
                    </div>
                    <div className={classes.lineHelp}>
                        <span className={classes.helpIcon}>
                            <ClearAllIcon style={{ fontSize: 20 }} />
                        </span>
                        Section d'un projet
                    </div>
                    <div className={classes.lineHelp} style={{ marginLeft: 5, fontSize: "16px" }}>
                        <b>Tâches : </b>
                    </div>
                    <div style={{ marginLeft: 25 }}>
                        <div className={classes.lineHelp}>
                            <span className={classes.helpIcon}>
                                <TextsmsIcon style={{ fontSize: 20 }} />
                            </span>
                            Tâche - Texte informatif
                        </div>
                    </div>
                    <div style={{ marginLeft: 25 }}>
                        <div className={classes.lineHelp}>
                            <span className={classes.helpIcon}>
                                <AssignmentIcon style={{ fontSize: 20 }} />
                            </span>
                            Tâche - Formulaire
                        </div>
                    </div>
                    <div style={{ marginLeft: 25 }}>
                        <div className={classes.lineHelp}>
                            <span className={classes.helpIcon}>
                                <ContactSupportIcon style={{ fontSize: 20 }} />
                            </span>
                            Tâche - Génération de documents
                        </div>
                    </div>
                    <div style={{ marginLeft: 25 }}>
                        <div className={classes.lineHelp}>
                            <span className={classes.helpIcon}>
                                <BorderColorIcon style={{ fontSize: 20 }} />
                            </span>
                            Tâche - Signature de documents
                        </div>
                    </div>
                    <div style={{ marginLeft: 25 }}>
                        <div className={classes.lineHelp}>
                            <span className={classes.helpIcon}>
                                <CloudUploadIcon style={{ fontSize: 20 }} />
                            </span>
                            Tâche - Upload de fichiers
                        </div>
                    </div>
                    <div style={{ marginLeft: 25 }}>
                        <div className={classes.lineHelp}>
                            <span className={classes.helpIcon}>
                                <EmailIcon style={{ fontSize: 20 }} />
                            </span>
                            Tâche - Courrier
                        </div>
                    </div>
                    <div style={{ marginLeft: 25 }}>
                        <div className={classes.lineHelp}>
                            <span className={classes.helpIcon}>
                                <EuroSymbolIcon style={{ fontSize: 20 }} />
                            </span>
                            Tâche - Paiement en ligne
                        </div>
                    </div>
                    <div style={{ marginLeft: 25 }}>
                        <div className={classes.lineHelp}>
                            <span className={classes.helpIcon}>
                                <OndemandVideoIcon style={{ fontSize: 20 }} />
                            </span>
                            Tâche - Prise de rdv visio
                        </div>
                    </div>
                </React.Fragment>
            }>
            <HelpIcon />
        </HtmlTooltip>
    );
}
