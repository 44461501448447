import DocumentContext from "../../../../../providers/DocumentContext";
import BadgeLabel from "../../../BadgeLabel";
import getNameType from "../../functions/getNameType";
import SaveButton from "../SaveButton";
import PrintTable from "./PrintTable";
import {
  Grid,
  Button,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Collapse,
  Switch,
  Menu,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useContext } from "react";
import { toast } from "react-toastify";

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    fontSize: 14,
    borderBottom: " 1px solid silver",
    backgroundColor: "#00b8d9",
    color: "white",
    fontWeight: "bold",
  },
  container: {
    padding: "10px 30px",
    backgroundColor: "rgba(0, 184, 217, 0.1)",
  },
  desc: {
    color: "#555",
    fontSize: 14,
  },
  variable: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  preview: {
    width: "100%",
  },
  previewValue: {
    backgroundColor: "white",
    minHeight: "24px",
  },
  previewTitle: {
    fontWeight: "bold",
  },
}));

export default ({
  variableInstance,
  setvariableInstance,
  setOpen,
  isFromWordIntegration,
}) => {
  const classes = useStyles();
  const { document, updateDocument } = useContext(DocumentContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [blocValue, setBlocValue] = useState(document.selection);
  const [showArrayFormat, setshowArrayFormat] = useState(false);
  const [select, setSelect] = useState({ bloc: "", cursor: 0 });
  const [preview, updatePreview] = useState(null);

  const options = document.variables
    .filter((el) => el.type === "array" || el.type === "multiarray")
    .map((option) => {
      const firstLetter = option.name[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        groupName: document.groupsVariables.find((el) => el.id === option.group)
          ? document.groupsVariables.find((el) => el.id === option.group).name
          : "Aucun groupe",
        ...option,
      };
    });

  React.useEffect(() => {
    setvariableInstance({
      type: "repetition",
      bloc: document.selection,
      columns: [],
      data: { variable: null },
    });
  }, []);

  const onSave = () => {
    if (showArrayFormat) {
      updateDocument({
        ...document,
        fireChangeToBloc: variableInstance.columns,
        statement: `{% forline internal in ${variableInstance.data.variable.type}_${variableInstance.data.variable.id} %}`,
      });
    } else {
      const displayBloc = blocValue;
      updateDocument({
        ...document,
        fireChangeToBloc: displayBloc,
        statement: `{% for internal in ${variableInstance.data.variable.type}_${variableInstance.data.variable.id} %}\v`,
      });
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(setRepetitionValue());
    toast.success("La répétition a été copiée dans le presse-papier.");
  };

  const insertMultiObjectsConditions = () => {
    var conditionsText = "";
    variableInstance.data.variable.objects.forEach((objectType) => {
      conditionsText +=
        '{% if internal["__object_name__"] == "' +
        objectType[0].label +
        '" %}\n\n{% endif %}\n';
    });
    setBlocValue(
      blocValue.slice(0, select.cursor) +
        conditionsText +
        blocValue.slice(select.cursor, blocValue.length)
    );
    setPreview();
  };

  function setRepetitionValue() {
    if (variableInstance !== null) {
      if (
        variableInstance.data.variable !== null &&
        variableInstance.data.variable !== undefined
      ) {
        let repetition = `{% for internal in ${variableInstance.data.variable.type}_${variableInstance.data.variable.id} %}\n`;
        repetition += `${blocValue}\n`;
        repetition += "{% endfor %}";
        return repetition;
      }
    }
  }

  function setPreview() {
    updatePreview(setRepetitionValue());
  }

  React.useEffect(() => {
    setPreview();
  }, [blocValue, document, variableInstance]);
  return (
    <div>
      <Grid container className={classes.container}>
        {isFromWordIntegration && (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.variable}
          >
            <div className={classes.preview}>
              <span className={classes.previewTitle}>Preview : </span>
              <div className={classes.previewValue}>
                {variableInstance?.data?.variable !== null ? preview : ""}
              </div>
            </div>
          </Grid>
        )}
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.variable}>
          <div className={classes.desc}>
            Sélectionner la variable à répéter :
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.variable}>
          <FormControl
            style={{ backgroundColor: "white", flexGrow: 1, width: "100%" }}
            variant="outlined"
            size="small"
          >
            <InputLabel></InputLabel>
            <Autocomplete
              size="small"
              value={variableInstance?.data?.variable ?? null}
              onChange={(e, v) => {
                const clonedVariable = JSON.parse(JSON.stringify(v));
                if (clonedVariable.type == "multiarray") {
                  clonedVariable.objects = clonedVariable.options;
                  clonedVariable.options = [];
                  v.options.forEach(function (objectDefinition) {
                    const objectName = objectDefinition[0].label;
                    for (var i = 1; i < objectDefinition.length; ++i) {
                      clonedVariable.options.push({
                        id: objectDefinition[i].id,
                        type: objectDefinition[i].type,
                        label: objectName + " / " + objectDefinition[i].label,
                      });
                    }
                  });
                }
                setvariableInstance({
                  ...variableInstance,
                  data: { variable: clonedVariable },
                });
                setPreview();
              }}
              options={options
                .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
                .sort((a, b) => -b.groupName.localeCompare(a.groupName))}
              groupBy={(option) => option.groupName}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option) => option.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choisir variable"
                  variant="outlined"
                />
              )}
              renderOption={(option) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BadgeLabel type={option.type} />
                  <span style={{ marginLeft: 15 }}>
                    <b>#{option.id}</b> {option.name}
                  </span>
                </div>
              )}
            />
          </FormControl>
        </Grid>
        <Collapse in={variableInstance?.data?.variable ? true : false}>
          <Grid container spacing={2}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.variable}
              style={{ marginTop: 20 }}
            >
              <span className={classes.desc}>
                Veuillez insérer les variables à l'interieur du bloc de texte à
                l'aide du bouton dédié
              </span>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "5px 0",
                padding: "0 10px",
              }}
            >
              <Button
                disabled={select.bloc === "newtitle" || select.bloc === "title"}
                variant="contained"
                color="primary"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                style={{
                  padding: "10px 10px",
                  margin: "10px 10px",
                }}
              >
                Insérer une variable au curseur
              </Button>
              <Button
                disabled={
                  select.bloc === "newtitle" ||
                  select.bloc === "title" ||
                  variableInstance?.data?.variable?.type != "multiarray" ||
                  showArrayFormat
                }
                variant="contained"
                color="primary"
                onClick={(event) => insertMultiObjectsConditions()}
                style={{
                  padding: "10px 10px",
                  margin: "10px 10px",
                }}
              >
                Insérer les conditions des objets
              </Button>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Switch
                  checked={showArrayFormat}
                  onChange={() => {
                    setshowArrayFormat(!showArrayFormat);
                    setPreview();
                  }}
                  color="secondary"
                />
                <span> Afficher sous forme de tableau</span>
              </div>
            </Grid>

            {showArrayFormat ? (
              <PrintTable
                variableInstance={variableInstance}
                setvariableInstance={setvariableInstance}
                setSelect={setSelect}
              />
            ) : (
              <Grid id="repetitionDiv" item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  variant="outlined"
                  multiline
                  rows={6}
                  fullWidth
                  style={{ backgroundColor: "white", caretColor: "red" }}
                  value={blocValue}
                  onChange={(e) => {
                    if (e.nativeEvent.inputType === "deleteContentBackward") {
                      setSelect({ ...select, cursor: select.cursor - 1 });
                    } else {
                      setSelect({ ...select, cursor: select.cursor + 1 });
                    }
                    setBlocValue(e.target.value);
                    setPreview();
                  }}
                  onMouseUp={(e) => {
                    setSelect({
                      cursor:
                        e.target.selectionDirection === "backward"
                          ? e.target.selectionStart
                          : e.target.selectionEnd,
                    });
                  }}
                  placeholder="Insérer le texte qui sera répété autant de fois que le nombre de données dans la variable"
                />
              </Grid>
            )}
          </Grid>
        </Collapse>

        <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: 10 }}>
          <SaveButton
            onCopy={onCopy}
            showCopy={true}
            onSave={onSave}
            isFromWordIntegration={isFromWordIntegration}
            copyButtonDisabled={showArrayFormat}
            disabled={
              variableInstance?.data?.variable === null ||
              (showArrayFormat && variableInstance?.columns?.length === 0)
            }
            setOpen={setOpen}
          />
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {variableInstance?.data?.variable?.options &&
          variableInstance?.data?.variable?.options.map((el, key) => (
            <MenuItem
              key={key}
              onClick={() => {
                if (select?.cursor) {
                  if (showArrayFormat) {
                    setAnchorEl(null);
                    let result = { ...variableInstance };
                    if (select.bloc === "body") {
                      result.columns[select.index][select.bloc] =
                        result.columns[select.index][select.bloc].slice(
                          0,
                          select.cursor
                        ) +
                        `{{internal["${
                          variableInstance.data.variable.options.find(
                            (elem) => elem.label === el.label
                          ).id
                        }"]}}` +
                        result.columns[select.index][select.bloc].slice(
                          select.cursor,
                          result.columns[select.index][select.bloc].length
                        );
                      setvariableInstance(result);
                    }
                  } else {
                    setAnchorEl(null);
                    setBlocValue(
                      blocValue.slice(0, select.cursor) +
                        `{{internal["${
                          variableInstance.data.variable.options.find(
                            (elem) => elem.label === el.label
                          ).id
                        }"]}}` +
                        blocValue.slice(select.cursor, blocValue.length)
                    );
                    setPreview();
                  }
                } else {
                  if (showArrayFormat) {
                    setAnchorEl(null);
                    let result = { ...variableInstance };
                    if (select.bloc === "body") {
                      result.columns[select.index][select.bloc] =
                        result.columns[select.index][select.bloc] +
                        `{{internal["${
                          variableInstance.data.variable.options.find(
                            (elem) => elem.label === el.label
                          ).id
                        }"]}}`;
                      setvariableInstance(result);
                    }
                  } else {
                    if (blocValue == undefined) {
                      setBlocValue(
                        `{{internal["${
                          variableInstance.data.variable.options.find(
                            (elem) => elem.label === el.label
                          ).id
                        }"]}}`
                      );

                      setPreview();
                    } else {
                      setBlocValue(
                        blocValue +
                          `{{internal["${
                            variableInstance.data.variable.options.find(
                              (elem) => elem.label === el.label
                            ).id
                          }"]}}`
                      );

                      setPreview();
                    }
                    setAnchorEl(null);
                  }
                }
              }}
            >
              {getNameType(el.type)}{" "}
              <span style={{ marginLeft: 5, marginRight: 5 }}>{el.label} </span>{" "}
              <i style={{ color: "silver", marginLeft: 5 }}>( {el.id} ) </i>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};
