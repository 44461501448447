import React from "react";
import { toast } from "react-toastify";

const config = {
    basename: "/",
    link: {
        terms_and_conditions_of_use: "https://www.comeonlaw.com/mentions-legales",
        data_privacy_policy: "https://www.comeonlaw.com/mentions-legales",
        contact: "mailto:info@comeonlaw.com",
    },
    axios: {
        baseURL: "https://oauth.dev.magic-lemp.com/api/",
    },
    regex: {
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    TVA: 0.2,
    defaultColors: {
        project: "52, 122, 235",
        project_shared: "14, 73, 171",
        section: "62, 189, 8",
        task: "255, 25, 25",
        taskOption: "232, 127, 7",
        selected: "255, 230, 0",
    },
    handleErrorBackend: ({ response, message = "" }) => {
        const status = response.response.status;
        if (status >= 500) {
            const first_part = response.response.data.split('<pre class="exception_value">')[1];
            const second_part = first_part.split("</pre>")[0];
            toast.error(
                <div>
                    Erreur serveur {message} <br /> {second_part}{" "}
                </div>
            );
        } else if (status >= 400 && status < 500) {
            toast.error(`Erreur ${message}:` + JSON.stringify(response.response.data).replaceAll(/[}{]/gi, " ").replaceAll("[", " ").replaceAll("]", " "));
        }
    },
};

export default config;
