const physicalPerson = {
  name: "Personne physique",
  fields: [
    "Civilité",
    "Prénom",
    "Nom",
    "Date de naissance",
    "Ville de naissance",
    "Nationalité",
    "Adresse",
    "Profession",
    "Numéro de sécurité sociale",
  ],
};

export { physicalPerson };
