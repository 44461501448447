export default class OauthPermission {
  static StandardUser = 'standard_user';

  static AdminUser = 'backoffice_user';

  static Guest = 'guest';

  static UnAuthorized = 'un_authorized';

  static mPermissionsRank = {
    [OauthPermission.UnAuthorized]: -1,
    [OauthPermission.Guest]: 0,
    [OauthPermission.StandardUser]: 1,
    [OauthPermission.AdminUser]: 2,
  };

  static comparePermission(firstPerm, secondPerm) {
    if (firstPerm == null) return secondPerm;
    if (secondPerm == null) return firstPerm;

    if (
      OauthPermission.mPermissionsRank[firstPerm] > OauthPermission.mPermissionsRank[secondPerm]
    ) {
      return firstPerm;
    }

    return secondPerm;
  }
}
