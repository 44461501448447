import ActiveEditorContext from "../../../../providers/ActiveEditorContext";
import TreeContext from "../../../../providers/TreeContext";
import TemplateLib from "@common/libs/template-lib";
import { Grid, Select, InputLabel, MenuItem, Chip, FormControl, ListSubheader, Checkbox, CircularProgress, Paper } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import TemplateLibInterviews from "~/common/libs/interview-template-lib";

export default function Signature(props) {
    const { _formData, set_formData } = props;
    const { activeEditor } = useContext(ActiveEditorContext);

    const { tree } = useContext(TreeContext);

    const [documentTaskList, setdocumentTaskList] = useState(null);
    const [uploadTaskList, setuploadTaskList] = useState(null);

    useEffect(() => {
        let listTasksUpload = [];
        const listSections = tree.projects[activeEditor.root_key[0]].sections;
        Promise.all(listSections.map(el => TemplateLib.getListTaskOfCategories(el.id))).then(res => {
            listTasksUpload = res.reduce((a, b) => a.concat(b), []).filter(el => el.actions[0]?.type === "upload");
            TemplateLibInterviews.getDocumentsFromProject(activeEditor.root[0].id).then(documents => {
                const listUpload = listTasksUpload;
                const listDocs = documents.filter(el => el.task !== undefined && el.task !== null);
                _formData.actions[0].data.document_templates = _formData.actions[0].data.document_templates.filter(el => listDocs.find(e => e.id === el));
                _formData.actions[0].data.upload_tasks = _formData.actions[0].data.upload_tasks.filter(el => listUpload.find(e => e.id === el));
                setuploadTaskList(listUpload);
                setdocumentTaskList(listDocs);
            });
        });
    }, []);

    useEffect(() => {
        if (!_formData.actions[0].data.document_templates || !_formData.actions[0].data.upload_tasks) {
            let result = { ..._formData };
            result.actions[0].data.document_templates = [];
            result.actions[0].data.upload_tasks = [];
            set_formData(result);
        }
    }, []);

    if (documentTaskList === null || uploadTaskList === null)
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                <CircularProgress size={20} />
            </div>
        );
    else if (documentTaskList.length < 1 && uploadTaskList.length < 1)
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                <h4 className="description" style={{ marginTop: "0px" }}>
                    Aucun documents trouvés dans le projet.
                    <br />
                    Veuillez ajouter une tâche questionnaire.
                </h4>
            </div>
        );
    else
        return (
            <Paper style={{ padding: "10px" }}>
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <FormControl variant="outlined" fullWidth style={{ backgroundColor: "white" }} size="small">
                            <InputLabel shrink>Documents sélectionnés</InputLabel>
                            <Select
                                multiple
                                value={_formData.actions[0].data.document_templates && _formData.actions[0].data.upload_tasks ? _formData.actions[0].data.document_templates.concat(_formData.actions[0].data.upload_tasks) : []}
                                renderValue={selected => (
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {selected.map((value, key) => (
                                            <Chip
                                                variant="outlined"
                                                size="small"
                                                style={{ borderRadius: "5px", marginRight: "5px", marginTop: "5px", padding: "0px" }}
                                                color="primary"
                                                key={key}
                                                label={documentTaskList.find(el => el.id === value) ? documentTaskList.find(el => el.id === value).name : uploadTaskList.find(el => el.id === value) ? uploadTaskList.find(el => el.id === value).name : "Erreur"}
                                            />
                                        ))}
                                    </div>
                                )}
                                onChange={(e, v) => {}}
                                label="Documents sélectionnés">
                                <div>
                                    <ListSubheader style={{ backgroundColor: "white" }}>Documents générés</ListSubheader>
                                    {documentTaskList.map((task, key) => (
                                        <MenuItem
                                            key={key}
                                            onClick={() => {
                                                if (_formData.actions[0].data.document_templates.findIndex(el => el === task.id) > -1) {
                                                    let result = { ..._formData };
                                                    result.actions[0].data.document_templates = result.actions[0].data.document_templates.filter(el => el !== task.id);
                                                    set_formData(result);
                                                } else {
                                                    let result = { ..._formData };
                                                    result.actions[0].data.document_templates.push(task.id);
                                                    set_formData(result);
                                                }
                                            }}>
                                            <Checkbox checked={_formData.actions[0].data.document_templates !== undefined && _formData.actions[0].data.document_templates.findIndex(el => el === task.id) > -1} />

                                            {task.name}
                                        </MenuItem>
                                    ))}
                                </div>
                                <div>
                                    <ListSubheader style={{ backgroundColor: "white" }}> Tâches d'upload</ListSubheader>
                                    {uploadTaskList.map((task, key) => (
                                        <MenuItem
                                            key={key}
                                            onClick={() => {
                                                if (_formData.actions[0].data.upload_tasks.findIndex(el => el === task.id) > -1) {
                                                    let result = { ..._formData };
                                                    result.actions[0].data.upload_tasks = result.actions[0].data.upload_tasks.filter(el => el !== task.id);
                                                    set_formData(result);
                                                } else {
                                                    let result = { ..._formData };
                                                    result.actions[0].data.upload_tasks.push(task.id);
                                                    set_formData(result);
                                                }
                                            }}>
                                            <Checkbox checked={_formData.actions[0].data.upload_tasks !== undefined && _formData.actions[0].data.upload_tasks.findIndex(el => el === task.id) > -1} />

                                            {task.name}
                                        </MenuItem>
                                    ))}
                                </div>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
        );
}
