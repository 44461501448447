import DocumentContext from "../../../providers/DocumentContext"
import TemplateLib from "@common/libs/interview-template-lib"
import {
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import React, { useContext } from "react"
import SaveIcon from "@material-ui/icons/Save"
import EditSharp from "@material-ui/icons/EditSharp"
import { toast } from "react-toastify"
const useStyles = makeStyles(({ palette }) => ({
  button: {
    margin: "10px 0",
    color: "white",
    backgroundColor: palette.cta.dark,
    width: "80%",
    display: "flex",
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: palette.cta.main,
    },
    "&:disabled": {
      backgroundColor: "silver",
    },
  },
}))

export default function SaveButtons({ tabIndex }) {
  const classes = useStyles()
  const { document, updateDocument } = useContext(DocumentContext)

  const [loading, setloading] = React.useState(false)

  const saveDocument = () => {
    TemplateLib.updateDocument(document.id, document).then((res) => {
      updateDocument({ ...document, launchSave: res.id })
    })
  }
  const renameDocument = () => {
    TemplateLib.updateDocument(document.id, document).then((res) => {
      toast.success("Le document a été renommé")
    })
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <FormControl
        fullWidth
        label="Titre du document"
        size="small"
        style={{ backgroundColor: "white" }}
      >
        <OutlinedInput
          style={{ fontSize: 14 }}
          value={document.name}
          onChange={(e) =>
            updateDocument({ ...document, name: e.target.value })
          }
          endAdornment={<InputAdornment position="end"></InputAdornment>}
        />
      </FormControl>
      <Button
        className={classes.button}
        disabled={loading || document.name === ""}
        fullWidth
        onClick={renameDocument}
      >
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <EditSharp style={{ marginRight: 15 }} />{" "}
            <span>Renommer le document</span>
          </>
        )}
      </Button>
    </div>
  )
}
