import DocumentContext from "../../../../../providers/DocumentContext";
import SaveButton from "../SaveButton";
import BlocCondition from "./BlocCondition";
import { Button } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    width: "100%",
    fontSize: 14,
    borderBottom: " 1px solid silver",
    backgroundColor: "#f93b3b",
    color: "white",
    fontWeight: "bold",
    padding: "10px 20px",
  },
  desc: {
    color: "#555",
    fontSize: 14,
    width: "100%",
    textAlign: "center",
    paddingTop: "10px",
  },
  variable: {
    display: "flex",
    // justifyContent:'center'
    alignItems: "center",
  },
}));

export default ({
  variableInstance,
  setvariableInstance,
  setOpen,
  isFromWordIntegration,
}) => {
  const classes = useStyles();
  const { document, updateDocument } = useContext(DocumentContext);
  const [preview, updatePreview] = useState(null);
  const options = document.variables
    .filter(
      (el) =>
        el.type === "bool" ||
        el.type === "choice" ||
        el.type === "multi" ||
        el.type === "list"
    )
    .map((option) => {
      const firstLetter = option.name[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        groupName: document.groupsVariables.find((el) => el.id === option.group)
          ? document.groupsVariables.find((el) => el.id === option.group).name
          : "Aucun groupe",
        ...option,
      };
    });

  React.useEffect(() => {
    setvariableInstance({
      type: "condition",
      bloc: document.selection,
      data: [[{ variable: null, option: "", id: 0 }]],
    });
  }, [document.edit.type, setvariableInstance]);

  const addBlocCondition = () => {
    let result = { ...variableInstance };
    result.data = [...result.data, [{ variable: null, option: "" }]];
    setvariableInstance(result);
  };

  const getCondition = () => {
    if (Array.isArray(variableInstance.data)) {
      let result = "{% if ";
      variableInstance.data.forEach((el, index) => {
        result += "";
        el.forEach((elem, i) => {
          if (elem.variable.type === "list")
            result += `"${elem.option}"  in ${elem.variable.type}_${elem.variable.id}`;
          else if (elem.variable.type === "multi")
            result += `"${elem.option}" in ${elem.variable.type}_${elem.variable.id}`;
          else if (elem.variable.type === "choice")
            result += `${elem.variable.type}_${elem.variable.id} == "${elem.option}"`;
          else if (elem.variable.type === "bool")
            result += `${elem.variable.type}_${elem.variable.id} == ${
              elem.option ? 1 : 0
            }`;
          if (i + 1 !== el.length) result += " and ";
        });
        result += "";
        if (index + 1 !== variableInstance.data.length) result += " or ";
      });
      result += " %}\v";
      return result;
    }
  };

  const onSave = () => {
    const result = getCondition();
    updateDocument({
      ...document,
      fireChangeToBloc: document.selection,
      statement: result,
    });
  };

  const onCopy = () => {
    let result = getCondition().slice(0, -1);
    result += " {% endif %}";
    navigator.clipboard.writeText(result);
    toast.success("La condition a été copiée dans le presse-papier.");
  };

  useEffect(() => {
    setPreview();
  }, [variableInstance]);

  const setPreview = () => {
    if (variableInstance) {
      if (Array.isArray(variableInstance.data)) {
        if (variableInstance !== null) {
          if (
            variableInstance?.data?.[0]?.[0]?.variable !== null ||
            variableInstance?.data?.[0]?.[0]?.option !== ""
          ) {
            let result = getCondition().slice(0, -1);
            result += " {% endif %}";
            updatePreview(result);
          }
        }
      }
    }
  };

  return (
    <div>
      {isFromWordIntegration && (
        <div>
          <span style={{ fontWeight: "bold" }}>Preview : </span> {preview}
        </div>
      )}
      <div className={classes.desc}>
        Afficher la selection seulement si un des blocs de condition est rempli
        :
      </div>
      <div style={{ padding: "0 10px" }}>
        {variableInstance?.data?.length &&
          variableInstance?.data?.map((el, key) => (
            <BlocCondition
              key={key}
              indexBloc={key}
              options={options}
              bloc={el}
              variableInstance={variableInstance}
              isFromWordIntegration={isFromWordIntegration}
              setvariableInstance={setvariableInstance}
            />
          ))}
      </div>
      {!isFromWordIntegration && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 40,
            marginTop: 20,
          }}
        >
          <Button
            size="small"
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px dashed #5469d4",
              color: "#5469d4",
            }}
            onClick={addBlocCondition}
          >
            <AddCircleOutlineIcon style={{ fontSize: 14, marginRight: 10 }} />
            <span> Nouveau bloc logique</span>
          </Button>
        </div>
      )}
      <SaveButton
        onSave={onSave}
        setOpen={setOpen}
        isFromWordIntegration={isFromWordIntegration}
        disabled={
          variableInstance?.data?.[0]?.[0]?.variable === null ||
          variableInstance?.data?.[0]?.[0]?.option === ""
        }
        showCopy={true}
        onCopy={onCopy}
      />
    </div>
  );
};
