import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListSubheader,
} from "@material-ui/core"
import React from "react"

export default ({
  variableDialog,
  setvariableDialog,
  type,
  variableFieldName,
  variableFieldKey,
  objectNumber,
}) => {
  function getMapping(variableDialog, variableFieldName) {
    try {
      // console.log("getMapping", variableDialog);
      let fieldMappings =
        variableDialog.database_object_model_fields_mapping[objectNumber].data

      if (fieldMappings == undefined) {
        variableDialog.database_object_model_fields_mapping[objectNumber].data =
          []
        fieldMappings = []
      }

      // console.log(fieldMappings);

      let fieldMapping = fieldMappings.find(
        (fm) => fm.variable_field_name == variableFieldName
      )

      return fieldMapping.database_object_model_field_name
    } catch (error) {
      console.log(error)
      return ""
    }
  }

  return (
    // <TextField
    //   variant="outlined"
    //   label="Mapping dossiers permanents"
    //   type={type}
    //   // variableFieldName={variableFieldName}
    //   // variableFieldKey={variableFieldKey}
    //   // objectNumber={objectNumber}
    //   style={{
    //     backgroundColor: "white",
    //     width: "100%",
    //     maxWidth: 300,
    //   }}
    //   size="small"
    //   value={getMapping(variableDialog, variableFieldName)}
    //   onChange={(e) => {
    //     // console.log("mapping onChange", e);
    //     let result = { ...variableDialog };
    //     // console.log(result);
    //     let fieldMappings =
    //       variableDialog.database_object_model_fields_mapping[objectNumber]
    //         .data;
    //     let fieldMapping = fieldMappings.find(
    //       (fm) => fm.variable_field_name == variableFieldName
    //     );
    //     if (fieldMapping === undefined) {
    //       fieldMapping = {
    //         variable_field_name: variableFieldName,
    //         database_object_model_field_name: "",
    //       };
    //       fieldMappings.push(fieldMapping);
    //     }
    //     fieldMapping.database_object_model_field_name = e.target.value;
    //     setvariableDialog(result);
    //   }}
    // />
    <FormControl
      size="small"
      variant="outlined"
      style={{
        backgroundColor: "white",
        width: "100%",
        maxWidth: 300,
      }}
    >
      <InputLabel>Mapping dossiers permanents</InputLabel>
      <Select
        type={type}
        style={{
          backgroundColor: "white",
          width: "100%",
          maxWidth: 300,
        }}
        size="small"
        label="Mapping dossiers permanents"
        value={getMapping(variableDialog, variableFieldName)}
        onChange={(e) => {
          // console.log("mapping onChange", e);
          let result = { ...variableDialog }
          // console.log(result);
          let fieldMappings =
            variableDialog.database_object_model_fields_mapping[objectNumber]
              .data
          let fieldMapping = fieldMappings.find(
            (fm) => fm.variable_field_name == variableFieldName
          )
          if (fieldMapping === undefined) {
            fieldMapping = {
              variable_field_name: variableFieldName,
              database_object_model_field_name: "",
            }
            fieldMappings.push(fieldMapping)
          }
          fieldMapping.database_object_model_field_name = e.target.value
          setvariableDialog(result)
        }}
      >
        <div>
          <ListSubheader>Personne physique</ListSubheader>
        </div>
        <MenuItem value="Civilité">Civilité</MenuItem>
        <MenuItem value="Prénom">Prénom</MenuItem>
        <MenuItem key="PP/Nom" value="Nom">
          Nom
        </MenuItem>
        <MenuItem key="PP/Date de naissance" value="Date de naissance">
          Date de naissance
        </MenuItem>
        <MenuItem key="PP/Ville de naissance" value="Ville de naissance">
          Ville de naissance
        </MenuItem>
        <MenuItem key="PP/Nationalité" value="Nationalité">
          Nationalité
        </MenuItem>
        <MenuItem key="PP/Adresse" value="Adresse">
          Adresse
        </MenuItem>
        <MenuItem key="PP/Adresse/Numéro et rue" value="Adresse/Numéro et rue">
          Adresse/Numéro et rue
        </MenuItem>
        <MenuItem key="PP/Adresse/Code postal" value="Adresse/Code postal">
          Adresse/Code postal
        </MenuItem>
        <MenuItem key="PP/Adresse/Ville" value="Adresse/Ville">
          Adresse/Ville
        </MenuItem>
        <MenuItem key="PP/Profession" value="Profession">
          Profession
        </MenuItem>
        <MenuItem
          key="PP/Numéro de sécurité sociale"
          value="Numéro de sécurité sociale"
        >
          Numéro de sécurité sociale
        </MenuItem>

        <div>
          <ListSubheader>Personne morale</ListSubheader>
        </div>
        <MenuItem keey="PM/Nom" value="Nom">
          Nom
        </MenuItem>
        <MenuItem value="Forme juridique">Forme juridique</MenuItem>
        <MenuItem value="Capital social">Capital social</MenuItem>
        <MenuItem value="Date de création">Date de création</MenuItem>
        <MenuItem value="Adresse du siège social">
          Adresse du siège social
        </MenuItem>
        <MenuItem key="PM/Adresse/Numéro et rue" value="Adresse/Numéro et rue">
          Adresse/Numéro et rue
        </MenuItem>
        <MenuItem key="PM/Adresse/Code postal" value="Adresse/Code postal">
          Adresse/Code postal
        </MenuItem>
        <MenuItem key="PM/Adresse/Ville" value="Adresse/Ville">
          Adresse/Ville
        </MenuItem>
        <MenuItem value="Numéro d'immatriculation">
          Numéro d'immatriculation
        </MenuItem>
        <MenuItem value="Ville d'immatriculation">
          Ville d'immatriculation
        </MenuItem>
        <MenuItem value="Numéro FINESS">Numéro FINESS</MenuItem>
        <MenuItem value="Numéro RNA">Numéro RNA</MenuItem>
        <MenuItem value="Représentant légal/Label">
          Représentant légal/Label
        </MenuItem>
        <MenuItem value="Représentant légal/Prénom">
          Représentant légal/Prénom
        </MenuItem>
        <MenuItem value="Représentant légal/Nom">
          Représentant légal/Nom
        </MenuItem>
        <MenuItem value="Commissaires aux comptes/Commissaire aux comptes/Label">
          Commissaires aux comptes/Commissaire aux comptes/Label
        </MenuItem>
        <MenuItem value="Commissaires aux comptes/Commissaire aux comptes/Position">
          Commissaires aux comptes/Commissaire aux comptes/Position
        </MenuItem>
        <MenuItem value="Commissaires aux comptes/Commissaire aux comptes/Date de début du mandat">
          Commissaires aux comptes/Commissaire aux comptes/Date de début du
          mandat
        </MenuItem>
        <MenuItem value="Commissaires aux comptes/Commissaire aux comptes/Date de fin du mandat">
          Commissaires aux comptes/Commissaire aux comptes/Date de fin du mandat
        </MenuItem>
        <MenuItem value="Bilans/Date de début d'exercice">
          Bilans/Date de début d'exercice
        </MenuItem>
        <MenuItem value="Bilans/Date de fin d'exercice">
          Bilans/Date de fin d'exercice
        </MenuItem>
        <MenuItem value="Bilans/Chiffre d'affaires">
          Bilans/Chiffre d'affaires
        </MenuItem>
        <MenuItem value="Bilans/Produit d'exploitation">
          Bilans/Produit d'exploitation
        </MenuItem>
        <MenuItem value="Bilans/Charges d'exploitation">
          Bilans/Charges d'exploitation
        </MenuItem>
        <MenuItem value="Bilans/Résultat d'exploitation">
          Bilans/Résultat d'exploitation
        </MenuItem>
        <MenuItem value="Bilans/Total bilan">Bilans/Total bilan</MenuItem>
        <MenuItem value="Bilans/Benéfices">Bilans/Benéfices</MenuItem>
        <MenuItem value="Bilans/Pertes">Bilans/Pertes</MenuItem>
        <MenuItem value="Bilans/Actif net immobilisé">
          Bilans/Actif net immobilisé
        </MenuItem>
        <MenuItem value="Bilans/Actif circulant">
          Bilans/Actif circulant
        </MenuItem>
        <MenuItem value="Bilans/Capitaux propres">
          Bilans/Capitaux propres
        </MenuItem>
        <MenuItem value="Bilans/Emprunts et dettes">
          Bilans/Emprunts et dettes
        </MenuItem>
        <MenuItem value="Bilans/Traitements et salaires">
          Bilans/Traitements et salaires
        </MenuItem>
        <MenuItem value="Bilans/Charges sociales">
          Bilans/Charges sociales
        </MenuItem>
        <MenuItem value="Bilans/Nombre de salariés">
          Bilans/Nombre de salariés
        </MenuItem>
        <MenuItem value="Bilans/Résultat financier">
          Bilans/Résultat financier
        </MenuItem>
        <MenuItem value="Bilans/Résultat courant">
          Bilans/Résultat courant
        </MenuItem>
        <MenuItem value="Bilans/Résultat exceptionnel">
          Bilans/Résultat exceptionnel
        </MenuItem>
        <MenuItem value="Bilans/Impôt sur les bénéfices">
          Bilans/Impôt sur les bénéfices
        </MenuItem>

        {/* <MenuItem value="Comptes de résultat/Date de début d'exercice">
          Bilans/Date de début d'exercice
        </MenuItem>
        <MenuItem value="Comptes de résultat/Date de fin d'exercice">
          Bilans/Date de fin d'exercice
        </MenuItem>
        <MenuItem value="Comptes de résultat/Chiffre d'affaires">
          Comptes de résultat/Chiffre d'affaires
        </MenuItem>
        <MenuItem value="Comptes de résultat/Produit d'exploitation">
          Comptes de résultat/Produit d'exploitation
        </MenuItem>
        <MenuItem value="Comptes de résultat/Charges d'exploitation">
          Comptes de résultat/Charges d'exploitation"
        </MenuItem> */}

        {/* <MenuItem value=""></MenuItem> */}

        <ListSubheader>Tribunal</ListSubheader>
        <MenuItem key="T/Nom" value="Nom">
          Nom
        </MenuItem>
        <MenuItem value="T/Adresse">Adresse</MenuItem>
        <MenuItem key="T/Adresse/Numéro et rue" value="Adresse/Numéro et rue">
          Adresse/Numéro et rue
        </MenuItem>
        <MenuItem key="T/Adresse/Code postal" value="Adresse/Code postal">
          Adresse/Code postal
        </MenuItem>
        <MenuItem key="T/Adresse/Ville" value="Adresse/Ville">
          Adresse/Ville
        </MenuItem>
        <ListSubheader>CPAM</ListSubheader>
        <MenuItem key="C/Nom" value="Nom">
          Nom
        </MenuItem>
        <MenuItem key="C/Adresse" value="Adresse">
          Adresse
        </MenuItem>
        <MenuItem key="C/Adresse/Numéro et rue" value="Adresse/Numéro et rue">
          Adresse/Numéro et rue
        </MenuItem>
        <MenuItem key="C/Adresse/Code postal" value="Adresse/Code postal">
          Adresse/Code postal
        </MenuItem>
        <MenuItem key="C/Adresse/Ville" value="Adresse/Ville">
          Adresse/Ville
        </MenuItem>
      </Select>
    </FormControl>
  )
}
