import StoreDataContext from "../../../providers/StoreDataContext";
import { CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useContext } from "react";
import FiltersContext from "~/providers/FiltersContext";

export default function CategoriesFilter() {
    const { storeData } = useContext(StoreDataContext);
    const { filtersData, updateFiltersData } = useContext(FiltersContext);

    return storeData.subdomains ? (
        <Autocomplete
            options={storeData.subdomains
                .filter(el => (filtersData.speciality === 1 ? el : el.specialty_id === filtersData.speciality))
                .sort(function (a, b) {
                    return a.specialty_id - b.specialty_id;
                })}
            getOptionSelected={(option, value) => {
                return option.id === value.id;
            }}
            groupBy={option => storeData.specialities[storeData.specialities.findIndex(el => option.specialty_id === el.id)].name}
            getOptionLabel={option => option.name}
            value={filtersData.categories}
            onChange={(e, v) => updateFiltersData(filtersData => ({ ...filtersData, categories: v }))}
            renderInput={params => <TextField fullWidth {...params} variant="outlined" label="Rechercher par sous-spécialité" placeholder="Rechercher" style={{ backgroundColor: "white" }} />}
        />
    ) : (
        <CircularProgress size={20} />
    );
}
