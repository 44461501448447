import ActiveEditorContext from "../../../providers/ActiveEditorContext";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import config from "~/config";

const useStyles = makeStyles({
    container: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
    project: {
        fontSize: 14,
        borderRadius: "5px",
        padding: "3px 7px",
        marginLeft: "5px",
        backgroundColor: `rgba(${localStorage.getItem("color-project") ? localStorage.getItem("color-project") : config.defaultColors.project},0.2)`,
        color: `rgb(${localStorage.getItem("color-project") ? localStorage.getItem("color-project") : config.defaultColors.project})`,
        fontWeight: "bold",
    },
    project_shared: {
        fontSize: 14,
        borderRadius: "5px",
        padding: "3px 7px",
        marginLeft: "5px",
        backgroundColor: `rgba(${localStorage.getItem("color-project-shared") ? localStorage.getItem("color-project-shared") : config.defaultColors.project_shared},0.2)`,
        color: `rgb(${localStorage.getItem("color-project-shared") ? localStorage.getItem("color-project-shared") : config.defaultColors.project_shared})`,
        fontWeight: "bold",
    },
    section: {
        fontSize: 14,
        borderRadius: "5px",
        padding: "3px 7px",
        marginLeft: "5px",
        backgroundColor: `rgba(${localStorage.getItem("color-section") ? localStorage.getItem("color-section") : config.defaultColors.section},0.2)`,
        color: `rgb(${localStorage.getItem("color-section") ? localStorage.getItem("color-section") : config.defaultColors.section})`,
    },
    task: {
        fontSize: 14,
        borderRadius: "5px",
        padding: "3px 7px",
        marginLeft: "5px",
        backgroundColor: `rgba(${localStorage.getItem("color-task") ? localStorage.getItem("color-task") : config.defaultColors.task},0.2)`,
        color: `rgb(${localStorage.getItem("color-task") ? localStorage.getItem("color-task") : config.defaultColors.task})`,
        fontStyle: "italic",
    },
});

export default function EditorRoot() {
    const classes = useStyles();

    const { activeEditor } = useContext(ActiveEditorContext);
    switch (activeEditor.type) {
        case null:
            return <h2>Editeur</h2>;
        case "project":
            return (
                <div className={classes.container}>
                    <span className={activeEditor.root[0].entities.length ? classes.project : classes.project_shared}>{activeEditor.root[0].name}</span>
                </div>
            );
        case "section":
            return (
                <div className={classes.container}>
                    <span className={activeEditor.root[0].entities.length ? classes.project : classes.project_shared}>{activeEditor.root[0].name + " ->"}</span>
                    <span className={classes.section}>{activeEditor.root[1].name}</span>
                </div>
            );
        case "task":
            return (
                <div className={classes.container}>
                    <span className={activeEditor.root[0].entities.length ? classes.project : classes.project_shared}>{activeEditor.root[0].name + " ->"}</span>
                    <span className={classes.section}>{activeEditor.root[1].name + " ->"}</span>
                    <span className={classes.task}>{activeEditor.root[2].name}</span>
                </div>
            );
        case "add_project":
            return (
                <div className={classes.container}>
                    <span>Nouveau modèle de projet</span>
                </div>
            );
        case "add_section":
            return (
                <div className={classes.container}>
                    <span>
                        Nouvelle section pour :<small className={activeEditor.root[0].entities.length ? classes.project : classes.project_shared}>{activeEditor.root[0].name}</small>
                    </span>
                </div>
            );
        case "add_task":
            return (
                <div className={classes.container}>
                    <span>
                        Nouvelle tâche pour :<small className={activeEditor.root[0].entities.length ? classes.project : classes.project_shared}>{activeEditor.root[0].name}</small>
                        <small className={classes.section}>{" -> " + activeEditor.root[1].name}</small>
                    </span>
                </div>
            );
        default:
            return "";
    }
}
