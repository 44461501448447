import DocumentContext from "../../../../providers/DocumentContext";
import { Paper } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";

const useStyles = makeStyles(() => ({
    title: {
        display: "flex",
        alignItems: "center",
        padding: "10px 20px",
        fontSize: 18,
        borderBottom: " 1px solid silver",
    },
    container: {
        // backgroundColor: "#dfdfdf",
        padding: " 10px 0",
    },
    editor: {
        overflowY: "scroll",

        width: "90%",
        margin: "0px 5%",
        backgroundColor: "white",
    },
}));

export default () => {
    const classes = useStyles();

    const { document, updateDocument } = useContext(DocumentContext);

    const getColor = () => {
        if (document.edit.type === null || document.selection === "" || document.selection === null) return "#dfdfdf";
        else if (document.edit.type === "variable") return "rgba(0, 219, 92, 0.2)";
        else if (document.edit.type === "condition") return "rgba(249, 59, 59,0.2)";
        else if (document.edit.type === "repetition") return "rgba(0, 184, 217, 0.2)";
    };

    return (
        <div id="selectionDiv">
            <Paper>
                <div className={classes.container} style={{ backgroundColor: getColor() }}>
                    <div className={classes.editor}>
                        <div style={{ backgroundColor: "white", padding: 10, height: 100, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ fontSize: 12, lineHeight: "14px", backgroundColor: "yellow" }}>{document.selection}</div>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    );
};
