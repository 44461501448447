import ApiService from '../services/api-service';
import GenericError from '../models/generic-error';
import RouteHelper from '../helpers/route-helper';
import User from '../models/user';

export default class SpecialtiesLib {
  static GetSpecialtiesPath = '/api/specialties/';

  static GetSpecialtyPath = '/api/specialties/{id}/';

  static UpdateUserSpecialtiesPath = '/api/employees/{id}/specialties/';

  static async getSpecialties() {
    let response;

    try {
      response = await ApiService.get(SpecialtiesLib.GetSpecialtiesPath);
    } catch (e) {
      throw new Error(GenericError.GenericErrorNotFound);
    }

    if (response.status === 200) {
      return response.data?.results;
    }

    throw new Error(GenericError.GenericError);
  }

  static async getSpecialty(id) {
    let response;

    try {
      response = await ApiService.get(
        RouteHelper.buildRoute(SpecialtiesLib.GetSpecialtyPath, { id }, null),
      );
    } catch (e) {
      throw new Error(GenericError.GenericErrorNotFound);
    }

    if (response.status === 200) {
      return response.data;
    }

    throw new Error(GenericError.GenericError);
  }

  static async update(idList) {
    const { id } = User.fromStorage();
    const path = SpecialtiesLib.UpdateUserSpecialtiesPath.replace('{id}', id);

    const response = await ApiService.put(
      path,
      idList.map((id) => ({ id })),
    );

    return response.data.map((e) => e.id);
  }

  static async updateUser(uid, idList) {
    const path = SpecialtiesLib.UpdateUserSpecialtiesPath.replace('{id}', uid);

    const response = await ApiService.put(
      path,
      idList.map((id) => ({ id })),
    );

    return response.data.map((e) => e.id);
  }
}
