import StoreDataContext from "../../../../../providers/StoreDataContext";
import { Grid, TextField, Checkbox, Chip, IconButton } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export default function Subdomains({ _formData, set_formData, _dataDialog, set_dataDialog, _openDialog, set_openDialog }) {
    const { storeData } = React.useContext(StoreDataContext);
    const [_textCategories, set_textCategories] = useState("");

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
                multiple
                size="small"
                onFocus={() => set_textCategories("")}
                disableCloseOnSelect
                getOptionSelected={(option, value) => {
                    return option.id === value.id;
                }}
                renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" style={{ borderRadius: "5px", marginRight: "5px", marginTop: "5px", padding: "0px", fontSize: "12px" }} color="primary" label={option.name} {...getTagProps({ index })} />)}
                options={storeData.subdomains
                    .filter(el => (_formData.projectspecialties.some(elem => elem === 1) || _formData.projectspecialties.length === 0 ? el : _formData.projectspecialties.findIndex(elem => el.specialty_id === elem) > -1))
                    .sort(function (a, b) {
                        return a.specialty_id - b.specialty_id;
                    })
                    .concat([{ name: _textCategories }])}
                groupBy={option => (option.specialty_id !== undefined ? storeData.specialities[storeData.specialities.findIndex(el => option.specialty_id === el.id)].name : "_________")}
                getOptionLabel={option => option.name}
                renderOption={(option, { selected }) => {
                    if (option.specialty_id !== undefined)
                        return (
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={_formData.subdomains.findIndex(el => el.id === option.id) > -1} />
                                    {option.name}
                                </div>
                                <IconButton
                                    className="edit"
                                    onClick={() => {
                                        set_dataDialog(option);
                                        set_openDialog(true);
                                    }}>
                                    <EditIcon className="edit" />
                                </IconButton>
                            </div>
                        );
                    else {
                        return (
                            <React.Fragment>
                                <b> + Ajouter {option.name !== "" ? option.name : "un nouvel élément"}</b>
                            </React.Fragment>
                        );
                    }
                }}
                value={_formData.subdomains}
                onChange={(e, v) => {
                    const edit_valid_1 = e.target.getAttribute("class") !== null ? e.target.getAttribute("class").indexOf("edit") > -1 : false;
                    const edit_valid_2 = e.target.getAttribute("d") !== null ? e.target.getAttribute("d").indexOf("M3 17.25V") > -1 : false;
                    if (edit_valid_1 || edit_valid_2) {
                    } else {
                        if (v.findIndex(el => el.specialty_id === undefined) === -1) {
                            let result = [...v];
                            result.pop();
                            if (result.some(elem => elem.id === v[v.length - 1].id)) set_formData({ ..._formData, subdomains: v.filter(elem => elem.id !== v[v.length - 1].id) });
                            else set_formData({ ..._formData, subdomains: v });
                        } else {
                            set_openDialog(true);
                            set_dataDialog({ name: _textCategories });
                        }
                    }
                }}
                renderInput={params => <TextField value={_textCategories} onChange={e => set_textCategories(e.target.value)} size="small" fullWidth {...params} variant="outlined" label="Sous-spécialité" placeholder="Rechercher" style={{ backgroundColor: "white" }} />}
            />
        </Grid>
    );
}
