import { Grid } from "@material-ui/core";
import React from "react";

export default function DraftPaiement(props) {
  const { products, tax_rate } = props._paymentForm;
  const getPrice = (arr) => {
    var total = 0;
    for (var i in arr) {
      total += +arr[i].amount;
    }
    return total;
  };

  return (
    <Grid container spacing={1}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <h3 style={{ margin: "0" }} className="description">
          Visualisation de la commande :
        </h3>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <hr style={{ margin: "0 0 20px 0" }} />
      </Grid>
      {/* PRODUIT  */}
      {products.map((elem, key) => (
        <Grid
          key={key}
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{elem.name !== "" ? elem.name : " . . . "}</span>
          <span style={{ display: "flex", justifyContent: "flex-end" }}>
            {elem.amount} €
          </span>
        </Grid>
      ))}

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <hr style={{ margin: "0" }} />
      </Grid>
      {/* TVA */}

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <span style={{ margin: "0" }}>Sous total</span>
        <span> {getPrice(products)} € </span>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <span style={{ margin: "0" }}>
          TVA ( {tax_rate === "" ? 0 : tax_rate}% )
        </span>
        <span> {(getPrice(products) * (tax_rate / 100)).toFixed(2)} € </span>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <hr style={{ margin: "0" }} />
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <b style={{ margin: "0" }}>Total</b>
        <b>
          {" "}
          {(
            +getPrice(products) +
            getPrice(products) * (tax_rate / 100)
          ).toFixed(2)}{" "}
          €{" "}
        </b>
      </Grid>
    </Grid>
  );
}
