////// React Core
import ActiveEditorContext from "../../../../providers/ActiveEditorContext";
import TreeContext from "../../../../providers/TreeContext";
import Button from "@material-ui/core/Button";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import React, { useContext } from "react";
import config from "~/config";

export default function SectionLine(props) {
  const { updateActiveEditor } = useContext(ActiveEditorContext);
  const { tree, updateTree } = useContext(TreeContext);

  const { nodeId, data, root, root_key, icon, index_to_display } = props;
  const valid =
    tree.selected === nodeId ||
    tree.selected.split("-")[0] + "-" + tree.selected.split("-")[1] === nodeId;
  return (
    <div
      className="clickable_line"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: "30px",
        transition: "0.3s",
        backgroundColor:
          tree.selected === nodeId
            ? `rgba(${
                localStorage.getItem("color-selected")
                  ? localStorage.getItem("color-selected")
                  : config.defaultColors.selected
              },0.6)`
            : "initial",
      }}
    >
      <span
        className="clickable_line"
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "20px",
          width: "75%",
          color: `rgb(${
            localStorage.getItem("color-section")
              ? localStorage.getItem("color-section")
              : config.defaultColors.section
          })`,

          padding: "5px 0",
        }}
        onClick={() =>
          updateActiveEditor({
            type: "section",
            data,
            root,
            root_key,
          })
        }
      >
        {icon ? (
          <div className="clickable_line" />
        ) : (
          <ClearAllIcon
            className="clickable_line"
            style={{ marginRight: "5px" }}
          />
        )}
        {index_to_display} - {data.name}
        {data.mandatory && (
          <span
            onClick={() =>
              updateActiveEditor({
                type: "section",
                data,
                root,
                root_key,
              })
            }
            className="clickable_line"
            style={{
              marginLeft: "10px",
              padding: "3px 5px",
              backgroundColor: `rgba(${
                localStorage.getItem("color-project")
                  ? localStorage.getItem("color-project")
                  : config.defaultColors.project
              },0.2)`,
              borderRadius: "5px",
              fontSize: "12px",
              border: `1px solid rgba(${
                localStorage.getItem("color-project")
                  ? localStorage.getItem("color-project")
                  : config.defaultColors.project
              },1)`,
              color: `rgba(${
                localStorage.getItem("color-project")
                  ? localStorage.getItem("color-project")
                  : config.defaultColors.project
              },1)`,
            }}
          >
            facultatif
          </span>
        )}
      </span>
      {valid && (
        <div>
          {!data.category_id && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                updateTree({
                  ...tree,
                  expanded: [...tree.expanded, root_key + ""],
                });
                updateActiveEditor({
                  type: "add_section",
                  data: null,
                  root,
                  root_key,
                });
              }}
              style={{
                padding: "3px 7px",
                marginRight: "5px",
              }}
            >
              + Ajouter sous-section
            </Button>
          )}
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              updateTree({
                ...tree,
                expanded: [...tree.expanded, root_key.join("-")],
              });
              updateActiveEditor({
                type: "add_task",
                data: null,
                root,
                root_key,
              });
            }}
            style={{
              padding: "3px 7px",
              marginRight: "5px",
            }}
          >
            + Ajouter tâche
          </Button>
        </div>
      )}
    </div>
  );
}
