import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function WysiwygEditor({ _formData, set_formData, placeholder }) {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // --
      ["link"],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // --
      [{ indent: "-1" }, { indent: "+1" }], // --

      [{ size: ["small", false, "large", "huge"] }], // --
      [{ color: [] }, { background: [] }], // --
    ],
  };

  return (
    <div className="text-editor" style={{ backgroundColor: "white" }}>
      <ReactQuill
        theme="snow"
        placeholder={placeholder}
        modules={modules}
        value={_formData.description ? _formData.description : ""}
        onChange={(e) => {
          set_formData({ ..._formData, description: e });
        }}
      />
    </div>
  );
}
