////// React Core :
import ActiveEditorContext from "../../../../providers/ActiveEditorContext";
import TreeContext from "../../../../providers/TreeContext";
import Add from "./Buttons/Add";
import DescriptionField from "./Fields/Description.js";
import EntitiesField from "./Fields/Entities";
import NameField from "./Fields/Name.js";
import Price from "./Fields/Price";
import SpecialitiesField from "./Fields/Specialities";
import SubdomainForm from "./Fields/SubdomainForm";
import SubdomainsField from "./Fields/Subdomains";
import TemplateLib from "@common/libs/template-lib";
import { Grid } from "@material-ui/core";
import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import config from "~/config";
import FiltersContext from "~/providers/FiltersContext";

export default function FormAddProject() {
    const { activeEditor, updateActiveEditor } = useContext(ActiveEditorContext);
    const { tree, updateTree } = useContext(TreeContext);
    const { filtersData, updateFiltersData } = useContext(FiltersContext);

    const [_loading, set_loading] = useState(false);

    const [_openDialog, set_openDialog] = useState(false);
    const [_dataDialog, set_dataDialog] = useState({ specialty_id: undefined, name: "" });

    const [_formData, set_formData] = useState({
        is_template: true,
        name: "",
        projectspecialties: [],
        entities: [],
        set_entities: [],
        completion: 0,
        description: "",
        subdomains: [],
        price: 0,
        tva: true,
    });

    const addProject = () => {
        set_loading(true);
        TemplateLib.newProject({ ..._formData, entities: [] }, filtersData.site.domain)
            .then(res => {
                TemplateLib.setSubdomainsToProject(res.id, _formData.subdomains)
                    .then(resolve => {
                        let valid = true;

                        if (filtersData.speciality !== 1) {
                            if (_formData.projectspecialties.indexOf(filtersData.speciality) === -1) {
                                updateFiltersData({ ...filtersData, speciality: 1 });
                                valid = false;
                            }
                        }
                        if (filtersData.categories !== null) {
                            if (_formData.subdomains.findIndex(el => filtersData.categories.id === el.id) === -1) {
                                updateFiltersData({ ...filtersData, categories: 1 });
                                valid = false;
                            }
                        }
                        if (filtersData.entities.length) {
                            if (_formData.set_entities.findIndex(el => filtersData.entities[0].id === el.id) === -1) {
                                updateFiltersData({ ...filtersData, entities: [] });
                                valid = false;
                            }
                        }

                        let realData = { ..._formData, id: res.id };
                        realData.subdomains = resolve;
                        let result = [...tree.projects, realData];
                        result.sort(function (a, b) {
                            if (a.name < b.name) return -1;
                            else if (a.name > b.name) return 1;
                            else return 0;
                        });
                        result = result.filter(el => el.entities.length !== 0).concat(result.filter(el => el.entities.length === 0));

                        if (!valid) updateTree({ ...tree, projects: result });
                        else updateTree({ ...tree, projects: result, selected: "" + result.findIndex(el => el.id === res.id) });
                        updateActiveEditor({ ...activeEditor, type: "project", data: realData, root: [realData], root_key: tree.projects.length });
                        toast.success('Ajout du projet : "' + realData.name + '" enregistré.');
                        set_loading(false);
                    })
                    .catch(response => {
                        set_loading(false);
                        config.handleErrorBackend({ response, message: "durant l'ajout du projet" });
                    });
            })
            .catch(response => {
                set_loading(false);
                config.handleErrorBackend({ response, message: "durant l'ajout du projet" });
            });
    };

    return (
        <Grid container spacing={2}>
            <SubdomainForm filtersData={filtersData} _openDialog={_openDialog} set_openDialog={set_openDialog} data={_dataDialog} set_dataDialog={set_dataDialog} _formData={_formData} set_formData={set_formData} />
            <NameField _formData={_formData} set_formData={set_formData} label="Nom du projet" />
            <Price _formData={_formData} set_formData={set_formData} label="Prix du projet" />
            <SpecialitiesField _formData={_formData} set_formData={set_formData} />
            <SubdomainsField _formData={_formData} set_formData={set_formData} _openDialog={_openDialog} set_openDialog={set_openDialog} _dataDialog={_dataDialog} set_dataDialog={set_dataDialog} />
            <EntitiesField _formData={_formData} set_formData={set_formData} />
            <DescriptionField _formData={_formData} set_formData={set_formData} label="Description du projet" />
            <Add _function={addProject} loading={_loading} />
        </Grid>
    );
}
