import { MenuItem, Button, Menu } from "@material-ui/core";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import React from "react";
import { toast } from "react-toastify";
import TemplateLib from "~/common/libs/template-lib";
import ActiveEditorContext from "~/providers/ActiveEditorContext";
import FiltersContext from "~/providers/FiltersContext";
import StoreDataContext from "~/providers/StoreDataContext";

export default function CopyProject({ _formData, set_formData, label }) {
  const { storeData } = React.useContext(StoreDataContext);
  const { activeEditor } = React.useContext(ActiveEditorContext);
  const { filtersData } = React.useContext(FiltersContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const listSites = storeData.sites.filter(
    (el) => el.id !== filtersData.site.id
  );

  const copyProject = (site) => {
    const project_id = activeEditor.data.id;
    const domain = site.domain;
    if (
      window.confirm(
        `Voulez vous vraiment copié l'intégralité du projet vers " ${site.name} : ${site.domain} " `
      )
    ) {
      TemplateLib.duplicateProject(project_id, domain).then((res) => {
        toast.success(
          `Le projet a été copié vers " ${site.name} : ${site.domain} " avec succès`
        );
      });
    }
  };

  return (
    listSites.length > 1 && (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CollectionsBookmarkIcon style={{ marginRight: 10 }} /> Copier vers un
          autre site
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {listSites.map((site, key) => (
            <MenuItem key={key} onClick={() => copyProject(site)}>
              {site.name} : {site.domain}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  );
}
