import { Grid, TextField } from "@material-ui/core";
import React from "react";

export default function Name({ _formData, set_formData, label }) {
  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <TextField
        aria-label={"project_" + label}
        autoComplete="off"
        style={{ backgroundColor: "white" }}
        fullWidth
        label={label}
        size="small"
        variant="outlined"
        value={_formData.name}
        onChange={(e) => set_formData({ ..._formData, name: e.target.value })}
      />
    </Grid>
  );
}
