import { Grid, TextField, Fade, Slide, Button, InputAdornment } from "@material-ui/core";
import React from "react";

export default function Input(props) {
    const { index, _paymentForm, set_paymentForm, data } = props;

    const deleteLine = () => {
        set_paymentForm({ ..._paymentForm, products: _paymentForm.products.filter((el, i) => i !== index) });
    };

    const getClearNumber = number => {
        let table = number.split("");
        if (number === "") return 0;
        else if (number === "0" || number === 0) return number;
        else if (table[0] === "0" || table[0] === 0) {
            table.shift();

            return getClearNumber(table.join(""));
        } else return table.join("");
    };

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
            <div style={{ width: "90%" }}>
                <Fade in={true} {...{ timeout: 1800 }}>
                    <div>
                        <Grid container style={{ marginBottom: "10px" }} spacing={2}>
                            <Grid item lg={7} md={7} sm={7} xs={12}>
                                <TextField
                                    style={{ backgroundColor: "white" }}
                                    fullWidth
                                    label="Nom de l'élément"
                                    size="small"
                                    variant="outlined"
                                    value={data.name}
                                    onChange={e => {
                                        let result = { ..._paymentForm };
                                        result.products[index].name = e.target.value;
                                        set_paymentForm(result);
                                    }}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={4} xs={8}>
                                <TextField
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                    }}
                                    style={{ backgroundColor: "white" }}
                                    fullWidth
                                    label="Montant"
                                    size="small"
                                    variant="outlined"
                                    type="number"
                                    value={data.amount}
                                    onBlur={e => {
                                        let result = { ..._paymentForm };
                                        result.products[index].amount = getClearNumber(e.target.value);
                                        set_paymentForm(result);
                                    }}
                                    onChange={e => {
                                        if (e.nativeEvent.inputType === "insertText" || e.nativeEvent.inputType === "deleteContentBackward" || e.nativeEvent.inputType === "insertFromPaste") {
                                            let result = { ..._paymentForm };
                                            result.products[index].amount = e.target.value === "" ? "" : +e.target.value;
                                            set_paymentForm(result);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item lg={1} md={1} sm={1} xs={4}>
                                {index !== 0 && (
                                    <Button onClick={deleteLine} variant="contained" color="secondary">
                                        -
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </div>
        </Slide>
    );
}
