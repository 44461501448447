import Checkbox from "./LineForms/Checkbox";
import Input from "./LineForms/Input";
import Radio from "./LineForms/Radio";
import Select from "./LineForms/Select";
import { Select as MaterialSelect, InputLabel, MenuItem, FormControl, Button, Paper, Divider } from "@material-ui/core";
import React, { useState } from "react";

export default function Formulaire(props) {
    const { _formData, set_formData } = props;

    const typesOfLines = [
        { value: "input", label: "Champ à remplir" },
        { value: "checkbox", label: "Case à cocher" },
        { value: "radio", label: "Choix" },
        { value: "select", label: "Sélection dans une liste" },
    ];
    const [_selectedTypeLine, set_selectedTypeLine] = useState("input");

    React.useEffect(() => {
        let state = {
            text: _formData.actions[0].data.text,
            type: _formData.actions[0].data.type !== undefined && _formData.actions[0].data.type !== null ? _formData.actions[0].data.type : "client_form",
            schema: _formData.actions[0].data.schema !== undefined && _formData.actions[0].data.schema !== null ? _formData.actions[0].data.schema : [],
            values: [],
        };
        set_formData({ ..._formData, actions: [{ ..._formData.actions[0], data: state }] });
    }, []);

    function makeid() {
        var ts = String(new Date().getTime()),
            i = 0,
            out = "";

        for (i = 0; i < ts.length; i += 2) {
            out += Number(ts.substr(i, 2)).toString(36);
        }

        return out;
    }

    const addLine = fromData => {
        let result = { ...fromData };
        if (result.actions[0].data.schema === undefined) {
            result.actions[0].data.schema = [];
        }
        result.actions[0].data.schema.push({
            type: _selectedTypeLine,
            label: "",
            placeholder: "",
            key: makeid(14),
        });
        set_formData(result);
    };

    const getLineForm = (schemaElem, key) => {
        if (schemaElem.type === "input") return <Input index={key} {...props} />;
        else if (schemaElem.type === "checkbox") return <Checkbox index={key} {...props} />;
        else if (schemaElem.type === "radio") return <Radio index={key} {...props} />;
        else if (schemaElem.type === "select") return <Select index={key} {...props} />;
    };

    return (
        <div>
            {_formData.actions[0].data.schema !== undefined && (
                <Paper style={{ padding: "10px" }}>
                    <FormControl variant="outlined" size="small" style={{ backgroundColor: "white", width: "300px", margin: "10px 0 10px 0 " }}>
                        <InputLabel>Type de formulaire</InputLabel>
                        <MaterialSelect
                            value={_formData.actions[0].data.type}
                            onChange={e => {
                                set_formData({ ..._formData, actions: [{ ..._formData.actions[0], data: { ..._formData.actions[0].data, type: e.target.value } }] });
                            }}
                            label="Type de formulaire">
                            <MenuItem value={"client_form"}>
                                <em>Formulaire Client</em>
                            </MenuItem>
                            <MenuItem value={"lawyer_form"}>
                                <em>Formulaire Avocat</em>
                            </MenuItem>
                        </MaterialSelect>
                    </FormControl>
                    {_formData.actions[0].data.schema.length > 0 &&
                        _formData.actions[0].data.schema.map((elem, key) => {
                            return (
                                <div key={key}>
                                    {" "}
                                    <hr style={{ width: "100%", backgroundColor: "grey", height: "1.5pt", margin: "20px 0 0 0", border: "none" }} /> {getLineForm(elem, key)}
                                </div>
                            );
                        })}
                </Paper>
            )}

            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", margin: "20px 0 20px 0" }}>
                <FormControl variant="outlined" size="small" style={{ backgroundColor: "white", width: "250px" }}>
                    <InputLabel>Type de ligne</InputLabel>
                    <MaterialSelect
                        value={_selectedTypeLine}
                        onChange={e => {
                            set_selectedTypeLine(e.target.value);
                        }}
                        label="Type de ligne">
                        {typesOfLines.map((elem, key) => (
                            <MenuItem key={key} value={elem.value}>
                                <em>{elem.label}</em>
                            </MenuItem>
                        ))}
                    </MaterialSelect>
                </FormControl>
                <Button onClick={() => addLine(_formData)} color="primary" variant="contained" style={{ height: "35px", marginLeft: "10px" }}>
                    + Ajouter ligne
                </Button>
            </div>
            <Divider style={{ width: "80%", marginLeft: "10%" }} />
        </div>
    );
}
