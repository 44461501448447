import StoreDataContext from "../../../providers/StoreDataContext";
import { FormControl, InputLabel, CircularProgress } from "@material-ui/core/";
import React, { useContext } from "react";
import FiltersContext from "~/providers/FiltersContext";
import ActiveEditorContext from "~/providers/ActiveEditorContext";
import { TextField } from "@material-ui/core/";
import TreeContext from "~/providers/TreeContext";

export default function ProjectNamesFilter(props) {
  const { storeData } = useContext(StoreDataContext);
  const { activeEditor, updateActiveEditor } = useContext(ActiveEditorContext);
  const { tree, updateTree } = useContext(TreeContext);
  const { filtersData, updateFiltersData } = useContext(FiltersContext);
  const [name, setName] = React.useState(filtersData.project);

  return storeData.subdomains ? (
    <FormControl
      variant="outlined"
      fullWidth
      style={{ backgroundColor: "white" }}
    >
      <TextField
        variant="outlined"
        label="Rechercher par projet"
        type="text"
        inputProps={{
          autoComplete: "new-password",
          form: {
            autoComplete: "off",
          },
        }}
        autoComplete="off"
        value={name}
        onChange={(e, v) => {
          setName(e.target.value);
        }}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            updateTree({
              ...tree,
              selected: "",
              expanded: [],
            });
            updateActiveEditor({ type: null, data: null });
            updateFiltersData(() => ({
              ...filtersData,
              project: ev.target.value,
            }));
            ev.preventDefault();
          }
        }}
        fullWidth
        style={{
          backgroundColor: "white",
          verticalAlign: "middle",
        }}
      />
    </FormControl>
  ) : (
    <CircularProgress size={20} />
  );
}
