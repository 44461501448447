import TreeContext from "../../../providers/TreeContext";
import ListTasks from "./ListTasks";
import TreeLine from "./TreeLine";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import config from "~/config";

export default function ListSections({ data, index }) {
  const { tree, updateTree } = React.useContext(TreeContext);

  return !tree.projects[index].sections ? (
    <></>
  ) : (
    <TreeLine
      nodeId={index + ""}
      root_key={[index]}
      root={[data]}
      data={data}
      style={{
        border: "1px solid grey",
        backgroundColor: `rgba(${
          localStorage.getItem("color-project")
            ? localStorage.getItem("color-project")
            : config.defaultColors.project
        },0.2)`,
      }}
    >
      {tree.projects[index].sections === "loading" ? (
        <div>
          <CircularProgress size={20} />{" "}
        </div>
      ) : (
        tree.projects[index].sections.map((elem, key) => (
          <TreeLine
            key={key}
            nodeId={index + "-" + key}
            root_key={[index, key]}
            root={[data, elem]}
            data={elem}
            index_to_display={key + 1}
            style={{
              border: "1px solid grey",
              backgroundColor: `rgba(${
                localStorage.getItem("color-section")
                  ? localStorage.getItem("color-section")
                  : config.defaultColors.section
              },0.2)`,
            }}
          >
            {tree.projects[index].sections[key].sections?.map((elem2, key2) => (
              <TreeLine
                key={key2}
                nodeId={index + "-" + key + "-" + key2}
                root_key={[index, key, key2]}
                root={[data, elem2]}
                data={elem2}
                index_to_display={key2 + 1}
                style={{
                  border: "1px solid grey",
                  backgroundColor: `rgba(${
                    localStorage.getItem("color-section")
                      ? localStorage.getItem("color-section")
                      : config.defaultColors.section
                  },0.2)`,
                }}
              >
                <ListTasks
                  data={elem2}
                  index={index + "-" + key + "-" + key2}
                  parent_index={key2}
                  root_key={[index, key, key2]}
                  root={[data, elem2]}
                />
              </TreeLine>
            ))}

            <ListTasks
              data={elem}
              index={index + "-" + key}
              parent_index={key}
              root_key={[index, key]}
              root={[data, elem]}
            />
          </TreeLine>
        ))
      )}
    </TreeLine>
  );
}
