import DocumentContext from "../../../../../providers/DocumentContext";
import BadgeLabel from "../../../BadgeLabel";
import SaveButton from "../SaveButton";
import { Grid, InputLabel, FormControl, TextField } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useContext } from "react";
import { toast } from "react-toastify";

const useStyles = makeStyles(({ palette, spacing }) => ({
  title: {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    fontSize: 14,
    borderBottom: " 1px solid silver",
    backgroundColor: "#00db5c",
    color: "white",
    fontWeight: "bold",
  },
  container: {
    padding: "10px 30px",
    backgroundColor: "rgba(0, 219, 92, 0.1)",
  },
  desc: {
    color: "#555",
    fontSize: 14,
  },
  variable: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  preview: {
    width: "100%",
  },
  previewValue: {
    backgroundColor: "white",
    minHeight: "24px",
  },
  previewTitle: {
    fontWeight: "bold",
  },
}));

export default ({
  variableInstance,
  setvariableInstance,
  setOpen,
  isFromWordIntegration,
}) => {
  const classes = useStyles();
  const { document, updateDocument } = useContext(DocumentContext);
  const [preview, updatePreview] = useState(null);
  const options = document.variables
    .filter(
      (el) =>
        el.type === "number" ||
        el.type === "text" ||
        el.type === "object" ||
        el.type === "date" ||
        el.type === "unisign" ||
        el.type === "multisign" ||
        el.type === "textformat"
    )
    .map((el) => ({
      ...el,
      groupName:
        document.groupsVariables.find((elem) => elem.id === el.group) !==
        undefined
          ? document.groupsVariables.find((elem) => elem.id === el.group).name
          : "Aucune page",
    }));

  React.useEffect(() => {
    setvariableInstance({
      type: "variable",
      bloc: document.selection,
      data: { variable: null },
    });
  }, []);

  const getObjects = (list) => {
    let result = [];
    list.forEach((elem) => {
      if (elem.type !== "object") result.push(elem);
      else {
        elem.options.forEach((el) => {
          result.push({
            ...elem,
            name: elem.name + " -> " + el.label,
            object_id: el.id,
          });
        });
      }
    });
    return result;
  };

  const getBloc = () => {
    return variableInstance?.data?.variable?.name.indexOf("->") > -1
      ? `{{  object_${variableInstance?.data?.variable?.id}.options["${variableInstance?.data?.variable.object_id}"] }}`
      : `{{  ${variableInstance?.data?.variable?.type}_${variableInstance?.data?.variable?.id} }}`;
  };

  const onSave = () => {
    if (variableInstance?.data?.variable !== null) {
      const displayBloc = getBloc();
      updateDocument({ ...document, fireChangeToBloc: displayBloc });
    }
  };

  const onCopy = () => {
    if (variableInstance?.data?.variable !== null) {
      const displayBloc = getBloc();
      navigator.clipboard.writeText(displayBloc);
      toast.success("La variable a été copiée dans le presse-papier.");
    }
  };

  const changePreview = () => {
    const prev = getBloc();
    updatePreview(prev);
  };

  React.useEffect(() => {
    changePreview();
  }, [variableInstance]);

  return (
    <Grid container className={classes.container}>
      {isFromWordIntegration && preview !== null && (
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.variable}>
          <div className={classes.preview}>
            <span className={classes.previewTitle}>Preview : </span>
            <div className={classes.previewValue}>
              {variableInstance?.data?.variable !== null ? preview : ""}
            </div>
          </div>
        </Grid>
      )}
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.variable}>
        <div className={classes.desc}>Choisissez une variable :</div>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.variable}>
        <FormControl
          style={{ backgroundColor: "white", flexGrow: 1 }}
          variant="outlined"
          size="small"
        >
          <InputLabel></InputLabel>
          <Autocomplete
            size="small"
            value={variableInstance?.data?.variable}
            onChange={(e, v) => {
              setvariableInstance({
                ...variableInstance,
                data: { variable: v },
              });
              if (!isFromWordIntegration) {
              } else {
                changePreview();
              }
            }}
            options={getObjects(options)}
            groupBy={(option) => option.groupName}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option) =>
              option.name === variableInstance?.data?.variable?.name
            }
            renderOption={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <BadgeLabel type={option.type} />
                <span style={{ marginLeft: 15 }}>
                  <b>#{option.id}</b> {option.name}
                </span>
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choisir variable"
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <SaveButton
          onSave={onSave}
          onCopy={onCopy}
          isFromWordIntegration={isFromWordIntegration}
          disabled={variableInstance?.data?.variable === null}
          showCopy={true}
          setOpen={setOpen}
        />
      </Grid>
    </Grid>
  );
};
