import LineCondition from "./LineCondition";
import { Grid, IconButton } from "@material-ui/core/";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import React from "react";

export default ({
  options,
  variableInstance,
  setvariableInstance,
  bloc,
  indexBloc,
  isFromWordIntegration,
}) => {
  const addLineCondition = (index) => {
    let result = { ...variableInstance };
    result.data[indexBloc] = [
      ...result.data[indexBloc],
      { variable: null, option: "" },
    ];
    setvariableInstance(result);
  };

  return (
    <Grid
      container
      style={{ backgroundColor: "#eee", borderRadius: 5, marginTop: 20 }}
      spacing={2}
    >
      {bloc?.map((elem, key) => (
        <LineCondition
          data={elem}
          key={key}
          indexLine={key}
          variableInstance={variableInstance}
          setvariableInstance={setvariableInstance}
          options={options}
          indexBloc={indexBloc}
        />
      ))}
      {!isFromWordIntegration && (
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "grey",
          }}
        >
          <IconButton
            style={{ borderRadius: 5, padding: "0px 10px" }}
            onClick={addLineCondition}
          >
            <AddCircleOutlineIcon style={{ fontSize: 14, marginRight: 5 }} />
            <span style={{ fontSize: 12 }}> ET </span>
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
