////// React Core :
import App from "./App"
import ClientDataContext from "./providers/ClientDataContext"
import AccountLib from "@common/libs/account-lib"
import GroupsLib from "@common/libs/groups-lib"
import TokenData from "@common/models/token-data"
import CircularProgress from "@material-ui/core/CircularProgress"
import React, { useState, useEffect } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import Login from "~/pages/login"

export default function Routes() {
  const [isLogged, setIsLogged] = useState()

  useEffect(() => {
    const token = TokenData.fromStorage()

    if ((token && token.isExpired()) || !token) {
      setIsLogged(false)
    } else {
      AccountLib.getUserProfile()
        .then(() => {
          setIsLogged(true)
        })
        .catch(() => {
          setIsLogged(false)
        })
    }
  }, [])

  const [clientData, setClientData] = useState({
    theme: null,
    site_client: null,
  })

  const contextClient = {
    clientData,
    updateClientData: setClientData,
  }

  useEffect(() => {
    GroupsLib.getRequestClient(window.location.origin).then((site_client) => {
      const theme = {
        logoUrl:
          "https://uploads-ssl.webflow.com/5fba818d019a29029e535d35/5ff2f93039c32d0bf9e7c26a_ComeOnLaw%20Logo.svg",
        brancdColor1: "green",
        brandColor2: "#F7FAFC",
        brandColor1: "#5469D4",
        font: "lato",
      }
      setClientData({ site_client, theme })
    })
  }, [])

  return (
    <Router>
      <Switch>
        <ClientDataContext.Provider value={contextClient}>
          {isLogged === undefined && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "80vh",
                alignItems: "center",
              }}
            >
              <CircularProgress size={50} />
            </div>
          )}
          {isLogged === false && <Route path="/" component={Login} />}
          {isLogged && (
            <App isLogged={isLogged} setIsLogged={(e) => setIsLogged(e)} />
          )}
        </ClientDataContext.Provider>
      </Switch>
    </Router>
  )
}
