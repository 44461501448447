import { ApiServiceRaw } from '../services/api-service';
import baseAxios from 'axios';
import User from '../models/user';
import TokenData from '../models/token-data';
import path from 'path';
import EnterprisesLib from './enterprises-lib';
import GroupsLib from './groups-lib';

const axios = baseAxios.create({
  baseURL: process.env.COMMON_LAW_TEMPLATE_API_URL,
});

const ApiService = new ApiServiceRaw(process.env.COMMON_LAW_TEMPLATE_API_URL);

export default class TemplateLib {
  // Site

  static SitesPath = 'api/sites/{id}';

  static async getListSites() {
    const response = await ApiService.get(this.SitesPath.replace('{id}', ''));
    return response.data.results;
  }

  static async getSite(id) {
    const response = await ApiService.get(this.SitesPath.replace('{id}', id));
    return response.data;
  }

  static async newSite(data) {
    const response = await ApiService.post(this.SitesPath.replace('{id}', ''), data);
    return response.data;
  }

  static async editSite(id, data) {
    const response = await ApiService.put(this.SitesPath.replace('{id}', id) + '/', data);
    return response.data;
  }

  static async deleteSite(id) {
    const response = await ApiService.delete(this.SitesPath.replace('{id}', id));
    return response.data;
  }

  // Categories

  static CategoriesPath = 'api/categories/{id}';

  static async getListCategoriesOfProject(project_id) {
    const response = await ApiService.get(
      this.CategoriesPath.replace('{id}', '') + '?project_id=' + project_id,
    );
    return response.data.results;
  }

  static async getCategorie(id) {
    const response = await ApiService.get(this.CategoriesPath.replace('{id}', id));
    return response.data;
  }

  static async newCategorie(data) {
    const response = await ApiService.post(this.CategoriesPath.replace('{id}', ''), data);
    return response.data;
  }

  static async editCategorie(id, data) {
    const response = await ApiService.put(this.CategoriesPath.replace('{id}', id) + '/', data);
    return response.data;
  }

  static async editCategories(categories) {
    try {
      categories.forEach(async (category) => {
        delete category.risk;
        await ApiService.put(this.CategoriesPath.replace('{id}', category.id) + '/', category);
      });
      return 'ok';
    } catch (e) {
      console.error('error', e);
    }
  }

  static async deleteCategorie(id) {
    const response = await ApiService.delete(this.CategoriesPath.replace('{id}', id));
    return response.data;
  }

  // Subdomains

  static SubdomainsPath = 'api/subdomains/{id}';

  static async getListSubdomains(site) {
    const response = await ApiService.get(
      this.SubdomainsPath.replace('{id}', '') + '?set_site=' + site,
    );
    return response.data.results;
  }

  static async getSubdomain(id) {
    const response = await ApiService.get(this.SubdomainsPath.replace('{id}', id));
    return response.data;
  }

  static async newSubdomain(data, site) {
    const response = await ApiService.post(
      this.SubdomainsPath.replace('{id}', '') + '?set_site=' + site,
      data,
    );
    return response.data;
  }

  static async editSubdomain(id, data, site) {
    const response = await ApiService.put(
      this.SubdomainsPath.replace('{id}', id) + '/?set_site=' + site,
      data,
    );
    return response.data;
  }

  static async deleteSubdomain(id) {
    const response = await ApiService.delete(this.SubdomainsPath.replace('{id}', id));
    return response.data;
  }

  // Directories
  static DirectoriesPath = 'api/directories/{id}';
  static async getListDirectories() {
    const response = await ApiService.get(this.DirectoriesPath.replace('{id}', ''));
    return response.data.results;
  }

  // Files

  static FilesPath = 'api/files/{id}';
  static async getListFiles() {
    const response = await ApiService.get(this.FilesPath.replace('{id}', ''));
    return response.data.results;
  }

  // Projects

  static ProjectsPath = 'api/projects/{id}';
  static ProjectTemplatesPath = 'api/projects/templates?limit=10000&set_site={filter_site}';
  static ProjectSubdomainPath = 'api/projects/{id}/set_subdomains/';
  static ProjectEntitiesPath = 'api/projects/{id}/set_entities/';
  static ProjectSitePath = 'api/projects/{id}/set_site/';
  static ProjectDuplicatePath = 'api/projects/{id}/duplicate/';

  static async getProject(id) {
    const response = await ApiService.get(this.ProjectsPath.replace('{id}', id + '/'));
    return response.data;
  }

  static async getListTemplateProjects(site_id = '') {
    const response = await ApiService.get(
      this.ProjectTemplatesPath.replace('{filter_site}', site_id),
    );
    return response.data.results;
  }

  static async newProject(data, site) {
    const response = await ApiService.post(
      this.ProjectsPath.replace('{id}', '') + '?set_site=' + site,
      data,
    );
    return response.data;
  }

  static async editProject(id, data, site) {
    const response = await ApiService.put(this.ProjectsPath.replace('{id}', id) + '/', data);
    return response.data;
  }

  static async refreshPreloadedProjects(id) {
    const response = await ApiService.put(
      this.ProjectsPath.replace('{id}', id) + '/refresh_preloaded_projects/',
      {},
    );
    return response.data;
  }

  static async deleteProject(id) {
    const response = await ApiService.delete(this.ProjectsPath.replace('{id}', id));
    return response.data;
  }

  static async setSiteToProject(id, site_id) {
    const response = await ApiService.put(this.ProjectSitePath.replace('{id}', id), site_id);
    return response.data;
  }

  static async setSubdomainsToProject(id, subdomains) {
    const response = await ApiService.put(
      this.ProjectSubdomainPath.replace('{id}', id),
      subdomains,
    );
    return response.data;
  }

  static async setEntityToProject(id, data, entities) {
    const response = await ApiService.put(this.ProjectEntitiesPath.replace('{id}', id), entities);
    return response.data;
  }

  static async duplicateProject(id, site) {
    const response = await ApiService.put(
      this.ProjectDuplicatePath.replace('{id}', id) + '?set_site=' + site,
      {},
    );
    return response.data;
  }
  // Tasks
  static TasksPath = 'api/tasks/{id}';
  static ActionsPath = 'api/tasks/{id}/set_actions/';
  static async getListTasks() {
    const response = await ApiService.get(this.TasksPath.replace('{id}', ''));
    return response.data.results;
  }

  static async getListTaskOfCategories(category__id) {
    const response = await ApiService.get(
      this.TasksPath.replace('{id}', '') + '?category__id=' + category__id + '&all_templates=True',
    );
    return response.data.results;
  }

  static async getSpecificTask(id) {
    const response = await ApiService.get(this.TasksPath.replace('{id}', id + '/'));
    return response.data;
  }

  static async newTask(data) {
    const response = await ApiService.post(this.TasksPath.replace('{id}', ''), data);
    return response.data;
  }

  static async editTask(id, data) {
    const response = await ApiService.put(this.TasksPath.replace('{id}', id) + '/', data);
    return response.data;
  }

  static async deleteTask(id) {
    const response = await ApiService.delete(this.TasksPath.replace('{id}', id));
    return response.data;
  }

  static async setActionsTask(id, data) {
    const response = await ApiService.put(this.ActionsPath.replace('{id}', id), data);
    return response.data;
  }
}
