import axios from 'axios';
import TokenData from '../models/token-data';

class Api {
  constructor(baseUrl) {
    this.axios = axios.create({
      baseURL: baseUrl,
    });

    this.baseUrl = baseUrl;

    this.initializeHeaders();
  }

  initializeHeaders() {
    if (TokenData.fromStorage()) {
      this.setAuthorizationToken();
    }
  }

  setAuthorizationToken() {
    const tokenData = TokenData.fromStorage();
    if (tokenData.accessToken) {
      this.axios.defaults.headers.common.Authorization = `JWT ${tokenData.accessTokenJwt}`;
    } else {
      delete this.axios.defaults.headers.common.Authorization;
    }
  }

  post(url, data, type = null, customHeaders = {}) {
    this.initializeHeaders();

    let config = { headers: {} };

    if (type) {
      config.headers['Content-Type'] = type;
    }

    config = { ...config, ...customHeaders };

    return new Promise((resolve, reject) => {
      this.axios
        .post(url, data, config)
        .then((response) => resolve(response))
        .catch((error) => {
          reject(error);
        });
    });
  }

  put(url, data, type = null) {
    this.initializeHeaders();

    const config = { headers: {} };

    if (type) {
      config.headers['Content-Type'] = type;
    }

    return new Promise((resolve, reject) => {
      this.axios
        .put(url, data, config)
        .then((response) => resolve(response))
        .catch((error) => {
          reject(error);
        });
    });
  }

  delete(url, type = null) {
    this.initializeHeaders();

    const config = { headers: {} };

    if (type) {
      config.headers['Content-Type'] = type;
    }

    return new Promise((resolve, reject) => {
      this.axios
        .delete(url, config)
        .then((response) => resolve(response))
        .catch((error) => {
          reject(error);
        });
    });
  }

  get(url, config) {
    this.initializeHeaders();

    return new Promise((resolve, reject) => {
      this.axios
        .get(url, config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  download(url) {
    this.initializeHeaders();

    return new Promise((resolve, reject) => {
      this.axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export { Api as ApiServiceRaw };

export default new Api(process.env.COMMON_LAW_OAUTH_API_URL);
