import GroupList from "./GroupList";
import { Paper } from "@material-ui/core/";

export default function ListVariables() {
    return (
        <Paper style={{ minHeight: 520, padding: 30 }}>
            <GroupList />
        </Paper>
    );
}
