////// React Core :
import ProjectLine from "./ThreeLines/ProjectLine";
import SectionLine from "./ThreeLines/SectionLine";
import TaskLine from "./ThreeLines/TaskLine";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";
import TreeItem from "@material-ui/lab/TreeItem";
import React from "react";
import { useSpring, animated } from "react-spring/web.cjs";

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = withStyles((theme) => ({
  label: {
    padding: "0",
    zIndex: 50,
  },
  iconContainer: {
    width: "40px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0",
    zIndex: 100,
  },

  group: {
    backgroundColor: "white",
  },
}))((props) => {
  return (
    <TreeItem
      TransitionComponent={TransitionComponent}
      {...props}
      label={getTreeItem({ ...props })}
    />
  );
});

const getTreeItem = (props) => {
  const { nodeId, root, root_key, data } = props;
  const count_level = nodeId.split("-").length;
  switch (count_level) {
    case 1:
      return <ProjectLine {...props} />;
    case 2:
      return <SectionLine {...props} />;
    case 3:
      if (data.tasks) {
        return <SectionLine {...props} />;
      } else {
        return <TaskLine {...props} />;
      }
    case 4:
      return <TaskLine {...props} />;
    default:
      return <span>{data.name}</span>;
  }
};

export default StyledTreeItem;
