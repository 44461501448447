import StoreDataContext from "../../providers/StoreDataContext";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useContext } from "react";
import TokenData from "~/common/models/token-data";
import FiltersContext from "~/providers/FiltersContext";

// import UserContext from '../../../providers/'

export default function SitesFilter() {
    const { storeData } = useContext(StoreDataContext);
    const { filtersData, updateFiltersData } = useContext(FiltersContext);

    const scope = TokenData.fromStorage().scope;

    React.useEffect(() => {
        if (filtersData.site !== null) sessionStorage.setItem("filters", JSON.stringify(filtersData));
    }, [filtersData.site]);

    return storeData?.sites?.length !== 1 && (scope.indexOf("admin_backoffice_user") > -1 || scope.indexOf("admin_staff_user") > -1) ? (
        <Autocomplete
            style={{ margin: "20px 0 10px 0" }}
            options={storeData.sites}
            getOptionLabel={option => option.name + " -  " + option.domain}
            value={filtersData.site}
            onChange={(e, v) => {
                updateFiltersData(filtersData => ({ ...filtersData, site: v }));
            }}
            renderInput={params => <TextField fullWidth {...params} variant="outlined" label="Périmètre" placeholder="Rechercher" style={{ backgroundColor: "white" }} />}
        />
    ) : (
        <h3>
            Périmètre de l'application : <span style={{ fontSize: 24, marginLeft: 10 }}> {storeData?.sites[0]?.name}</span>
        </h3>
    );
}
