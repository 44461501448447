import { Grid, TextField, Fade, Slide, Button, Checkbox, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(() => ({
    title: {
        color: "grey",
        fontSize: "12px",
    },
    inputLabel: {
        fontSize: "12px",
    },
    inputPlaceholder: {
        color: "#aaa",
        fontSize: "12px",
    },
}));

export default function Select(props) {
    const classes = useStyles();
    const { _formData, set_formData, index } = props;

    const deleteLine = () => {
        let result = { ..._formData };
        result.actions[0].data.schema = result.actions[0].data.schema.filter(elem => elem.key !== _formData.actions[0].data.schema[index].key);
        set_formData(result);
    };

    return (
        <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 600 }}>
            <div>
                <Fade in={true} {...{ timeout: 1800 }}>
                    <div>
                        <small style={{ display: "flex", justifyContent: "center", marginBottom: "10px", fontWeight: "bold" }}>Sélection dans une liste</small>

                        <Grid container style={{ marginBottom: "10px" }}>
                            <Grid item lg={11} md={11} sm={11} xs={12}>
                                <TextField
                                    multiline
                                    rows={2}
                                    InputLabelProps={{
                                        className: classes.title,
                                    }}
                                    InputProps={{
                                        className: classes.inputLabel,
                                    }}
                                    fullWidth
                                    value={_formData.actions[0].data.schema[index].label}
                                    onChange={e => {
                                        let result = { ..._formData };
                                        result.actions[0].data.schema[index].label = e.target.value;
                                        set_formData(result);
                                    }}
                                    variant="outlined"
                                    size="small"
                                    label="Question"
                                    style={{ backgroundColor: "white" }}
                                />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <small className="description">Choix multiples :</small>{" "}
                                    <Checkbox
                                        checked={_formData.actions[0].data.schema[index].multiple}
                                        onChange={(e, v) => {
                                            let result = { ..._formData };
                                            result.actions[0].data.schema[index].multiple = v;
                                            set_formData(result);
                                        }}
                                    />
                                </div>

                                <div>
                                    <Autocomplete
                                        size="small"
                                        multiple
                                        freeSolo
                                        options={[]}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                return <Chip variant="outlined" label={option} {...getTagProps({ index })} />;
                                            })
                                        }
                                        onChange={(e, v) => {
                                            let result = { ..._formData };
                                            result.actions[0].data.schema[index].list = v;
                                            set_formData(result);
                                        }}
                                        value={_formData.actions[0].data.schema[index].list ? _formData.actions[0].data.schema[index].list : []}
                                        renderInput={params => <TextField size="small" {...params} variant="outlined" label="Liste d'éléments" placeholder="Ajouter un élément" />}
                                    />
                                </div>
                            </Grid>

                            <Grid item lg={1} md={1} sm={1} xs={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <Button onClick={deleteLine} variant="contained" color="secondary" style={{ height: "27px", width: "60%", margin: "15% 0 0 10%" }}>
                                    <b>-</b>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </div>
        </Slide>
    );
}
