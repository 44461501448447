import { Grid, Checkbox } from "@material-ui/core"
import React from "react"

export default function Mandatory({ _formData, set_formData, label }) {
  return (
    <Grid
      item
      lg={5}
      md={5}
      sm={12}
      xs={12}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Checkbox
        checked={_formData.mandatory}
        onChange={(e) =>
          set_formData({ ..._formData, mandatory: e.target.checked })
        }
      />
      <span
        style={{ cursor: "pointer" }}
        onClick={() =>
          set_formData({ ..._formData, mandatory: !_formData.mandatory })
        }
      >
        {label}
      </span>
    </Grid>
  )
}
