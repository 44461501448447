import AssignmentIcon from "@material-ui/icons/Assignment";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import EmailIcon from "@material-ui/icons/Email";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import NoteIcon from "@material-ui/icons/Note";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import TextsmsIcon from "@material-ui/icons/Textsms";

export default function getIconTask(type) {
    if (type !== undefined) {
        switch (type) {
            case "text":
                return <TextsmsIcon style={{ marginRight: "10px" }} />;
            case "form":
                return <AssignmentIcon style={{ marginRight: "10px" }} />;
            case "interview":
                return <ContactSupportIcon style={{ marginRight: "10px" }} />;
            case "electronic":
                return <BorderColorIcon style={{ marginRight: "10px" }} />;
            case "upload":
                return <CloudUploadIcon style={{ marginRight: "10px" }} />;
            case "courrier":
                return <EmailIcon style={{ marginRight: "10px" }} />;
            case "payment":
                return <EuroSymbolIcon style={{ marginRight: "10px" }} />;
            case "rdv":
                return <OndemandVideoIcon style={{ marginRight: "10px" }} />;

            default:
                return <NoteIcon style={{ marginRight: "10px" }} />;
        }
    } else return <NoteIcon style={{ marginRight: "10px" }} />;
}
