import getIconTask from "../Functions/getIconTask";
import { Grid, Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import React from "react";

export default function Description({ _formData, set_formData }) {
    const listTaskType = [
        { value: "text", label: "Texte informatif" },
        { value: "form", label: "Formulaire" },
        { value: "interview", label: "Génération de documents" },
        { value: "electronic", label: "Signature de documents" },
        { value: "courrier", label: "Courrier en ligne" },
        { value: "upload", label: "Upload de fichiers" },
        { value: "payment", label: "Paiement en ligne" },
        { value: "rdv", label: "Prise de rdv visio" },
    ];

    return (
        <Grid item lg={7} md={7} sm={12} xs={12}>
            <FormControl variant="outlined" size="small" fullWidth style={{ backgroundColor: "white" }}>
                <InputLabel>Type de la tâche</InputLabel>
                <Select
                    value={_formData.actions[0].type}
                    onChange={e => {
                        set_formData({ ..._formData, actions: [{ ..._formData.actions[0], type: e.target.value }] });
                    }}
                    label="Type de la tâche">
                    {listTaskType.map((elem, key) => (
                        <MenuItem key={key} value={elem.value}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span>{getIconTask(elem.value)}</span>
                                <em>{elem.label}</em>
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}
