import StoreDataContext from "../../../../../providers/StoreDataContext";
import TemplateLib from "@common/libs/template-lib";
import { Dialog, TextField, Select, FormControl, InputLabel, Button, MenuItem, CircularProgress } from "@material-ui/core/";
import React, { useState } from "react";
import { toast } from "react-toastify";
import FiltersContext from "~/providers/FiltersContext";

export default function SubdomainForm(props) {
    const { _openDialog, set_openDialog, data, set_dataDialog, _formData, set_formData } = props;
    const { storeData, updateStoreData } = React.useContext(StoreDataContext);
    const { filtersData } = React.useContext(FiltersContext);
    const [loading, setloading] = useState(false);

    const [_formSubdomain, set_formSubdomain] = useState({
        specialty_id: "",
        name: "",
    });

    React.useEffect(() => {
        if (data?.specialty_id)
            set_formSubdomain({
                specialty_id: data.specialty_id,
                name: data?.name ? data.name : "",
            });
        else
            set_formSubdomain({
                specialty_id: "",
                name: data?.name ? data?.name : "",
            });
    }, [data]);

    const DeleteSubdomain = () => {
        setloading(true);
        TemplateLib.deleteSubdomain(data.id)
            .then(() => {
                let result = [...storeData.specialities];
                result[data.specialty_id].subdomains = result[data.specialty_id].subdomains.filter(el => el.id !== data.id);
                let result2 = [...storeData.subdomains];
                result2 = result2.filter(el => el.id !== data.id);
                updateStoreData({ ...storeData, specialities: result, subdomains: result2 });
                toast.success(`Suppression de la sous-spécialité "${_formSubdomain.name}" enregistré`);
                if (_formData.subdomains.findIndex(el => el.id === data.id) > -1) set_formData({ ..._formData, subdomains: _formData.subdomains.filter(el => el.id !== data.id) });
                set_dataDialog(undefined);
                set_openDialog(false);
                setloading(false);
            })
            .catch(err => {
                setloading(false);
                toast.error(
                    <div>
                        <div>Erreur survenue durant la suppression de la sous-spécialité :</div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: JSON.stringify(err.response.data).replaceAll(/[}{]/gi, " ").replaceAll("[", " ").replaceAll("]", " ").replaceAll(",", "<br/>"),
                            }}
                        />
                    </div>
                );
            });
    };
    const AddSubdomain = () => {
        setloading(true);

        TemplateLib.newSubdomain(_formSubdomain, filtersData.site.domain)
            .then(res => {
                let result = [...storeData.specialities];
                result[_formSubdomain.specialty_id].subdomains.push(res);

                let result2 = [...storeData.subdomains];
                result2.push(res);

                updateStoreData({ ...storeData, specialities: result, subdomains: result2 });

                set_formData({ ..._formData, subdomains: [..._formData.subdomains, res] });
                toast.success(`Ajout de la sous-spécialité "${_formSubdomain.name}" enregistré`);
                setloading(false);
                set_openDialog(false);
            })
            .catch(err => {
                setloading(false);
                toast.error(
                    <div>
                        <div>Erreur survenue durant l'ajout de la sous-spécialité :</div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: JSON.stringify(err.response.data).replaceAll(/[}{]/gi, " ").replaceAll("[", " ").replaceAll("]", " ").replaceAll(",", "<br/>"),
                            }}
                        />
                    </div>
                );
            });
    };

    const EditSubdomain = () => {
        setloading(true);
        TemplateLib.editSubdomain(data.id, _formSubdomain, filtersData.site.domain)
            .then(res => {
                let result = [...storeData.specialities];
                const index = result[_formSubdomain.specialty_id].subdomains.findIndex(el => el.id === data.id);
                result[_formSubdomain.specialty_id].subdomains[index] = res;
                let result2 = [...storeData.subdomains];

                const index2 = result2.findIndex(el => el.id === res.id);
                result2[index2] = res;
                updateStoreData({ ...storeData, specialities: result, subdomains: result2 });
                toast.success(`Modification de la sous-spécialité "${_formSubdomain.name}" enregistré`);

                const index3 = _formData.subdomains.findIndex(el => el.id === data.id);
                if (index3 > -1) {
                    let result3 = { ..._formData };
                    result3.subdomains[index3] = res;
                    set_formData(result3);
                }

                setloading(false);
                set_dataDialog(res);
                set_openDialog(false);
            })
            .catch(err => {
                setloading(false);
                toast.error(
                    <div>
                        <div>Erreur survenue durant la modification de la sous-spécialité :</div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: JSON.stringify(err.response.data).replaceAll(/[}{]/gi, " ").replaceAll("[", " ").replaceAll("]", " ").replaceAll(",", "<br/>"),
                            }}
                        />
                    </div>
                );
            });
    };

    return (
        <Dialog open={_openDialog} onClose={() => set_openDialog(false)}>
            {loading ? (
                <div style={{ width: "600px", height: "300px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress size={20} />
                </div>
            ) : (
                <div style={{ padding: "20px", width: "600px", height: "300px" }}>
                    <h2>Ajouter une sous-spécialité</h2>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "200px" }}>
                        <TextField
                            size="small"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            style={{ margin: "30px 0" }}
                            label="Nom de la sous-spécialité"
                            aria-label="name_under_speciality"
                            value={_formSubdomain.name}
                            onChange={e => {
                                set_formSubdomain({ ..._formSubdomain, name: e.target.value });
                            }}
                        />
                        <FormControl variant="outlined" fullWidth style={{ backgroundColor: "white" }} size="small">
                            <InputLabel>Spécialité attachée</InputLabel>
                            <Select
                                value={_formSubdomain.specialty_id}
                                onChange={e => {
                                    set_formSubdomain({ ..._formSubdomain, specialty_id: e.target.value });
                                }}
                                label="Spécialité attachée">
                                {storeData.specialities.map((speciality, key) => (
                                    <MenuItem key={key} value={speciality.id}>
                                        {speciality.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%" }}>
                            <Button onClick={() => set_openDialog(false)} variant="contained" style={{ margin: "20px 0 0 20px" }}>
                                Annuler
                            </Button>
                            {data?.specialty_id && (
                                <Button onClick={DeleteSubdomain} variant="contained" color="secondary" style={{ margin: "20px 0 0 20px" }}>
                                    Supprimer
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    return data?.specialty_id ? EditSubdomain() : AddSubdomain();
                                }}
                                variant="contained"
                                color="primary"
                                disabled={data?.specialty_id ? _formSubdomain.specialty_id === data?.specialty_id && _formSubdomain.name === data.name : _formSubdomain.specialty_id.length === 0 || _formSubdomain.name === ""}
                                style={{ margin: "20px 0 0 20px" }}>
                                {data?.specialty_id ? "Modifier" : "Ajouter"}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Dialog>
    );
}
