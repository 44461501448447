import ApiService from '../services/api-service';
import PaginationHelper from '../helpers/pagination-helper';
import User from '../models/user';
import EstablishmentsLib from './establishments-lib';

const path = '/api/enterprises/';
const userPath = '/api/users/{id}/enterprises/';

export default class EnterprisesLib {
  static EnterprisesPath = '/api/enterprises/';
  static UserEnterprisesPath = '/api/users/{id}/enterprises/';

  static async create(data) {
    const response = await ApiService.post(path, data);
    if (data.siret) EstablishmentsLib.create({ ...data, enterprise_id: response.data.id });

    return response.data;
  }

  static async update(id, data) {
    const _path = `${path}${id}/`;

    const response = await ApiService.put(_path, data);

    return response.data;
  }

  static async delete(id) {
    const _path = `${path}${id}/`;

    const response = await ApiService.delete(_path);

    return response;
  }

  static async get(idOrParams) {
    let _path, response;

    if (typeof idOrParams === 'object') {
      const user = User.fromStorage();
      _path = userPath.replace('{id}', user.id);
      const params = idOrParams;
      response = await ApiService.get(_path, { params });
    } else {
      if (typeof idOrParams === 'number') {
        const id = idOrParams;
        _path = `${path}${id}/`;
      } else {
        const user = User.fromStorage();
        _path = userPath.replace('{id}', user.id);
      }
      response = await ApiService.get(_path);
    }

    return response.data.results ? response.data.results : response.data;
  }

  static getPagination(params) {
    const user = User.fromStorage();
    const _path = userPath.replace('{id}', user.id);

    return PaginationHelper.getPagination(_path, params);
  }

  static async getAll() {
    const response = await ApiService.get(path);

    return response.data;
  }
}
