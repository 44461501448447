import Editor from "../components/documents-edit";
import ListVar from "../components/documents-edit/EditorView/DialogListVar";
import DocumentList from "../components/documents/DocumentList";
import DocumentContext from "../providers/DocumentContext";
import { Grid, Fade, Slide } from "@material-ui/core/";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SaveButton from "../components/documents-edit/EditorView/SaveButton";

export default function Documents() {
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();
  const [documentEdit, setdocumentEdit] = useState(
    location.pathname === "/documents"
  );

  const initDocument = {
    selection: "",
    main: null,
    edit: { type: null },
    variables: [],
    groupsVariables: [],
    editorVariableOpen: false,
    fireChangeToBloc: null,
    project: null,
    name: "",
    launchSave: null,
  };

  const [document, setDocument] = useState(initDocument);

  const contextDocument = {
    document,
    updateDocument: setDocument,
  };

  useEffect(() => {
    setdocumentEdit(location.pathname === "/documents");
    setTabIndex(0);
    setDocument(initDocument);
  }, [location]);

  return (
    <div style={{ overflow: "hidden" }}>
      {documentEdit ? (
        <div>
          <Slide
            direction="left"
            in={true}
            mountOnEnter
            unmountOnExit
            {...{ timeout: 1000 }}
          >
            <div>
              <Fade
                mountOnEnter
                unmountOnExit
                in={true}
                {...{ timeout: 1000 + 1000 }}
              >
                <div>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <h1 style={{ margin: "20px 0 20px 0" }}>
                          Modèles de documents
                        </h1>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <DocumentList />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Fade>
            </div>
          </Slide>
        </div>
      ) : (
        <div style={{ marginTop: 15 }}>
          <DocumentContext.Provider value={contextDocument}>
            <Grid container spacing={2}>
              <Grid
                item
                lg={tabIndex === 1 ? 8 : 12}
                md={tabIndex === 1 ? 8 : 12}
                sm={tabIndex === 1 ? 8 : 12}
                xs={12}
              >
                <Editor tabIndex={tabIndex} setTabIndex={setTabIndex} />
              </Grid>
              {tabIndex === 1 && (
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={4}
                  xs={12}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <SaveButton tabIndex={tabIndex} />
                  <ListVar />
                </Grid>
              )}
            </Grid>
          </DocumentContext.Provider>
        </div>
      )}
    </div>
  );
}
