import DocumentContext from "../../../providers/DocumentContext";
import TemplateLib from "@common/libs/interview-template-lib";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab/";
import React, { useContext } from "react";
import { toast } from "react-toastify";

export default function FormDialog({ groupDialog, setgroupDialog }) {
    const { document, updateDocument } = useContext(DocumentContext);

    const sendVariable = () => {};

    const handleClose = () => {
        setgroupDialog(false);
    };

    const saveGroup = () => {
        if (groupDialog.id !== null) {
            let result = { ...document };
            const index = result.groupsVariables.findIndex(el => el.id === groupDialog.id);
            TemplateLib.updateGroupVariable(result.groupsVariables[index].id, groupDialog)
                .then(() => {
                    toast.success("Modification de la page de questionnaire enregistré");
                    result.groupsVariables[index] = groupDialog;
                    updateDocument(result);
                })
                .catch(() => toast.error("Erreur lors de la modification d'une page de questionnaire'"));
        } else {
            let result = { ...document };
            TemplateLib.createGroupVariable({ ...groupDialog, order: result.groupsVariables.length + 1, project: document.project })
                .then(res => {
                    toast.success("Ajout de la page de questionnaire enregistré");
                    result.groupsVariables.push(res);
                    updateDocument(result);
                })
                .catch(() => toast.error("Erreur lors de l'ajout d'une page de questionnaire"));
        }
        handleClose();
    };

    return (
        groupDialog && (
            <Dialog open={groupDialog ? true : false} onClose={handleClose}>
                <div style={{ maxWidth: 1000 }}setvariableDialog>
                    <DialogTitle>{groupDialog.id !== null ? "Modifier" : "Ajouter"} une page de questionnaire</DialogTitle>

                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Alert severity="info">Veuillez renseigner les détails de la page de questionnaire.</Alert>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField label="Nom de la page" error={groupDialog.name === ""} size="small" multiline fullWidth variant="outlined" value={groupDialog.name} onChange={e => setgroupDialog({ ...groupDialog, name: e.target.value })} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <TextField label="Description de la page" size="small" multiline rows={3} fullWidth variant="outlined" value={groupDialog.description} onChange={e => setgroupDialog({ ...groupDialog, description: e.target.value })} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained">
                            Annuler
                        </Button>
                        <Button onClick={sendVariable} variant="contained" color="primary" onClick={saveGroup} disabled={groupDialog.name === ""}>
                            {groupDialog.id !== null ? "Enregistrer" : "Ajouter"}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        )
    );
}
