import ApiService from '../services/api-service';
import PaginationHelper from '../helpers/pagination-helper';
import User from '../models/user';

const path = '/api/establishments/';
const userPath = '/api/users/{id}/establishments/';

export default class EstablishmentsLib {
  static EstablishmentsPath = '/api/establishments/';
  static UserEstablishmentsPath = '/api/users/{id}/establishments/';

  static async create(data) {
    const response = await ApiService.post(path, data);

    return response.data;
  }

  static async update(id, data) {
    const _path = `${path}${id}/`;

    const response = await ApiService.put(_path, data);

    return response.data;
  }

  static async delete(id) {
    const _path = `${path}${id}/`;

    const response = await ApiService.delete(_path);

    return response;
  }

  static async get(idOrParams) {
    let _path;
    if (typeof idOrParams === 'number') {
      _path = `${path}${idOrParams}/`;
    } else {
      _path = path;
    }

    const response = await ApiService.get(_path, { params: idOrParams });

    return response.data.results;
  }


  static async getAll() {
    const response = await ApiService.get(path);

    return response.data.results;
  }
  

  static async getUser(params) {
    const user = User.fromStorage();
    const path = EstablishmentsLib.UserEstablishmentsPath.replace('{id}', user.id);

    const response = await ApiService.get(path, { params });

    return response.data.results;
  }

  static getPagination(params) {
    return PaginationHelper.getPagination(path, params);
  }
}
