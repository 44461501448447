import ApiService from '../services/api-service';
import User from '../models/user';

export default class GroupsLib {
  static GroupsPath = '/api/groups/';
  static UserGroupsPath = '/api/users/{id}/groups/';
  static UserRequestClient = '/api/applications/request_to_client/?site={site}';

  static async getRequestClient(site) {
    const response = await ApiService.get(this.UserRequestClient.replace('{site}', site));

    return response.data;
  }

  static async get() {
    const user = User.fromStorage();
    const path = GroupsLib.UserGroupsPath.replace('{id}', user.id);
    const response = await ApiService.get(path);

    const groups = response.data.results;

    return groups.length ? groups[0] : null;
  }

  static async getAll() {
    const response = await ApiService.get(GroupsLib.GroupsPath);

    return response.data.results;
  }

  static async create(contact, name) {
    const response = await ApiService.post(GroupsLib.GroupsPath, { name, contact });

    return response.data;
  }

  static async read(id) {
    const path = `${GroupsLib.GroupsPath}${id}/`;

    const response = await ApiService.get(path);

    return response.data.name;
  }

  static async update(id, name) {
    const path = `${GroupsLib.GroupsPath}${id}/`;

    const response = await ApiService.put(path, { name });

    return response.data;
  }

  static async delete(id) {
    const path = `${GroupsLib.GroupsPath}${id}/`;

    const response = await ApiService.delete(path);

    return response;
  }
}
