import { Grid, TextField, Button } from "@material-ui/core";
import React, { useEffect } from "react";

export default function Upload(props) {
    const { _formData, set_formData } = props;

    useEffect(() => {
        let state = {
            text: _formData.actions[0].data.text,
            files: _formData.actions[0].data.files !== undefined && _formData.actions[0].data.files !== null ? _formData.actions[0].data.files : [],
        };
        set_formData({ ..._formData, actions: [{ ..._formData.actions[0], data: state }] });
    }, []);
    return (
        <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button disabled variant="contained" style={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", height: "40px" }}>
                    Choisir
                </Button>
                <TextField style={{ backgroundColor: "#eee" }} disabled variant="outlined" size="small" label="Joindre un fichier" />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div className="description">{"L'utilisateur se chargera d'uploader le fichier demandé."}</div>
            </Grid>
        </Grid>
    );
}
