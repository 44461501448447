import StoreDataContext from "../../../../../providers/StoreDataContext";
import { Grid, Select, InputLabel, MenuItem, FormControl, ListItemText, Checkbox, Chip } from "@material-ui/core";
import React, { useContext } from "react";

export default function Specialities({ _formData, set_formData }) {
    const { storeData } = useContext(StoreDataContext);

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControl variant="outlined" fullWidth style={{ backgroundColor: "white" }} size="small">
                <InputLabel>Spécialités du projet</InputLabel>
                <Select
                    multiple
                    renderValue={selected => (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {selected.map(value => (
                                <Chip variant="outlined" size="small" style={{ borderRadius: "5px", marginRight: "5px", marginTop: "5px", padding: "0px" }} color="primary" key={value} label={storeData.specialities.find(el => el.id === value)?.name} />
                            ))}
                        </div>
                    )}
                    value={_formData.projectspecialties}
                    onChange={() => {}}
                    label="Spécialités du projet">
                    {storeData.specialities
                        .sort(function (a, b) {
                            return a.id - b.id;
                        })
                        .map((speciality, key) => (
                            <MenuItem
                                key={key}
                                value={speciality.id}
                                onClick={() => {
                                    if (_formData.projectspecialties.find(el => el === speciality.id)) {
                                        set_formData({ ..._formData, projectspecialties: _formData.projectspecialties.filter(el => el !== speciality.id) });
                                    } else {
                                        set_formData({ ..._formData, projectspecialties: [..._formData.projectspecialties, speciality.id] });
                                    }
                                }}>
                                <Checkbox checked={_formData.projectspecialties.findIndex(el => el === speciality.id) > -1} />
                                <ListItemText primary={speciality.name} />
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Grid>
    );
}
