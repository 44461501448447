import StoreDataContext from "../../../providers/StoreDataContext";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CircularProgress,
} from "@material-ui/core/";
import React, { useContext } from "react";
import FiltersContext from "~/providers/FiltersContext";

export default function SpecialitiesFilter(props) {
  const { storeData } = useContext(StoreDataContext);
  const { filtersData, updateFiltersData } = useContext(FiltersContext);

  return storeData.specialities ? (
    <FormControl
      variant="outlined"
      fullWidth
      style={{ backgroundColor: "white" }}
    >
      <InputLabel>Recherche par spécialité</InputLabel>
      <Select
        value={filtersData.speciality}
        onChange={(e, v) =>
          updateFiltersData((filtersData) => ({
            ...filtersData,
            speciality: e.target.value,
          }))
        }
        label="Recherche par spécialité"
      >
        {storeData.specialities.length ? (
          storeData.specialities
            .sort(function (a, b) {
              return a.id - b.id;
            })
            .map((speciality, key) => (
              <MenuItem key={key} value={speciality.id}>
                {speciality.name}
              </MenuItem>
            ))
        ) : (
          <MenuItem value={1}>Toutes les spécialités</MenuItem>
        )}
      </Select>
    </FormControl>
  ) : (
    <CircularProgress size={20} />
  );
}
