import DocumentContext from "../../../providers/DocumentContext";
import ConditionView from "./Actions/Condition";
import RepetitionView from "./Actions/Repetition";
import Selection from "./Actions/Selection";
import VariablesView from "./Actions/Variables";
import getColorEdit from "./functions/getColorEdit";
import { DialogTitle, Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import React, { useState, useContext } from "react";

export default function AlertDialog({ open, setOpen, isFromWordIntegration }) {
  const { document } = useContext(DocumentContext);
  const [variableInstance, setvariableInstance] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const getTitle = () => {
    switch (document.edit.type) {
      case "variable":
        return "Définir une variable";
      case "condition":
        return "Définir une condition";
      case "repetition":
        return "Définir une répétition";
      default:
        break;
    }
  };

  return (
    document.edit.type !== null && (
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ maxWidth: 700, margin: "0 auto" }}
        fullWidth
      >
        <DialogTitle
          style={{
            backgroundColor: getColorEdit(document.edit.type),
            color: "white",
            fontWeight: "bold",
          }}
        >
          {getTitle()}
        </DialogTitle>
        <Grid container style={{ padding: 15, overflowX: "hidden" }}>
          {!isFromWordIntegration && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Selection />
            </Grid>
          )}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {document.edit.type === "variable" && (
              <VariablesView
                setOpen={setOpen}
                variableInstance={variableInstance}
                setvariableInstance={setvariableInstance}
                isFromWordIntegration={isFromWordIntegration}
              />
            )}
            {document.edit.type === "condition" && (
              <ConditionView
                setOpen={setOpen}
                variableInstance={variableInstance}
                setvariableInstance={setvariableInstance}
                isFromWordIntegration={isFromWordIntegration}
              />
            )}
            {document.edit.type === "repetition" && (
              <RepetitionView
                setOpen={setOpen}
                variableInstance={variableInstance}
                setvariableInstance={setvariableInstance}
                isFromWordIntegration={isFromWordIntegration}
              />
            )}
          </Grid>
        </Grid>
      </Dialog>
    )
  );
}
