import ActiveEditorContext from "../../../../providers/ActiveEditorContext";
import DraftPaiement from "./DraftPaiement";
import LineProduct from "./LineProduct";
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
  InputAdornment,
  Divider,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";

export default function Paiement(props) {
  const { _formData, set_formData, _paymentForm, set_paymentForm } = props;
  const { activeEditor } = useContext(ActiveEditorContext);

  useEffect(() => {
    let state = {
      text: _formData.actions[0].data.text,
      price_uuid:
        _formData.actions[0].data.price_uuid !== undefined &&
        _formData.actions[0].data.price_uuid !== null
          ? _formData.actions[0].data.price_uuid
          : "",
      product_uuid:
        _formData.actions[0].data.product_uuid !== undefined &&
        _formData.actions[0].data.product_uuid !== null
          ? _formData.actions[0].data.product_uuid
          : "",
      payment_uuid:
        _formData.actions[0].data.payment_uuid !== undefined &&
        _formData.actions[0].data.payment_uuid !== null
          ? _formData.actions[0].data.payment_uuid
          : "",
    };
    set_formData({
      ..._formData,
      actions: [{ ..._formData.actions[0], data: state }],
    });

    if (
      _formData.actions[0].data.price_uuid &&
      _formData.actions[0].data.price_uuid !== ""
    ) {
      set_paymentForm({
        tax_rate: _formData.actions[0].data.tax_rate
          ? _formData.actions[0].data.tax_rate
          : 20,
        products: _formData.actions[0].data.products
          ? _formData.actions[0].data.products
          : [],
      });
    }
  }, []);

  const addLine = () => {
    set_paymentForm({
      ..._paymentForm,
      products: [..._paymentForm.products, { name: "", amount: 0 }],
    });
  };

  const getClearNumber = (number) => {
    let table = number.split("");
    if (table[0] === "0") {
      table.shift();
      return getClearNumber(table.join(""));
    } else return table.join("");
  };

  return (
    _paymentForm?.products !== undefined &&
    _paymentForm?.tax_rate !== undefined && (
      <>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div
              className="description"
              style={{ textAlign: "center", width: "100%" }}
            >
              Veuillez construire le bon de commande que le client devra payer.
            </div>
          </Grid>
          {_paymentForm.products.map((elem, key) => {
            return (
              <LineProduct
                data={elem}
                key={key}
                index={key}
                _paymentForm={_paymentForm}
                set_paymentForm={set_paymentForm}
              />
            );
          })}
          <Grid item lg={8} md={8} sm={8} xs={12}>
            <Button variant="contained" color="primary" onClick={addLine}>
              + Ajouter produit
            </Button>
          </Grid>
          <Grid
            item
            lg={4}
            md={4}
            sm={4}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Checkbox
              checked={
                _paymentForm?.tax_rate !== "0" && _paymentForm?.tax_rate !== 0
              }
              onChange={(e) => {
                if (e.target.checked) {
                  set_paymentForm({ ..._paymentForm, tax_rate: "20" });
                } else {
                  set_paymentForm({ ..._paymentForm, tax_rate: "0" });
                }
              }}
            />
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              style={{ backgroundColor: "white" }}
              fullWidth
              label="T.V.A"
              size="small"
              variant="outlined"
              type="number"
              value={_paymentForm.tax_rate}
              onChange={(e) => {
                if (
                  e.nativeEvent.inputType === "insertText" ||
                  e.nativeEvent.inputType === "deleteContentBackward" ||
                  e.nativeEvent.inputType === "insertFromPaste"
                ) {
                  if (e.target.value === "")
                    set_paymentForm({ ..._paymentForm, tax_rate: "" });
                  else if (e.target.value > 100)
                    set_paymentForm({ ..._paymentForm, tax_rate: 100 });
                  else if (e.target.value < 0)
                    set_paymentForm({ ..._paymentForm, tax_rate: 0 });
                  else
                    set_paymentForm({
                      ..._paymentForm,
                      tax_rate: +e.target.value,
                    });
                }
              }}
              onBlur={(e) => {
                if (e.target.value === "")
                  set_paymentForm({ ..._paymentForm, tax_rate: 0 });
                else
                  set_paymentForm({
                    ..._paymentForm,
                    tax_rate: getClearNumber(e.target.value),
                  });
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ width: "80%", margin: "20px 10%" }} />
        {activeEditor.type === "add_task" ||
        (_paymentForm?.products !== undefined &&
          _paymentForm?.tax_rate !== undefined) ? (
          <DraftPaiement _paymentForm={_paymentForm} />
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={20} />
          </div>
        )}
      </>
    )
  );
}
