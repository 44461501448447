import { Checkbox, Grid } from "@material-ui/core"
import { useEffect } from "react"

export default ({
  variableDialog,
  setvariableDialog,
  setoriginalvariableType,
  originalvariableType,
}) => {
  useEffect(() => {
    if (originalvariableType !== "composable") {
      setvariableDialog({ ...variableDialog, placeholder: { value: false } })
      setoriginalvariableType("composable")
    }
  }, [])

  return (
    originalvariableType === "bool" && (
      <>
        <Grid
          style={{
            backgroundColor: "#eee",
            margin: "0 20px",
            display: "flex",
            alignItems: "center",
            border: "1px solid silver",
            cursor: "pointer",
          }}
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          onClick={() => {
            setvariableDialog({
              ...variableDialog,
              placeholder: { value: !variableDialog.placeholder.value },
            })
          }}
        >
          {/* <Checkbox checked={variableDialog?.placeholder?.value} /> Cochée par
          défaut ? */}
        </Grid>
      </>
    )
  )
}
