import { Button, CircularProgress } from "@material-ui/core";

export default function Modify({ _function, loading }) {
  return (
    <Button
      variant="contained"
      color="primary"
      disabled={loading}
      onClick={_function}
    >
      {loading ? <CircularProgress size={18} /> : "Enregistrer modification"}
    </Button>
  );
}
